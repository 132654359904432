const translations = {
  de: {
    "backend welcome": "Willkommen",
  },
  en: {
    "backend welcome": "Welcome",
  },
};

export default translations;

import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  assets: {
    backgroundImage: "https://assets.online-days.com/images/schloss-1600px.jpg",
  },
  colors: {
    primaryColorDark: "rgba(35, 46, 88, .8)",
    primaryColorMedium: "rgba(172, 185, 203, .8)",
    primaryColorLight: "rgba(172, 185, 203, .8)",
    secondaryColorDark: "#06B49A",
    secondaryColorMedium: "",
    secondaryColorLight: "",
  },
  fonts: ["sans-serif", "Roboto"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;

/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateOrganizationInput = {
  id?: string | null,
  title?: LanguageInputInput | null,
  description?: LanguageInputInput | null,
  platformTitle?: LanguageInputInput | null,
  editorStateDE?: string | null,
  editorStateEN?: string | null,
  website?: LanguageInputInput | null,
  contact?: string | null,
  logo?: LanguageInputInput | null,
  primaryColor?: string | null,
  secondaryColor?: string | null,
  font?: string | null,
  backgroundImage?: string | null,
  standardCover?: string | null,
  welcomeText?: LanguageInputInput | null,
  features?: Array< string | null > | null,
  bbbServerUrl?: string | null,
  bbbServerSecret?: string | null,
  currentSessionDesc?: LanguageInputInput | null,
  upcomingSessionDesc?: LanguageInputInput | null,
  archivedSessionDesc?: LanguageInputInput | null,
  copyright?: string | null,
  maxEvents?: number | null,
  transactionalEmail?: string | null,
  cssOverride?: string | null,
  showAudienceFiltering?: boolean | null,
  loginRequired?: boolean | null,
  landingPageDisplay?: LandingPageDisplay | null,
  videoUrl?: string | null,
  autoDeleteUsers?: boolean | null,
  dataPrivacy?: string | null,
  imprint?: string | null,
  showSessionGroupFiltering?: boolean | null,
  hideGlobalSearch?: boolean | null,
  hideArchivedSessions?: boolean | null,
};

export type LanguageInputInput = {
  en?: string | null,
  de?: string | null,
};

export enum LandingPageDisplay {
  VIDEO = "VIDEO",
  SESSIONS_LIST = "SESSIONS_LIST",
  NONE = "NONE",
}


export type ModelOrganizationConditionInput = {
  editorStateDE?: ModelStringInput | null,
  editorStateEN?: ModelStringInput | null,
  contact?: ModelStringInput | null,
  primaryColor?: ModelStringInput | null,
  secondaryColor?: ModelStringInput | null,
  font?: ModelStringInput | null,
  backgroundImage?: ModelStringInput | null,
  standardCover?: ModelStringInput | null,
  features?: ModelStringInput | null,
  bbbServerUrl?: ModelStringInput | null,
  bbbServerSecret?: ModelStringInput | null,
  copyright?: ModelStringInput | null,
  maxEvents?: ModelIntInput | null,
  transactionalEmail?: ModelStringInput | null,
  cssOverride?: ModelStringInput | null,
  showAudienceFiltering?: ModelBooleanInput | null,
  loginRequired?: ModelBooleanInput | null,
  landingPageDisplay?: ModelLandingPageDisplayInput | null,
  videoUrl?: ModelStringInput | null,
  autoDeleteUsers?: ModelBooleanInput | null,
  dataPrivacy?: ModelStringInput | null,
  imprint?: ModelStringInput | null,
  showSessionGroupFiltering?: ModelBooleanInput | null,
  hideGlobalSearch?: ModelBooleanInput | null,
  hideArchivedSessions?: ModelBooleanInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelLandingPageDisplayInput = {
  eq?: LandingPageDisplay | null,
  ne?: LandingPageDisplay | null,
};

export type Organization = {
  __typename: "Organization",
  id?: string,
  title?: LanguageInput,
  description?: LanguageInput,
  platformTitle?: LanguageInput,
  editorStateDE?: string | null,
  editorStateEN?: string | null,
  website?: LanguageInput,
  contact?: string | null,
  logo?: LanguageInput,
  primaryColor?: string | null,
  secondaryColor?: string | null,
  font?: string | null,
  backgroundImage?: string | null,
  standardCover?: string | null,
  welcomeText?: LanguageInput,
  features?: Array< string | null > | null,
  bbbServerUrl?: string | null,
  bbbServerSecret?: string | null,
  currentSessionDesc?: LanguageInput,
  upcomingSessionDesc?: LanguageInput,
  archivedSessionDesc?: LanguageInput,
  copyright?: string | null,
  maxEvents?: number | null,
  transactionalEmail?: string | null,
  cssOverride?: string | null,
  showAudienceFiltering?: boolean | null,
  loginRequired?: boolean | null,
  landingPageDisplay?: LandingPageDisplay | null,
  videoUrl?: string | null,
  autoDeleteUsers?: boolean | null,
  dataPrivacy?: string | null,
  imprint?: string | null,
  showSessionGroupFiltering?: boolean | null,
  hideGlobalSearch?: boolean | null,
  hideArchivedSessions?: boolean | null,
  createdAt?: string,
  updatedAt?: string,
  departments?: ModelDepartmentConnection,
  events?: ModelEventConnection,
  sessions?: ModelSessionConnection,
};

export type LanguageInput = {
  __typename: "LanguageInput",
  en?: string | null,
  de?: string | null,
};

export type ModelDepartmentConnection = {
  __typename: "ModelDepartmentConnection",
  items?:  Array<Department | null >,
  nextToken?: string | null,
};

export type Department = {
  __typename: "Department",
  id?: string,
  title?: LanguageInput,
  description?: LanguageInput,
  primaryColor?: string | null,
  editorStateDE?: string | null,
  editorStateEN?: string | null,
  logo?: string | null,
  website?: string | null,
  createdAt?: string,
  updatedAt?: string,
  organization?: Organization,
  programs?: ModelProgramConnection,
  sessions?: ModelSessionConnection,
  modules?: ModelModuleConnection,
  staff?: ModelDepartmentStaffConnection,
};

export type ModelProgramConnection = {
  __typename: "ModelProgramConnection",
  items?:  Array<Program | null >,
  nextToken?: string | null,
};

export type Program = {
  __typename: "Program",
  id?: string,
  title?: LanguageInput,
  description?: LanguageInput,
  editorStateDE?: string | null,
  editorStateEN?: string | null,
  createdAt?: string,
  updatedAt?: string,
  department?: Department,
  sessions?: ModelProgramSessionConnection,
};

export type ModelProgramSessionConnection = {
  __typename: "ModelProgramSessionConnection",
  items?:  Array<ProgramSession | null >,
  nextToken?: string | null,
};

export type ProgramSession = {
  __typename: "ProgramSession",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  program?: Program,
  session?: Session,
};

export type Session = {
  __typename: "Session",
  id?: string,
  title?: LanguageInput,
  description?: LanguageInput,
  start?: string | null,
  end?: string | null,
  language?: Language | null,
  type?: SessionType | null,
  sessionState?: SessionState | null,
  stream?: string | null,
  chatEnabled?: boolean | null,
  conference?: string | null,
  module?:  Array<Module | null > | null,
  isFree?: boolean | null,
  audience?: Array< UserType | null > | null,
  color?: string | null,
  resources?:  Array<Resource | null > | null,
  image?: string | null,
  anonymous?: boolean | null,
  isRated?: boolean | null,
  isProtected?: boolean | null,
  password?: string | null,
  automaticActivationEnabled?: boolean | null,
  automaticArchiveEnabled?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  author?: User,
  zoom?: Zoom,
  teams?: Teams,
  count?: number | null,
  isRestricted?: boolean | null,
  group?: string | null,
  link?: string | null,
  sessionDepartmentId?: string | null,
  organization?: Organization,
  department?: Department,
  event?: Event,
  program?: ModelProgramSessionConnection,
  ratings?: ModelRatingConnection,
  messages?: ModelMessageConnection,
  modules?: ModelSessionModulesConnection,
  enrollments?: ModelSessionEnrollmentConnection,
  likes?: ModelLikeConnection,
};

export enum Language {
  de = "de",
  en = "en",
  mixed = "mixed",
}


export enum SessionType {
  CHAT = "CHAT",
  STREAM = "STREAM",
  CONFERENCE = "CONFERENCE",
  ZOOM = "ZOOM",
  JITSI = "JITSI",
  TEAMS = "TEAMS",
  LINK = "LINK",
  ODCONFERENCE = "ODCONFERENCE",
  STREAMCONFERENCE = "STREAMCONFERENCE",
  QUEUECONFERENCE = "QUEUECONFERENCE",
}


export enum SessionState {
  ACTIVE = "ACTIVE",
  SCHEDULED = "SCHEDULED",
  ARCHIVED = "ARCHIVED",
  UNPUBLISHED = "UNPUBLISHED",
}


export type Module = {
  __typename: "Module",
  id?: string,
  title?: LanguageInput,
  startpage?: LanguageInput,
  startpageEN?: string | null,
  startpageDE?: string | null,
  color?: string | null,
  isPublished?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  author?: User,
  sort?: number | null,
  department?: Department,
  rating?: ModelRatingConnection,
  sections?: ModelSectionConnection,
  users?: ModelModuleUsersConnection,
  events?: ModelEventModulesConnection,
  sessions?: ModelSessionModulesConnection,
  likes?: ModelLikeConnection,
};

export type User = {
  __typename: "User",
  id?: string,
  status?: Status | null,
  firstName?: string | null,
  lastName?: string | null,
  image?: string | null,
  userType?: UserType | null,
  position?: LanguageInput,
  isTemporary?: boolean | null,
  onboarded?: boolean | null,
  createdAt?: string,
  updatedAt?: string,
  events?: ModelEventEnrollmentsConnection,
  ratings?: ModelRatingConnection,
  authEvents?: ModelAuthEventConnection,
  messages?: ModelMessageConnection,
  lastLoginTime?: string | null,
  email?: string | null,
  ytTokens?: Token,
  teamsTokens?: Token,
  redirect?: string | null,
  modules?: ModelModuleUsersConnection,
  departments?: ModelDepartmentStaffConnection,
  sessions?: ModelSessionEnrollmentConnection,
  likes?: ModelLikeConnection,
};

export enum Status {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DELETED = "DELETED",
}


export enum UserType {
  PROSPECT = "PROSPECT",
  CURRENT = "CURRENT",
  STAFF = "STAFF",
  EXTERNAL = "EXTERNAL",
}


export type ModelEventEnrollmentsConnection = {
  __typename: "ModelEventEnrollmentsConnection",
  items?:  Array<EventEnrollments | null >,
  nextToken?: string | null,
};

export type EventEnrollments = {
  __typename: "EventEnrollments",
  id?: string,
  type?: string | null,
  createdAt?: string,
  updatedAt?: string,
  event?: Event,
  user?: User,
};

export type Event = {
  __typename: "Event",
  id?: string,
  state?: EventState | null,
  title?: LanguageInput,
  description?: LanguageInput,
  videoUrl?: LanguageInput,
  videoCover?: string | null,
  image?: string | null,
  imageAlt?: string | null,
  start?: string | null,
  end?: string | null,
  template?: EventTemplate | null,
  count?: number | null,
  isRestricted?: boolean | null,
  group?: string | null,
  audience?: Array< UserType | null > | null,
  showInlineModules?: boolean | null,
  altHeaderStyle?: boolean | null,
  createdAt?: string,
  updatedAt?: string,
  organization?: Organization,
  enrollments?: ModelEventEnrollmentsConnection,
  sessions?: ModelSessionConnection,
  modules?: ModelEventModulesConnection,
  likes?: ModelLikeConnection,
};

export enum EventState {
  ACTIVE = "ACTIVE",
  SCHEDULED = "SCHEDULED",
  ARCHIVED = "ARCHIVED",
  UNPUBLISHED = "UNPUBLISHED",
}


export enum EventTemplate {
  STANDARD = "STANDARD",
  SCHEDULE = "SCHEDULE",
}


export type ModelSessionConnection = {
  __typename: "ModelSessionConnection",
  items?:  Array<Session | null >,
  nextToken?: string | null,
};

export type ModelEventModulesConnection = {
  __typename: "ModelEventModulesConnection",
  items?:  Array<EventModules | null >,
  nextToken?: string | null,
};

export type EventModules = {
  __typename: "EventModules",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  event?: Event,
  module?: Module,
};

export type ModelLikeConnection = {
  __typename: "ModelLikeConnection",
  items?:  Array<Like | null >,
  nextToken?: string | null,
};

export type Like = {
  __typename: "Like",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  event?: Event,
  session?: Session,
  message?: Message,
  module?: Module,
  page?: Page,
  user?: User,
};

export type Message = {
  __typename: "Message",
  id?: string,
  body?: string | null,
  createdAt?: string | null,
  sessionId?: string | null,
  anonymousUserName?: string | null,
  replies?:  Array<Message | null > | null,
  isFlagged?: boolean | null,
  flaggedBy?: string | null,
  isRepliedTo?: boolean | null,
  updatedAt?: string,
  session?: Session,
  author?: User,
  likes?: ModelLikeConnection,
};

export type Page = {
  __typename: "Page",
  id?: string,
  title?: LanguageInput,
  editorState?: LanguageInput,
  editorStateDE?: string | null,
  editorStateEN?: string | null,
  sort?: number | null,
  isPublished?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  author?: User,
  count?: number | null,
  section?: Section,
  likes?: ModelLikeConnection,
};

export type Section = {
  __typename: "Section",
  id?: string,
  title?: LanguageInput,
  description?: LanguageInput,
  startpage?: LanguageInput,
  sort?: number | null,
  isPublished?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  author?: User,
  module?: Module,
  pages?: ModelPageConnection,
};

export type ModelPageConnection = {
  __typename: "ModelPageConnection",
  items?:  Array<Page | null >,
  nextToken?: string | null,
};

export type ModelRatingConnection = {
  __typename: "ModelRatingConnection",
  items?:  Array<Rating | null >,
  nextToken?: string | null,
};

export type Rating = {
  __typename: "Rating",
  id?: string,
  stars?: number | null,
  text?: string | null,
  createdAt?: string,
  updatedAt?: string,
  session?: Session,
  module?: Module,
  user?: User,
};

export type ModelAuthEventConnection = {
  __typename: "ModelAuthEventConnection",
  items?:  Array<AuthEvent | null >,
  nextToken?: string | null,
};

export type AuthEvent = {
  __typename: "AuthEvent",
  id?: string,
  type?: string | null,
  createdAt?: string | null,
  updatedAt?: string,
  user?: User,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items?:  Array<Message | null >,
  nextToken?: string | null,
};

export type Token = {
  __typename: "Token",
  accessToken?: string | null,
  refreshToken?: string | null,
  expires_in?: string | null,
};

export type ModelModuleUsersConnection = {
  __typename: "ModelModuleUsersConnection",
  items?:  Array<ModuleUsers | null >,
  nextToken?: string | null,
};

export type ModuleUsers = {
  __typename: "ModuleUsers",
  id?: string,
  type?: string | null,
  createdAt?: string,
  updatedAt?: string,
  module?: Module,
  user?: User,
};

export type ModelDepartmentStaffConnection = {
  __typename: "ModelDepartmentStaffConnection",
  items?:  Array<DepartmentStaff | null >,
  nextToken?: string | null,
};

export type DepartmentStaff = {
  __typename: "DepartmentStaff",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  department?: Department,
  user?: User,
};

export type ModelSessionEnrollmentConnection = {
  __typename: "ModelSessionEnrollmentConnection",
  items?:  Array<SessionEnrollment | null >,
  nextToken?: string | null,
};

export type SessionEnrollment = {
  __typename: "SessionEnrollment",
  id?: string,
  type?: string | null,
  sessionEnrollmentSessionId?: string | null,
  sessionEnrollmentUserId?: string | null,
  createdAt?: string,
  updatedAt?: string,
  session?: Session,
  user?: User,
};

export type ModelSectionConnection = {
  __typename: "ModelSectionConnection",
  items?:  Array<Section | null >,
  nextToken?: string | null,
};

export type ModelSessionModulesConnection = {
  __typename: "ModelSessionModulesConnection",
  items?:  Array<SessionModules | null >,
  nextToken?: string | null,
};

export type SessionModules = {
  __typename: "SessionModules",
  id?: string,
  createdAt?: string,
  updatedAt?: string,
  session?: Session,
  module?: Module,
};

export type Resource = {
  __typename: "Resource",
  description?: LanguageInput,
  link?: string | null,
};

export type Zoom = {
  __typename: "Zoom",
  start_url?: string | null,
  join_url?: string | null,
  id?: string | null,
};

export type Teams = {
  __typename: "Teams",
  joinUrl?: string | null,
  id?: string | null,
};

export type ModelModuleConnection = {
  __typename: "ModelModuleConnection",
  items?:  Array<Module | null >,
  nextToken?: string | null,
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items?:  Array<Event | null >,
  nextToken?: string | null,
};

export type UpdateOrganizationInput = {
  id: string,
  title?: LanguageInputInput | null,
  description?: LanguageInputInput | null,
  platformTitle?: LanguageInputInput | null,
  editorStateDE?: string | null,
  editorStateEN?: string | null,
  website?: LanguageInputInput | null,
  contact?: string | null,
  logo?: LanguageInputInput | null,
  primaryColor?: string | null,
  secondaryColor?: string | null,
  font?: string | null,
  backgroundImage?: string | null,
  standardCover?: string | null,
  welcomeText?: LanguageInputInput | null,
  features?: Array< string | null > | null,
  bbbServerUrl?: string | null,
  bbbServerSecret?: string | null,
  currentSessionDesc?: LanguageInputInput | null,
  upcomingSessionDesc?: LanguageInputInput | null,
  archivedSessionDesc?: LanguageInputInput | null,
  copyright?: string | null,
  maxEvents?: number | null,
  transactionalEmail?: string | null,
  cssOverride?: string | null,
  showAudienceFiltering?: boolean | null,
  loginRequired?: boolean | null,
  landingPageDisplay?: LandingPageDisplay | null,
  videoUrl?: string | null,
  autoDeleteUsers?: boolean | null,
  dataPrivacy?: string | null,
  imprint?: string | null,
  showSessionGroupFiltering?: boolean | null,
  hideGlobalSearch?: boolean | null,
  hideArchivedSessions?: boolean | null,
};

export type DeleteOrganizationInput = {
  id: string,
};

export type CreateDepartmentInput = {
  id?: string | null,
  title?: LanguageInputInput | null,
  description?: LanguageInputInput | null,
  primaryColor?: string | null,
  editorStateDE?: string | null,
  editorStateEN?: string | null,
  logo?: string | null,
  website?: string | null,
  departmentOrganizationId?: string | null,
};

export type ModelDepartmentConditionInput = {
  primaryColor?: ModelStringInput | null,
  editorStateDE?: ModelStringInput | null,
  editorStateEN?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  website?: ModelStringInput | null,
  and?: Array< ModelDepartmentConditionInput | null > | null,
  or?: Array< ModelDepartmentConditionInput | null > | null,
  not?: ModelDepartmentConditionInput | null,
};

export type UpdateDepartmentInput = {
  id: string,
  title?: LanguageInputInput | null,
  description?: LanguageInputInput | null,
  primaryColor?: string | null,
  editorStateDE?: string | null,
  editorStateEN?: string | null,
  logo?: string | null,
  website?: string | null,
  departmentOrganizationId?: string | null,
};

export type DeleteDepartmentInput = {
  id: string,
};

export type CreateProgramInput = {
  id?: string | null,
  title?: LanguageInputInput | null,
  description?: LanguageInputInput | null,
  editorStateDE?: string | null,
  editorStateEN?: string | null,
  programDepartmentId?: string | null,
};

export type ModelProgramConditionInput = {
  editorStateDE?: ModelStringInput | null,
  editorStateEN?: ModelStringInput | null,
  and?: Array< ModelProgramConditionInput | null > | null,
  or?: Array< ModelProgramConditionInput | null > | null,
  not?: ModelProgramConditionInput | null,
};

export type UpdateProgramInput = {
  id: string,
  title?: LanguageInputInput | null,
  description?: LanguageInputInput | null,
  editorStateDE?: string | null,
  editorStateEN?: string | null,
  programDepartmentId?: string | null,
};

export type DeleteProgramInput = {
  id: string,
};

export type CreateEventInput = {
  id?: string | null,
  state?: EventState | null,
  title?: LanguageInputInput | null,
  description?: LanguageInputInput | null,
  videoUrl?: LanguageInputInput | null,
  videoCover?: string | null,
  image?: string | null,
  imageAlt?: string | null,
  start?: string | null,
  end?: string | null,
  template?: EventTemplate | null,
  count?: number | null,
  isRestricted?: boolean | null,
  group?: string | null,
  audience?: Array< UserType | null > | null,
  showInlineModules?: boolean | null,
  altHeaderStyle?: boolean | null,
  eventOrganizationId?: string | null,
};

export type ModelEventConditionInput = {
  state?: ModelEventStateInput | null,
  videoCover?: ModelStringInput | null,
  image?: ModelStringInput | null,
  imageAlt?: ModelStringInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  template?: ModelEventTemplateInput | null,
  count?: ModelIntInput | null,
  isRestricted?: ModelBooleanInput | null,
  group?: ModelStringInput | null,
  audience?: ModelUserTypeListInput | null,
  showInlineModules?: ModelBooleanInput | null,
  altHeaderStyle?: ModelBooleanInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
};

export type ModelEventStateInput = {
  eq?: EventState | null,
  ne?: EventState | null,
};

export type ModelEventTemplateInput = {
  eq?: EventTemplate | null,
  ne?: EventTemplate | null,
};

export type ModelUserTypeListInput = {
  eq?: Array< UserType | null > | null,
  ne?: Array< UserType | null > | null,
  contains?: UserType | null,
  notContains?: UserType | null,
};

export type UpdateEventInput = {
  id: string,
  state?: EventState | null,
  title?: LanguageInputInput | null,
  description?: LanguageInputInput | null,
  videoUrl?: LanguageInputInput | null,
  videoCover?: string | null,
  image?: string | null,
  imageAlt?: string | null,
  start?: string | null,
  end?: string | null,
  template?: EventTemplate | null,
  count?: number | null,
  isRestricted?: boolean | null,
  group?: string | null,
  audience?: Array< UserType | null > | null,
  showInlineModules?: boolean | null,
  altHeaderStyle?: boolean | null,
  eventOrganizationId?: string | null,
};

export type DeleteEventInput = {
  id: string,
};

export type CreateEventEnrollmentsInput = {
  id?: string | null,
  type?: string | null,
  eventEnrollmentsEventId?: string | null,
  eventEnrollmentsUserId?: string | null,
};

export type ModelEventEnrollmentsConditionInput = {
  type?: ModelStringInput | null,
  and?: Array< ModelEventEnrollmentsConditionInput | null > | null,
  or?: Array< ModelEventEnrollmentsConditionInput | null > | null,
  not?: ModelEventEnrollmentsConditionInput | null,
};

export type UpdateEventEnrollmentsInput = {
  id: string,
  type?: string | null,
  eventEnrollmentsEventId?: string | null,
  eventEnrollmentsUserId?: string | null,
};

export type DeleteEventEnrollmentsInput = {
  id: string,
};

export type CreateProgramSessionInput = {
  id?: string | null,
  programSessionProgramId?: string | null,
  programSessionSessionId?: string | null,
};

export type ModelProgramSessionConditionInput = {
  and?: Array< ModelProgramSessionConditionInput | null > | null,
  or?: Array< ModelProgramSessionConditionInput | null > | null,
  not?: ModelProgramSessionConditionInput | null,
};

export type UpdateProgramSessionInput = {
  id: string,
  programSessionProgramId?: string | null,
  programSessionSessionId?: string | null,
};

export type DeleteProgramSessionInput = {
  id: string,
};

export type CreateSessionInput = {
  id?: string | null,
  title?: LanguageInputInput | null,
  description?: LanguageInputInput | null,
  start?: string | null,
  end?: string | null,
  language?: Language | null,
  type?: SessionType | null,
  sessionState?: SessionState | null,
  stream?: string | null,
  chatEnabled?: boolean | null,
  conference?: string | null,
  isFree?: boolean | null,
  audience?: Array< UserType | null > | null,
  color?: string | null,
  resources?: Array< ResourceInput | null > | null,
  image?: string | null,
  anonymous?: boolean | null,
  isRated?: boolean | null,
  isProtected?: boolean | null,
  password?: string | null,
  automaticActivationEnabled?: boolean | null,
  automaticArchiveEnabled?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  zoom?: ZoomInput | null,
  teams?: TeamsInput | null,
  count?: number | null,
  isRestricted?: boolean | null,
  group?: string | null,
  link?: string | null,
  sessionDepartmentId?: string | null,
  sessionEventId?: string | null,
  sessionOrganizationId?: string | null,
};

export type ResourceInput = {
  description?: LanguageInputInput | null,
  link?: string | null,
};

export type ZoomInput = {
  start_url?: string | null,
  join_url?: string | null,
  id?: string | null,
};

export type TeamsInput = {
  joinUrl?: string | null,
  id?: string | null,
};

export type ModelSessionConditionInput = {
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  language?: ModelLanguageInput | null,
  type?: ModelSessionTypeInput | null,
  sessionState?: ModelSessionStateInput | null,
  stream?: ModelStringInput | null,
  chatEnabled?: ModelBooleanInput | null,
  conference?: ModelStringInput | null,
  isFree?: ModelBooleanInput | null,
  audience?: ModelUserTypeListInput | null,
  color?: ModelStringInput | null,
  image?: ModelStringInput | null,
  anonymous?: ModelBooleanInput | null,
  isRated?: ModelBooleanInput | null,
  isProtected?: ModelBooleanInput | null,
  password?: ModelStringInput | null,
  automaticActivationEnabled?: ModelBooleanInput | null,
  automaticArchiveEnabled?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  count?: ModelIntInput | null,
  isRestricted?: ModelBooleanInput | null,
  group?: ModelStringInput | null,
  link?: ModelStringInput | null,
  sessionDepartmentId?: ModelIDInput | null,
  and?: Array< ModelSessionConditionInput | null > | null,
  or?: Array< ModelSessionConditionInput | null > | null,
  not?: ModelSessionConditionInput | null,
};

export type ModelLanguageInput = {
  eq?: Language | null,
  ne?: Language | null,
};

export type ModelSessionTypeInput = {
  eq?: SessionType | null,
  ne?: SessionType | null,
};

export type ModelSessionStateInput = {
  eq?: SessionState | null,
  ne?: SessionState | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateSessionInput = {
  id: string,
  title?: LanguageInputInput | null,
  description?: LanguageInputInput | null,
  start?: string | null,
  end?: string | null,
  language?: Language | null,
  type?: SessionType | null,
  sessionState?: SessionState | null,
  stream?: string | null,
  chatEnabled?: boolean | null,
  conference?: string | null,
  isFree?: boolean | null,
  audience?: Array< UserType | null > | null,
  color?: string | null,
  resources?: Array< ResourceInput | null > | null,
  image?: string | null,
  anonymous?: boolean | null,
  isRated?: boolean | null,
  isProtected?: boolean | null,
  password?: string | null,
  automaticActivationEnabled?: boolean | null,
  automaticArchiveEnabled?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  zoom?: ZoomInput | null,
  teams?: TeamsInput | null,
  count?: number | null,
  isRestricted?: boolean | null,
  group?: string | null,
  link?: string | null,
  sessionDepartmentId?: string | null,
  sessionEventId?: string | null,
  sessionOrganizationId?: string | null,
};

export type DeleteSessionInput = {
  id: string,
};

export type CreateRatingInput = {
  id?: string | null,
  stars?: number | null,
  text?: string | null,
  ratingSessionId?: string | null,
  ratingModuleId?: string | null,
  ratingUserId?: string | null,
};

export type ModelRatingConditionInput = {
  stars?: ModelIntInput | null,
  text?: ModelStringInput | null,
  and?: Array< ModelRatingConditionInput | null > | null,
  or?: Array< ModelRatingConditionInput | null > | null,
  not?: ModelRatingConditionInput | null,
};

export type UpdateRatingInput = {
  id: string,
  stars?: number | null,
  text?: string | null,
  ratingSessionId?: string | null,
  ratingModuleId?: string | null,
  ratingUserId?: string | null,
};

export type DeleteRatingInput = {
  id: string,
};

export type CreateAuthEventInput = {
  id?: string | null,
  type?: string | null,
  createdAt?: string | null,
  authEventUserId?: string | null,
};

export type ModelAuthEventConditionInput = {
  type?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAuthEventConditionInput | null > | null,
  or?: Array< ModelAuthEventConditionInput | null > | null,
  not?: ModelAuthEventConditionInput | null,
};

export type UpdateAuthEventInput = {
  id: string,
  type?: string | null,
  createdAt?: string | null,
  authEventUserId?: string | null,
};

export type DeleteAuthEventInput = {
  id: string,
};

export type CreateMessageInput = {
  id?: string | null,
  body?: string | null,
  createdAt?: string | null,
  sessionId?: string | null,
  anonymousUserName?: string | null,
  isFlagged?: boolean | null,
  flaggedBy?: string | null,
  isRepliedTo?: boolean | null,
  messageSessionId?: string | null,
  messageAuthorId?: string | null,
};

export type ModelMessageConditionInput = {
  body?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  sessionId?: ModelIDInput | null,
  anonymousUserName?: ModelStringInput | null,
  isFlagged?: ModelBooleanInput | null,
  flaggedBy?: ModelStringInput | null,
  isRepliedTo?: ModelBooleanInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
};

export type UpdateMessageInput = {
  id: string,
  body?: string | null,
  createdAt?: string | null,
  sessionId?: string | null,
  anonymousUserName?: string | null,
  isFlagged?: boolean | null,
  flaggedBy?: string | null,
  isRepliedTo?: boolean | null,
  messageSessionId?: string | null,
  messageAuthorId?: string | null,
};

export type DeleteMessageInput = {
  id: string,
};

export type CreateModuleInput = {
  id?: string | null,
  title?: LanguageInputInput | null,
  startpage?: LanguageInputInput | null,
  startpageEN?: string | null,
  startpageDE?: string | null,
  color?: string | null,
  isPublished?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  sort?: number | null,
  moduleDepartmentId?: string | null,
};

export type ModelModuleConditionInput = {
  startpageEN?: ModelStringInput | null,
  startpageDE?: ModelStringInput | null,
  color?: ModelStringInput | null,
  isPublished?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  and?: Array< ModelModuleConditionInput | null > | null,
  or?: Array< ModelModuleConditionInput | null > | null,
  not?: ModelModuleConditionInput | null,
};

export type UpdateModuleInput = {
  id: string,
  title?: LanguageInputInput | null,
  startpage?: LanguageInputInput | null,
  startpageEN?: string | null,
  startpageDE?: string | null,
  color?: string | null,
  isPublished?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  sort?: number | null,
  moduleDepartmentId?: string | null,
};

export type DeleteModuleInput = {
  id: string,
};

export type CreateSectionInput = {
  id?: string | null,
  title?: LanguageInputInput | null,
  description?: LanguageInputInput | null,
  startpage?: LanguageInputInput | null,
  sort?: number | null,
  isPublished?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  sectionModuleId?: string | null,
};

export type ModelSectionConditionInput = {
  sort?: ModelIntInput | null,
  isPublished?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSectionConditionInput | null > | null,
  or?: Array< ModelSectionConditionInput | null > | null,
  not?: ModelSectionConditionInput | null,
};

export type UpdateSectionInput = {
  id: string,
  title?: LanguageInputInput | null,
  description?: LanguageInputInput | null,
  startpage?: LanguageInputInput | null,
  sort?: number | null,
  isPublished?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  sectionModuleId?: string | null,
};

export type DeleteSectionInput = {
  id: string,
};

export type CreatePageInput = {
  id?: string | null,
  title?: LanguageInputInput | null,
  editorState?: LanguageInputInput | null,
  editorStateDE?: string | null,
  editorStateEN?: string | null,
  sort?: number | null,
  isPublished?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  count?: number | null,
  pageSectionId?: string | null,
};

export type ModelPageConditionInput = {
  editorStateDE?: ModelStringInput | null,
  editorStateEN?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  isPublished?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  count?: ModelIntInput | null,
  and?: Array< ModelPageConditionInput | null > | null,
  or?: Array< ModelPageConditionInput | null > | null,
  not?: ModelPageConditionInput | null,
};

export type UpdatePageInput = {
  id: string,
  title?: LanguageInputInput | null,
  editorState?: LanguageInputInput | null,
  editorStateDE?: string | null,
  editorStateEN?: string | null,
  sort?: number | null,
  isPublished?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  count?: number | null,
  pageSectionId?: string | null,
};

export type DeletePageInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  status?: Status | null,
  lastLoginTime?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  image?: string | null,
  userType?: UserType | null,
  position?: LanguageInputInput | null,
  isTemporary?: boolean | null,
  onboarded?: boolean | null,
  ytTokens?: TokenInput | null,
  teamsTokens?: TokenInput | null,
  redirect?: string | null,
};

export type TokenInput = {
  accessToken?: string | null,
  refreshToken?: string | null,
  expires_in?: string | null,
};

export type ModelUserConditionInput = {
  status?: ModelStatusInput | null,
  lastLoginTime?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  image?: ModelStringInput | null,
  userType?: ModelUserTypeInput | null,
  isTemporary?: ModelBooleanInput | null,
  onboarded?: ModelBooleanInput | null,
  redirect?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export type ModelUserTypeInput = {
  eq?: UserType | null,
  ne?: UserType | null,
};

export type UpdateUserInput = {
  id: string,
  status?: Status | null,
  lastLoginTime?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  image?: string | null,
  userType?: UserType | null,
  position?: LanguageInputInput | null,
  isTemporary?: boolean | null,
  onboarded?: boolean | null,
  ytTokens?: TokenInput | null,
  teamsTokens?: TokenInput | null,
  redirect?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateModuleUsersInput = {
  id?: string | null,
  type?: string | null,
  moduleUsersModuleId?: string | null,
  moduleUsersUserId?: string | null,
};

export type ModelModuleUsersConditionInput = {
  type?: ModelStringInput | null,
  and?: Array< ModelModuleUsersConditionInput | null > | null,
  or?: Array< ModelModuleUsersConditionInput | null > | null,
  not?: ModelModuleUsersConditionInput | null,
};

export type UpdateModuleUsersInput = {
  id: string,
  type?: string | null,
  moduleUsersModuleId?: string | null,
  moduleUsersUserId?: string | null,
};

export type DeleteModuleUsersInput = {
  id: string,
};

export type CreateDepartmentStaffInput = {
  id?: string | null,
  departmentStaffDepartmentId?: string | null,
  departmentStaffUserId?: string | null,
};

export type ModelDepartmentStaffConditionInput = {
  and?: Array< ModelDepartmentStaffConditionInput | null > | null,
  or?: Array< ModelDepartmentStaffConditionInput | null > | null,
  not?: ModelDepartmentStaffConditionInput | null,
};

export type UpdateDepartmentStaffInput = {
  id: string,
  departmentStaffDepartmentId?: string | null,
  departmentStaffUserId?: string | null,
};

export type DeleteDepartmentStaffInput = {
  id: string,
};

export type CreateEventModulesInput = {
  id?: string | null,
  eventModulesEventId?: string | null,
  eventModulesModuleId?: string | null,
};

export type ModelEventModulesConditionInput = {
  and?: Array< ModelEventModulesConditionInput | null > | null,
  or?: Array< ModelEventModulesConditionInput | null > | null,
  not?: ModelEventModulesConditionInput | null,
};

export type UpdateEventModulesInput = {
  id: string,
  eventModulesEventId?: string | null,
  eventModulesModuleId?: string | null,
};

export type DeleteEventModulesInput = {
  id: string,
};

export type CreateSessionModulesInput = {
  id?: string | null,
  sessionModulesSessionId?: string | null,
  sessionModulesModuleId?: string | null,
};

export type ModelSessionModulesConditionInput = {
  and?: Array< ModelSessionModulesConditionInput | null > | null,
  or?: Array< ModelSessionModulesConditionInput | null > | null,
  not?: ModelSessionModulesConditionInput | null,
};

export type UpdateSessionModulesInput = {
  id: string,
  sessionModulesSessionId?: string | null,
  sessionModulesModuleId?: string | null,
};

export type DeleteSessionModulesInput = {
  id: string,
};

export type CreateSessionEnrollmentInput = {
  id?: string | null,
  type?: string | null,
  sessionEnrollmentSessionId?: string | null,
  sessionEnrollmentUserId?: string | null,
};

export type ModelSessionEnrollmentConditionInput = {
  type?: ModelStringInput | null,
  sessionEnrollmentSessionId?: ModelIDInput | null,
  and?: Array< ModelSessionEnrollmentConditionInput | null > | null,
  or?: Array< ModelSessionEnrollmentConditionInput | null > | null,
  not?: ModelSessionEnrollmentConditionInput | null,
};

export type UpdateSessionEnrollmentInput = {
  id: string,
  type?: string | null,
  sessionEnrollmentSessionId?: string | null,
  sessionEnrollmentUserId?: string | null,
};

export type DeleteSessionEnrollmentInput = {
  id: string,
};

export type CreateLikeInput = {
  id?: string | null,
  likeUserId?: string | null,
  likeMessageId?: string | null,
  likePageId?: string | null,
  likeModuleId?: string | null,
  likeSessionId?: string | null,
  likeEventId?: string | null,
};

export type ModelLikeConditionInput = {
  and?: Array< ModelLikeConditionInput | null > | null,
  or?: Array< ModelLikeConditionInput | null > | null,
  not?: ModelLikeConditionInput | null,
};

export type UpdateLikeInput = {
  id: string,
  likeUserId?: string | null,
  likeMessageId?: string | null,
  likePageId?: string | null,
  likeModuleId?: string | null,
  likeSessionId?: string | null,
  likeEventId?: string | null,
};

export type DeleteLikeInput = {
  id: string,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  editorStateDE?: ModelStringInput | null,
  editorStateEN?: ModelStringInput | null,
  contact?: ModelStringInput | null,
  primaryColor?: ModelStringInput | null,
  secondaryColor?: ModelStringInput | null,
  font?: ModelStringInput | null,
  backgroundImage?: ModelStringInput | null,
  standardCover?: ModelStringInput | null,
  features?: ModelStringInput | null,
  bbbServerUrl?: ModelStringInput | null,
  bbbServerSecret?: ModelStringInput | null,
  copyright?: ModelStringInput | null,
  maxEvents?: ModelIntInput | null,
  transactionalEmail?: ModelStringInput | null,
  cssOverride?: ModelStringInput | null,
  showAudienceFiltering?: ModelBooleanInput | null,
  loginRequired?: ModelBooleanInput | null,
  landingPageDisplay?: ModelLandingPageDisplayInput | null,
  videoUrl?: ModelStringInput | null,
  autoDeleteUsers?: ModelBooleanInput | null,
  dataPrivacy?: ModelStringInput | null,
  imprint?: ModelStringInput | null,
  showSessionGroupFiltering?: ModelBooleanInput | null,
  hideGlobalSearch?: ModelBooleanInput | null,
  hideArchivedSessions?: ModelBooleanInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
};

export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items?:  Array<Organization | null >,
  nextToken?: string | null,
};

export type ModelDepartmentFilterInput = {
  id?: ModelIDInput | null,
  primaryColor?: ModelStringInput | null,
  editorStateDE?: ModelStringInput | null,
  editorStateEN?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  website?: ModelStringInput | null,
  and?: Array< ModelDepartmentFilterInput | null > | null,
  or?: Array< ModelDepartmentFilterInput | null > | null,
  not?: ModelDepartmentFilterInput | null,
};

export type ModelProgramFilterInput = {
  id?: ModelIDInput | null,
  editorStateDE?: ModelStringInput | null,
  editorStateEN?: ModelStringInput | null,
  and?: Array< ModelProgramFilterInput | null > | null,
  or?: Array< ModelProgramFilterInput | null > | null,
  not?: ModelProgramFilterInput | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  state?: ModelEventStateInput | null,
  videoCover?: ModelStringInput | null,
  image?: ModelStringInput | null,
  imageAlt?: ModelStringInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  template?: ModelEventTemplateInput | null,
  count?: ModelIntInput | null,
  isRestricted?: ModelBooleanInput | null,
  group?: ModelStringInput | null,
  audience?: ModelUserTypeListInput | null,
  showInlineModules?: ModelBooleanInput | null,
  altHeaderStyle?: ModelBooleanInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
};

export type ModelEventEnrollmentsFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelEventEnrollmentsFilterInput | null > | null,
  or?: Array< ModelEventEnrollmentsFilterInput | null > | null,
  not?: ModelEventEnrollmentsFilterInput | null,
};

export type ModelProgramSessionFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelProgramSessionFilterInput | null > | null,
  or?: Array< ModelProgramSessionFilterInput | null > | null,
  not?: ModelProgramSessionFilterInput | null,
};

export type ModelSessionFilterInput = {
  id?: ModelIDInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  language?: ModelLanguageInput | null,
  type?: ModelSessionTypeInput | null,
  sessionState?: ModelSessionStateInput | null,
  stream?: ModelStringInput | null,
  chatEnabled?: ModelBooleanInput | null,
  conference?: ModelStringInput | null,
  isFree?: ModelBooleanInput | null,
  audience?: ModelUserTypeListInput | null,
  color?: ModelStringInput | null,
  image?: ModelStringInput | null,
  anonymous?: ModelBooleanInput | null,
  isRated?: ModelBooleanInput | null,
  isProtected?: ModelBooleanInput | null,
  password?: ModelStringInput | null,
  automaticActivationEnabled?: ModelBooleanInput | null,
  automaticArchiveEnabled?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  count?: ModelIntInput | null,
  isRestricted?: ModelBooleanInput | null,
  group?: ModelStringInput | null,
  link?: ModelStringInput | null,
  sessionDepartmentId?: ModelIDInput | null,
  and?: Array< ModelSessionFilterInput | null > | null,
  or?: Array< ModelSessionFilterInput | null > | null,
  not?: ModelSessionFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelRatingFilterInput = {
  id?: ModelIDInput | null,
  stars?: ModelIntInput | null,
  text?: ModelStringInput | null,
  and?: Array< ModelRatingFilterInput | null > | null,
  or?: Array< ModelRatingFilterInput | null > | null,
  not?: ModelRatingFilterInput | null,
};

export type ModelAuthEventFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAuthEventFilterInput | null > | null,
  or?: Array< ModelAuthEventFilterInput | null > | null,
  not?: ModelAuthEventFilterInput | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  body?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  sessionId?: ModelIDInput | null,
  anonymousUserName?: ModelStringInput | null,
  isFlagged?: ModelBooleanInput | null,
  flaggedBy?: ModelStringInput | null,
  isRepliedTo?: ModelBooleanInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type ModelModuleFilterInput = {
  id?: ModelIDInput | null,
  startpageEN?: ModelStringInput | null,
  startpageDE?: ModelStringInput | null,
  color?: ModelStringInput | null,
  isPublished?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  and?: Array< ModelModuleFilterInput | null > | null,
  or?: Array< ModelModuleFilterInput | null > | null,
  not?: ModelModuleFilterInput | null,
};

export type ModelSectionFilterInput = {
  id?: ModelIDInput | null,
  sort?: ModelIntInput | null,
  isPublished?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSectionFilterInput | null > | null,
  or?: Array< ModelSectionFilterInput | null > | null,
  not?: ModelSectionFilterInput | null,
};

export type ModelPageFilterInput = {
  id?: ModelIDInput | null,
  editorStateDE?: ModelStringInput | null,
  editorStateEN?: ModelStringInput | null,
  sort?: ModelIntInput | null,
  isPublished?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  count?: ModelIntInput | null,
  and?: Array< ModelPageFilterInput | null > | null,
  or?: Array< ModelPageFilterInput | null > | null,
  not?: ModelPageFilterInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelStatusInput | null,
  lastLoginTime?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  image?: ModelStringInput | null,
  userType?: ModelUserTypeInput | null,
  isTemporary?: ModelBooleanInput | null,
  onboarded?: ModelBooleanInput | null,
  redirect?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items?:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelModuleUsersFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelModuleUsersFilterInput | null > | null,
  or?: Array< ModelModuleUsersFilterInput | null > | null,
  not?: ModelModuleUsersFilterInput | null,
};

export type ModelDepartmentStaffFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelDepartmentStaffFilterInput | null > | null,
  or?: Array< ModelDepartmentStaffFilterInput | null > | null,
  not?: ModelDepartmentStaffFilterInput | null,
};

export type ModelEventModulesFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelEventModulesFilterInput | null > | null,
  or?: Array< ModelEventModulesFilterInput | null > | null,
  not?: ModelEventModulesFilterInput | null,
};

export type ModelSessionModulesFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelSessionModulesFilterInput | null > | null,
  or?: Array< ModelSessionModulesFilterInput | null > | null,
  not?: ModelSessionModulesFilterInput | null,
};

export type ModelSessionEnrollmentFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  sessionEnrollmentSessionId?: ModelIDInput | null,
  sessionEnrollmentUserId?: ModelIDInput | null,
  and?: Array< ModelSessionEnrollmentFilterInput | null > | null,
  or?: Array< ModelSessionEnrollmentFilterInput | null > | null,
  not?: ModelSessionEnrollmentFilterInput | null,
};

export type ModelLikeFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelLikeFilterInput | null > | null,
  or?: Array< ModelLikeFilterInput | null > | null,
  not?: ModelLikeFilterInput | null,
};

export type CreateOrganizationMutationVariables = {
  input?: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    platformTitle?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    website?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    contact?: string | null,
    logo?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    primaryColor?: string | null,
    secondaryColor?: string | null,
    font?: string | null,
    backgroundImage?: string | null,
    standardCover?: string | null,
    welcomeText?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    features?: Array< string | null > | null,
    bbbServerUrl?: string | null,
    bbbServerSecret?: string | null,
    currentSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    upcomingSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    archivedSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    copyright?: string | null,
    maxEvents?: number | null,
    transactionalEmail?: string | null,
    cssOverride?: string | null,
    showAudienceFiltering?: boolean | null,
    loginRequired?: boolean | null,
    landingPageDisplay?: LandingPageDisplay | null,
    videoUrl?: string | null,
    autoDeleteUsers?: boolean | null,
    dataPrivacy?: string | null,
    imprint?: string | null,
    showSessionGroupFiltering?: boolean | null,
    hideGlobalSearch?: boolean | null,
    hideArchivedSessions?: boolean | null,
    createdAt: string,
    updatedAt: string,
    departments?:  {
      __typename: "ModelDepartmentConnection",
      items:  Array< {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input?: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    platformTitle?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    website?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    contact?: string | null,
    logo?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    primaryColor?: string | null,
    secondaryColor?: string | null,
    font?: string | null,
    backgroundImage?: string | null,
    standardCover?: string | null,
    welcomeText?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    features?: Array< string | null > | null,
    bbbServerUrl?: string | null,
    bbbServerSecret?: string | null,
    currentSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    upcomingSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    archivedSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    copyright?: string | null,
    maxEvents?: number | null,
    transactionalEmail?: string | null,
    cssOverride?: string | null,
    showAudienceFiltering?: boolean | null,
    loginRequired?: boolean | null,
    landingPageDisplay?: LandingPageDisplay | null,
    videoUrl?: string | null,
    autoDeleteUsers?: boolean | null,
    dataPrivacy?: string | null,
    imprint?: string | null,
    showSessionGroupFiltering?: boolean | null,
    hideGlobalSearch?: boolean | null,
    hideArchivedSessions?: boolean | null,
    createdAt: string,
    updatedAt: string,
    departments?:  {
      __typename: "ModelDepartmentConnection",
      items:  Array< {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input?: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    platformTitle?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    website?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    contact?: string | null,
    logo?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    primaryColor?: string | null,
    secondaryColor?: string | null,
    font?: string | null,
    backgroundImage?: string | null,
    standardCover?: string | null,
    welcomeText?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    features?: Array< string | null > | null,
    bbbServerUrl?: string | null,
    bbbServerSecret?: string | null,
    currentSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    upcomingSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    archivedSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    copyright?: string | null,
    maxEvents?: number | null,
    transactionalEmail?: string | null,
    cssOverride?: string | null,
    showAudienceFiltering?: boolean | null,
    loginRequired?: boolean | null,
    landingPageDisplay?: LandingPageDisplay | null,
    videoUrl?: string | null,
    autoDeleteUsers?: boolean | null,
    dataPrivacy?: string | null,
    imprint?: string | null,
    showSessionGroupFiltering?: boolean | null,
    hideGlobalSearch?: boolean | null,
    hideArchivedSessions?: boolean | null,
    createdAt: string,
    updatedAt: string,
    departments?:  {
      __typename: "ModelDepartmentConnection",
      items:  Array< {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateDepartmentMutationVariables = {
  input?: CreateDepartmentInput,
  condition?: ModelDepartmentConditionInput | null,
};

export type CreateDepartmentMutation = {
  createDepartment?:  {
    __typename: "Department",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    primaryColor?: string | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    logo?: string | null,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff?:  {
      __typename: "ModelDepartmentStaffConnection",
      items:  Array< {
        __typename: "DepartmentStaff",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateDepartmentMutationVariables = {
  input?: UpdateDepartmentInput,
  condition?: ModelDepartmentConditionInput | null,
};

export type UpdateDepartmentMutation = {
  updateDepartment?:  {
    __typename: "Department",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    primaryColor?: string | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    logo?: string | null,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff?:  {
      __typename: "ModelDepartmentStaffConnection",
      items:  Array< {
        __typename: "DepartmentStaff",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteDepartmentMutationVariables = {
  input?: DeleteDepartmentInput,
  condition?: ModelDepartmentConditionInput | null,
};

export type DeleteDepartmentMutation = {
  deleteDepartment?:  {
    __typename: "Department",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    primaryColor?: string | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    logo?: string | null,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff?:  {
      __typename: "ModelDepartmentStaffConnection",
      items:  Array< {
        __typename: "DepartmentStaff",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateProgramMutationVariables = {
  input?: CreateProgramInput,
  condition?: ModelProgramConditionInput | null,
};

export type CreateProgramMutation = {
  createProgram?:  {
    __typename: "Program",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    createdAt: string,
    updatedAt: string,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    sessions?:  {
      __typename: "ModelProgramSessionConnection",
      items:  Array< {
        __typename: "ProgramSession",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateProgramMutationVariables = {
  input?: UpdateProgramInput,
  condition?: ModelProgramConditionInput | null,
};

export type UpdateProgramMutation = {
  updateProgram?:  {
    __typename: "Program",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    createdAt: string,
    updatedAt: string,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    sessions?:  {
      __typename: "ModelProgramSessionConnection",
      items:  Array< {
        __typename: "ProgramSession",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteProgramMutationVariables = {
  input?: DeleteProgramInput,
  condition?: ModelProgramConditionInput | null,
};

export type DeleteProgramMutation = {
  deleteProgram?:  {
    __typename: "Program",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    createdAt: string,
    updatedAt: string,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    sessions?:  {
      __typename: "ModelProgramSessionConnection",
      items:  Array< {
        __typename: "ProgramSession",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateEventMutationVariables = {
  input?: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    state?: EventState | null,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoUrl?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoCover?: string | null,
    image?: string | null,
    imageAlt?: string | null,
    start?: string | null,
    end?: string | null,
    template?: EventTemplate | null,
    count?: number | null,
    isRestricted?: boolean | null,
    group?: string | null,
    audience?: Array< UserType | null > | null,
    showInlineModules?: boolean | null,
    altHeaderStyle?: boolean | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    enrollments?:  {
      __typename: "ModelEventEnrollmentsConnection",
      items:  Array< {
        __typename: "EventEnrollments",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateEventMutationVariables = {
  input?: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    state?: EventState | null,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoUrl?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoCover?: string | null,
    image?: string | null,
    imageAlt?: string | null,
    start?: string | null,
    end?: string | null,
    template?: EventTemplate | null,
    count?: number | null,
    isRestricted?: boolean | null,
    group?: string | null,
    audience?: Array< UserType | null > | null,
    showInlineModules?: boolean | null,
    altHeaderStyle?: boolean | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    enrollments?:  {
      __typename: "ModelEventEnrollmentsConnection",
      items:  Array< {
        __typename: "EventEnrollments",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteEventMutationVariables = {
  input?: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    state?: EventState | null,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoUrl?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoCover?: string | null,
    image?: string | null,
    imageAlt?: string | null,
    start?: string | null,
    end?: string | null,
    template?: EventTemplate | null,
    count?: number | null,
    isRestricted?: boolean | null,
    group?: string | null,
    audience?: Array< UserType | null > | null,
    showInlineModules?: boolean | null,
    altHeaderStyle?: boolean | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    enrollments?:  {
      __typename: "ModelEventEnrollmentsConnection",
      items:  Array< {
        __typename: "EventEnrollments",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateEventEnrollmentsMutationVariables = {
  input?: CreateEventEnrollmentsInput,
  condition?: ModelEventEnrollmentsConditionInput | null,
};

export type CreateEventEnrollmentsMutation = {
  createEventEnrollments?:  {
    __typename: "EventEnrollments",
    id: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateEventEnrollmentsMutationVariables = {
  input?: UpdateEventEnrollmentsInput,
  condition?: ModelEventEnrollmentsConditionInput | null,
};

export type UpdateEventEnrollmentsMutation = {
  updateEventEnrollments?:  {
    __typename: "EventEnrollments",
    id: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteEventEnrollmentsMutationVariables = {
  input?: DeleteEventEnrollmentsInput,
  condition?: ModelEventEnrollmentsConditionInput | null,
};

export type DeleteEventEnrollmentsMutation = {
  deleteEventEnrollments?:  {
    __typename: "EventEnrollments",
    id: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateProgramSessionMutationVariables = {
  input?: CreateProgramSessionInput,
  condition?: ModelProgramSessionConditionInput | null,
};

export type CreateProgramSessionMutation = {
  createProgramSession?:  {
    __typename: "ProgramSession",
    id: string,
    createdAt: string,
    updatedAt: string,
    program?:  {
      __typename: "Program",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      createdAt: string,
      updatedAt: string,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      sessions?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateProgramSessionMutationVariables = {
  input?: UpdateProgramSessionInput,
  condition?: ModelProgramSessionConditionInput | null,
};

export type UpdateProgramSessionMutation = {
  updateProgramSession?:  {
    __typename: "ProgramSession",
    id: string,
    createdAt: string,
    updatedAt: string,
    program?:  {
      __typename: "Program",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      createdAt: string,
      updatedAt: string,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      sessions?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteProgramSessionMutationVariables = {
  input?: DeleteProgramSessionInput,
  condition?: ModelProgramSessionConditionInput | null,
};

export type DeleteProgramSessionMutation = {
  deleteProgramSession?:  {
    __typename: "ProgramSession",
    id: string,
    createdAt: string,
    updatedAt: string,
    program?:  {
      __typename: "Program",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      createdAt: string,
      updatedAt: string,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      sessions?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSessionMutationVariables = {
  input?: CreateSessionInput,
  condition?: ModelSessionConditionInput | null,
};

export type CreateSessionMutation = {
  createSession?:  {
    __typename: "Session",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    start?: string | null,
    end?: string | null,
    language?: Language | null,
    type?: SessionType | null,
    sessionState?: SessionState | null,
    stream?: string | null,
    chatEnabled?: boolean | null,
    conference?: string | null,
    module?:  Array< {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    isFree?: boolean | null,
    audience?: Array< UserType | null > | null,
    color?: string | null,
    resources?:  Array< {
      __typename: "Resource",
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      link?: string | null,
    } | null > | null,
    image?: string | null,
    anonymous?: boolean | null,
    isRated?: boolean | null,
    isProtected?: boolean | null,
    password?: string | null,
    automaticActivationEnabled?: boolean | null,
    automaticArchiveEnabled?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    zoom?:  {
      __typename: "Zoom",
      start_url?: string | null,
      join_url?: string | null,
      id?: string | null,
    } | null,
    teams?:  {
      __typename: "Teams",
      joinUrl?: string | null,
      id?: string | null,
    } | null,
    count?: number | null,
    isRestricted?: boolean | null,
    group?: string | null,
    link?: string | null,
    sessionDepartmentId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    program?:  {
      __typename: "ModelProgramSessionConnection",
      items:  Array< {
        __typename: "ProgramSession",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ratings?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    enrollments?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        id: string,
        type?: string | null,
        sessionEnrollmentSessionId?: string | null,
        sessionEnrollmentUserId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateSessionMutationVariables = {
  input?: UpdateSessionInput,
  condition?: ModelSessionConditionInput | null,
};

export type UpdateSessionMutation = {
  updateSession?:  {
    __typename: "Session",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    start?: string | null,
    end?: string | null,
    language?: Language | null,
    type?: SessionType | null,
    sessionState?: SessionState | null,
    stream?: string | null,
    chatEnabled?: boolean | null,
    conference?: string | null,
    module?:  Array< {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    isFree?: boolean | null,
    audience?: Array< UserType | null > | null,
    color?: string | null,
    resources?:  Array< {
      __typename: "Resource",
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      link?: string | null,
    } | null > | null,
    image?: string | null,
    anonymous?: boolean | null,
    isRated?: boolean | null,
    isProtected?: boolean | null,
    password?: string | null,
    automaticActivationEnabled?: boolean | null,
    automaticArchiveEnabled?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    zoom?:  {
      __typename: "Zoom",
      start_url?: string | null,
      join_url?: string | null,
      id?: string | null,
    } | null,
    teams?:  {
      __typename: "Teams",
      joinUrl?: string | null,
      id?: string | null,
    } | null,
    count?: number | null,
    isRestricted?: boolean | null,
    group?: string | null,
    link?: string | null,
    sessionDepartmentId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    program?:  {
      __typename: "ModelProgramSessionConnection",
      items:  Array< {
        __typename: "ProgramSession",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ratings?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    enrollments?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        id: string,
        type?: string | null,
        sessionEnrollmentSessionId?: string | null,
        sessionEnrollmentUserId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteSessionMutationVariables = {
  input?: DeleteSessionInput,
  condition?: ModelSessionConditionInput | null,
};

export type DeleteSessionMutation = {
  deleteSession?:  {
    __typename: "Session",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    start?: string | null,
    end?: string | null,
    language?: Language | null,
    type?: SessionType | null,
    sessionState?: SessionState | null,
    stream?: string | null,
    chatEnabled?: boolean | null,
    conference?: string | null,
    module?:  Array< {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    isFree?: boolean | null,
    audience?: Array< UserType | null > | null,
    color?: string | null,
    resources?:  Array< {
      __typename: "Resource",
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      link?: string | null,
    } | null > | null,
    image?: string | null,
    anonymous?: boolean | null,
    isRated?: boolean | null,
    isProtected?: boolean | null,
    password?: string | null,
    automaticActivationEnabled?: boolean | null,
    automaticArchiveEnabled?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    zoom?:  {
      __typename: "Zoom",
      start_url?: string | null,
      join_url?: string | null,
      id?: string | null,
    } | null,
    teams?:  {
      __typename: "Teams",
      joinUrl?: string | null,
      id?: string | null,
    } | null,
    count?: number | null,
    isRestricted?: boolean | null,
    group?: string | null,
    link?: string | null,
    sessionDepartmentId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    program?:  {
      __typename: "ModelProgramSessionConnection",
      items:  Array< {
        __typename: "ProgramSession",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ratings?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    enrollments?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        id: string,
        type?: string | null,
        sessionEnrollmentSessionId?: string | null,
        sessionEnrollmentUserId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateRatingMutationVariables = {
  input?: CreateRatingInput,
  condition?: ModelRatingConditionInput | null,
};

export type CreateRatingMutation = {
  createRating?:  {
    __typename: "Rating",
    id: string,
    stars?: number | null,
    text?: string | null,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateRatingMutationVariables = {
  input?: UpdateRatingInput,
  condition?: ModelRatingConditionInput | null,
};

export type UpdateRatingMutation = {
  updateRating?:  {
    __typename: "Rating",
    id: string,
    stars?: number | null,
    text?: string | null,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteRatingMutationVariables = {
  input?: DeleteRatingInput,
  condition?: ModelRatingConditionInput | null,
};

export type DeleteRatingMutation = {
  deleteRating?:  {
    __typename: "Rating",
    id: string,
    stars?: number | null,
    text?: string | null,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateAuthEventMutationVariables = {
  input?: CreateAuthEventInput,
  condition?: ModelAuthEventConditionInput | null,
};

export type CreateAuthEventMutation = {
  createAuthEvent?:  {
    __typename: "AuthEvent",
    id: string,
    type?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateAuthEventMutationVariables = {
  input?: UpdateAuthEventInput,
  condition?: ModelAuthEventConditionInput | null,
};

export type UpdateAuthEventMutation = {
  updateAuthEvent?:  {
    __typename: "AuthEvent",
    id: string,
    type?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteAuthEventMutationVariables = {
  input?: DeleteAuthEventInput,
  condition?: ModelAuthEventConditionInput | null,
};

export type DeleteAuthEventMutation = {
  deleteAuthEvent?:  {
    __typename: "AuthEvent",
    id: string,
    type?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateMessageMutationVariables = {
  input?: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    body?: string | null,
    createdAt?: string | null,
    sessionId?: string | null,
    anonymousUserName?: string | null,
    replies?:  Array< {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    isFlagged?: boolean | null,
    flaggedBy?: string | null,
    isRepliedTo?: boolean | null,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input?: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    body?: string | null,
    createdAt?: string | null,
    sessionId?: string | null,
    anonymousUserName?: string | null,
    replies?:  Array< {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    isFlagged?: boolean | null,
    flaggedBy?: string | null,
    isRepliedTo?: boolean | null,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input?: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    body?: string | null,
    createdAt?: string | null,
    sessionId?: string | null,
    anonymousUserName?: string | null,
    replies?:  Array< {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    isFlagged?: boolean | null,
    flaggedBy?: string | null,
    isRepliedTo?: boolean | null,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateModuleMutationVariables = {
  input?: CreateModuleInput,
  condition?: ModelModuleConditionInput | null,
};

export type CreateModuleMutation = {
  createModule?:  {
    __typename: "Module",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpage?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpageEN?: string | null,
    startpageDE?: string | null,
    color?: string | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    sort?: number | null,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    rating?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateModuleMutationVariables = {
  input?: UpdateModuleInput,
  condition?: ModelModuleConditionInput | null,
};

export type UpdateModuleMutation = {
  updateModule?:  {
    __typename: "Module",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpage?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpageEN?: string | null,
    startpageDE?: string | null,
    color?: string | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    sort?: number | null,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    rating?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteModuleMutationVariables = {
  input?: DeleteModuleInput,
  condition?: ModelModuleConditionInput | null,
};

export type DeleteModuleMutation = {
  deleteModule?:  {
    __typename: "Module",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpage?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpageEN?: string | null,
    startpageDE?: string | null,
    color?: string | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    sort?: number | null,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    rating?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateSectionMutationVariables = {
  input?: CreateSectionInput,
  condition?: ModelSectionConditionInput | null,
};

export type CreateSectionMutation = {
  createSection?:  {
    __typename: "Section",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpage?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    sort?: number | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    pages?:  {
      __typename: "ModelPageConnection",
      items:  Array< {
        __typename: "Page",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateSectionMutationVariables = {
  input?: UpdateSectionInput,
  condition?: ModelSectionConditionInput | null,
};

export type UpdateSectionMutation = {
  updateSection?:  {
    __typename: "Section",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpage?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    sort?: number | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    pages?:  {
      __typename: "ModelPageConnection",
      items:  Array< {
        __typename: "Page",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteSectionMutationVariables = {
  input?: DeleteSectionInput,
  condition?: ModelSectionConditionInput | null,
};

export type DeleteSectionMutation = {
  deleteSection?:  {
    __typename: "Section",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpage?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    sort?: number | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    pages?:  {
      __typename: "ModelPageConnection",
      items:  Array< {
        __typename: "Page",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreatePageMutationVariables = {
  input?: CreatePageInput,
  condition?: ModelPageConditionInput | null,
};

export type CreatePageMutation = {
  createPage?:  {
    __typename: "Page",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorState?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    sort?: number | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    count?: number | null,
    section?:  {
      __typename: "Section",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      module?:  {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null,
      pages?:  {
        __typename: "ModelPageConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdatePageMutationVariables = {
  input?: UpdatePageInput,
  condition?: ModelPageConditionInput | null,
};

export type UpdatePageMutation = {
  updatePage?:  {
    __typename: "Page",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorState?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    sort?: number | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    count?: number | null,
    section?:  {
      __typename: "Section",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      module?:  {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null,
      pages?:  {
        __typename: "ModelPageConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeletePageMutationVariables = {
  input?: DeletePageInput,
  condition?: ModelPageConditionInput | null,
};

export type DeletePageMutation = {
  deletePage?:  {
    __typename: "Page",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorState?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    sort?: number | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    count?: number | null,
    section?:  {
      __typename: "Section",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      module?:  {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null,
      pages?:  {
        __typename: "ModelPageConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input?: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    status?: Status | null,
    firstName?: string | null,
    lastName?: string | null,
    image?: string | null,
    userType?: UserType | null,
    position?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    isTemporary?: boolean | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    events?:  {
      __typename: "ModelEventEnrollmentsConnection",
      items:  Array< {
        __typename: "EventEnrollments",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ratings?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    authEvents?:  {
      __typename: "ModelAuthEventConnection",
      items:  Array< {
        __typename: "AuthEvent",
        id: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLoginTime?: string | null,
    email?: string | null,
    ytTokens?:  {
      __typename: "Token",
      accessToken?: string | null,
      refreshToken?: string | null,
      expires_in?: string | null,
    } | null,
    teamsTokens?:  {
      __typename: "Token",
      accessToken?: string | null,
      refreshToken?: string | null,
      expires_in?: string | null,
    } | null,
    redirect?: string | null,
    modules?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    departments?:  {
      __typename: "ModelDepartmentStaffConnection",
      items:  Array< {
        __typename: "DepartmentStaff",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        id: string,
        type?: string | null,
        sessionEnrollmentSessionId?: string | null,
        sessionEnrollmentUserId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input?: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    status?: Status | null,
    firstName?: string | null,
    lastName?: string | null,
    image?: string | null,
    userType?: UserType | null,
    position?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    isTemporary?: boolean | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    events?:  {
      __typename: "ModelEventEnrollmentsConnection",
      items:  Array< {
        __typename: "EventEnrollments",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ratings?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    authEvents?:  {
      __typename: "ModelAuthEventConnection",
      items:  Array< {
        __typename: "AuthEvent",
        id: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLoginTime?: string | null,
    email?: string | null,
    ytTokens?:  {
      __typename: "Token",
      accessToken?: string | null,
      refreshToken?: string | null,
      expires_in?: string | null,
    } | null,
    teamsTokens?:  {
      __typename: "Token",
      accessToken?: string | null,
      refreshToken?: string | null,
      expires_in?: string | null,
    } | null,
    redirect?: string | null,
    modules?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    departments?:  {
      __typename: "ModelDepartmentStaffConnection",
      items:  Array< {
        __typename: "DepartmentStaff",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        id: string,
        type?: string | null,
        sessionEnrollmentSessionId?: string | null,
        sessionEnrollmentUserId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input?: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    status?: Status | null,
    firstName?: string | null,
    lastName?: string | null,
    image?: string | null,
    userType?: UserType | null,
    position?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    isTemporary?: boolean | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    events?:  {
      __typename: "ModelEventEnrollmentsConnection",
      items:  Array< {
        __typename: "EventEnrollments",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ratings?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    authEvents?:  {
      __typename: "ModelAuthEventConnection",
      items:  Array< {
        __typename: "AuthEvent",
        id: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLoginTime?: string | null,
    email?: string | null,
    ytTokens?:  {
      __typename: "Token",
      accessToken?: string | null,
      refreshToken?: string | null,
      expires_in?: string | null,
    } | null,
    teamsTokens?:  {
      __typename: "Token",
      accessToken?: string | null,
      refreshToken?: string | null,
      expires_in?: string | null,
    } | null,
    redirect?: string | null,
    modules?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    departments?:  {
      __typename: "ModelDepartmentStaffConnection",
      items:  Array< {
        __typename: "DepartmentStaff",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        id: string,
        type?: string | null,
        sessionEnrollmentSessionId?: string | null,
        sessionEnrollmentUserId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateModuleUsersMutationVariables = {
  input?: CreateModuleUsersInput,
  condition?: ModelModuleUsersConditionInput | null,
};

export type CreateModuleUsersMutation = {
  createModuleUsers?:  {
    __typename: "ModuleUsers",
    id: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateModuleUsersMutationVariables = {
  input?: UpdateModuleUsersInput,
  condition?: ModelModuleUsersConditionInput | null,
};

export type UpdateModuleUsersMutation = {
  updateModuleUsers?:  {
    __typename: "ModuleUsers",
    id: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteModuleUsersMutationVariables = {
  input?: DeleteModuleUsersInput,
  condition?: ModelModuleUsersConditionInput | null,
};

export type DeleteModuleUsersMutation = {
  deleteModuleUsers?:  {
    __typename: "ModuleUsers",
    id: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateDepartmentStaffMutationVariables = {
  input?: CreateDepartmentStaffInput,
  condition?: ModelDepartmentStaffConditionInput | null,
};

export type CreateDepartmentStaffMutation = {
  createDepartmentStaff?:  {
    __typename: "DepartmentStaff",
    id: string,
    createdAt: string,
    updatedAt: string,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateDepartmentStaffMutationVariables = {
  input?: UpdateDepartmentStaffInput,
  condition?: ModelDepartmentStaffConditionInput | null,
};

export type UpdateDepartmentStaffMutation = {
  updateDepartmentStaff?:  {
    __typename: "DepartmentStaff",
    id: string,
    createdAt: string,
    updatedAt: string,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteDepartmentStaffMutationVariables = {
  input?: DeleteDepartmentStaffInput,
  condition?: ModelDepartmentStaffConditionInput | null,
};

export type DeleteDepartmentStaffMutation = {
  deleteDepartmentStaff?:  {
    __typename: "DepartmentStaff",
    id: string,
    createdAt: string,
    updatedAt: string,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateEventModulesMutationVariables = {
  input?: CreateEventModulesInput,
  condition?: ModelEventModulesConditionInput | null,
};

export type CreateEventModulesMutation = {
  createEventModules?:  {
    __typename: "EventModules",
    id: string,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateEventModulesMutationVariables = {
  input?: UpdateEventModulesInput,
  condition?: ModelEventModulesConditionInput | null,
};

export type UpdateEventModulesMutation = {
  updateEventModules?:  {
    __typename: "EventModules",
    id: string,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteEventModulesMutationVariables = {
  input?: DeleteEventModulesInput,
  condition?: ModelEventModulesConditionInput | null,
};

export type DeleteEventModulesMutation = {
  deleteEventModules?:  {
    __typename: "EventModules",
    id: string,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSessionModulesMutationVariables = {
  input?: CreateSessionModulesInput,
  condition?: ModelSessionModulesConditionInput | null,
};

export type CreateSessionModulesMutation = {
  createSessionModules?:  {
    __typename: "SessionModules",
    id: string,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSessionModulesMutationVariables = {
  input?: UpdateSessionModulesInput,
  condition?: ModelSessionModulesConditionInput | null,
};

export type UpdateSessionModulesMutation = {
  updateSessionModules?:  {
    __typename: "SessionModules",
    id: string,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSessionModulesMutationVariables = {
  input?: DeleteSessionModulesInput,
  condition?: ModelSessionModulesConditionInput | null,
};

export type DeleteSessionModulesMutation = {
  deleteSessionModules?:  {
    __typename: "SessionModules",
    id: string,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSessionEnrollmentMutationVariables = {
  input?: CreateSessionEnrollmentInput,
  condition?: ModelSessionEnrollmentConditionInput | null,
};

export type CreateSessionEnrollmentMutation = {
  createSessionEnrollment?:  {
    __typename: "SessionEnrollment",
    id: string,
    type?: string | null,
    sessionEnrollmentSessionId?: string | null,
    sessionEnrollmentUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateSessionEnrollmentMutationVariables = {
  input?: UpdateSessionEnrollmentInput,
  condition?: ModelSessionEnrollmentConditionInput | null,
};

export type UpdateSessionEnrollmentMutation = {
  updateSessionEnrollment?:  {
    __typename: "SessionEnrollment",
    id: string,
    type?: string | null,
    sessionEnrollmentSessionId?: string | null,
    sessionEnrollmentUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteSessionEnrollmentMutationVariables = {
  input?: DeleteSessionEnrollmentInput,
  condition?: ModelSessionEnrollmentConditionInput | null,
};

export type DeleteSessionEnrollmentMutation = {
  deleteSessionEnrollment?:  {
    __typename: "SessionEnrollment",
    id: string,
    type?: string | null,
    sessionEnrollmentSessionId?: string | null,
    sessionEnrollmentUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateLikeMutationVariables = {
  input?: CreateLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type CreateLikeMutation = {
  createLike?:  {
    __typename: "Like",
    id: string,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    message?:  {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    page?:  {
      __typename: "Page",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorState?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      count?: number | null,
      section?:  {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateLikeMutationVariables = {
  input?: UpdateLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type UpdateLikeMutation = {
  updateLike?:  {
    __typename: "Like",
    id: string,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    message?:  {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    page?:  {
      __typename: "Page",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorState?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      count?: number | null,
      section?:  {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteLikeMutationVariables = {
  input?: DeleteLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type DeleteLikeMutation = {
  deleteLike?:  {
    __typename: "Like",
    id: string,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    message?:  {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    page?:  {
      __typename: "Page",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorState?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      count?: number | null,
      section?:  {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id?: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    platformTitle?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    website?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    contact?: string | null,
    logo?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    primaryColor?: string | null,
    secondaryColor?: string | null,
    font?: string | null,
    backgroundImage?: string | null,
    standardCover?: string | null,
    welcomeText?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    features?: Array< string | null > | null,
    bbbServerUrl?: string | null,
    bbbServerSecret?: string | null,
    currentSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    upcomingSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    archivedSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    copyright?: string | null,
    maxEvents?: number | null,
    transactionalEmail?: string | null,
    cssOverride?: string | null,
    showAudienceFiltering?: boolean | null,
    loginRequired?: boolean | null,
    landingPageDisplay?: LandingPageDisplay | null,
    videoUrl?: string | null,
    autoDeleteUsers?: boolean | null,
    dataPrivacy?: string | null,
    imprint?: string | null,
    showSessionGroupFiltering?: boolean | null,
    hideGlobalSearch?: boolean | null,
    hideArchivedSessions?: boolean | null,
    createdAt: string,
    updatedAt: string,
    departments?:  {
      __typename: "ModelDepartmentConnection",
      items:  Array< {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDepartmentQueryVariables = {
  id?: string,
};

export type GetDepartmentQuery = {
  getDepartment?:  {
    __typename: "Department",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    primaryColor?: string | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    logo?: string | null,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff?:  {
      __typename: "ModelDepartmentStaffConnection",
      items:  Array< {
        __typename: "DepartmentStaff",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListDepartmentsQueryVariables = {
  filter?: ModelDepartmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDepartmentsQuery = {
  listDepartments?:  {
    __typename: "ModelDepartmentConnection",
    items:  Array< {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProgramQueryVariables = {
  id?: string,
};

export type GetProgramQuery = {
  getProgram?:  {
    __typename: "Program",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    createdAt: string,
    updatedAt: string,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    sessions?:  {
      __typename: "ModelProgramSessionConnection",
      items:  Array< {
        __typename: "ProgramSession",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListProgramsQueryVariables = {
  filter?: ModelProgramFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProgramsQuery = {
  listPrograms?:  {
    __typename: "ModelProgramConnection",
    items:  Array< {
      __typename: "Program",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      createdAt: string,
      updatedAt: string,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      sessions?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventQueryVariables = {
  id?: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    state?: EventState | null,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoUrl?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoCover?: string | null,
    image?: string | null,
    imageAlt?: string | null,
    start?: string | null,
    end?: string | null,
    template?: EventTemplate | null,
    count?: number | null,
    isRestricted?: boolean | null,
    group?: string | null,
    audience?: Array< UserType | null > | null,
    showInlineModules?: boolean | null,
    altHeaderStyle?: boolean | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    enrollments?:  {
      __typename: "ModelEventEnrollmentsConnection",
      items:  Array< {
        __typename: "EventEnrollments",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventEnrollmentsQueryVariables = {
  id?: string,
};

export type GetEventEnrollmentsQuery = {
  getEventEnrollments?:  {
    __typename: "EventEnrollments",
    id: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListEventEnrollmentssQueryVariables = {
  filter?: ModelEventEnrollmentsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventEnrollmentssQuery = {
  listEventEnrollmentss?:  {
    __typename: "ModelEventEnrollmentsConnection",
    items:  Array< {
      __typename: "EventEnrollments",
      id: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProgramSessionQueryVariables = {
  id?: string,
};

export type GetProgramSessionQuery = {
  getProgramSession?:  {
    __typename: "ProgramSession",
    id: string,
    createdAt: string,
    updatedAt: string,
    program?:  {
      __typename: "Program",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      createdAt: string,
      updatedAt: string,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      sessions?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListProgramSessionsQueryVariables = {
  filter?: ModelProgramSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProgramSessionsQuery = {
  listProgramSessions?:  {
    __typename: "ModelProgramSessionConnection",
    items:  Array< {
      __typename: "ProgramSession",
      id: string,
      createdAt: string,
      updatedAt: string,
      program?:  {
        __typename: "Program",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSessionQueryVariables = {
  id?: string,
};

export type GetSessionQuery = {
  getSession?:  {
    __typename: "Session",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    start?: string | null,
    end?: string | null,
    language?: Language | null,
    type?: SessionType | null,
    sessionState?: SessionState | null,
    stream?: string | null,
    chatEnabled?: boolean | null,
    conference?: string | null,
    module?:  Array< {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    isFree?: boolean | null,
    audience?: Array< UserType | null > | null,
    color?: string | null,
    resources?:  Array< {
      __typename: "Resource",
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      link?: string | null,
    } | null > | null,
    image?: string | null,
    anonymous?: boolean | null,
    isRated?: boolean | null,
    isProtected?: boolean | null,
    password?: string | null,
    automaticActivationEnabled?: boolean | null,
    automaticArchiveEnabled?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    zoom?:  {
      __typename: "Zoom",
      start_url?: string | null,
      join_url?: string | null,
      id?: string | null,
    } | null,
    teams?:  {
      __typename: "Teams",
      joinUrl?: string | null,
      id?: string | null,
    } | null,
    count?: number | null,
    isRestricted?: boolean | null,
    group?: string | null,
    link?: string | null,
    sessionDepartmentId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    program?:  {
      __typename: "ModelProgramSessionConnection",
      items:  Array< {
        __typename: "ProgramSession",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ratings?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    enrollments?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        id: string,
        type?: string | null,
        sessionEnrollmentSessionId?: string | null,
        sessionEnrollmentUserId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListSessionsQueryVariables = {
  filter?: ModelSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSessionsQuery = {
  listSessions?:  {
    __typename: "ModelSessionConnection",
    items:  Array< {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SessionsByStateAndAndStartTimeQueryVariables = {
  sessionState?: SessionState | null,
  start?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SessionsByStateAndAndStartTimeQuery = {
  sessionsByStateAndAndStartTime?:  {
    __typename: "ModelSessionConnection",
    items:  Array< {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SessionsByStateAndAndEndTimeQueryVariables = {
  sessionState?: SessionState | null,
  end?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SessionsByStateAndAndEndTimeQuery = {
  sessionsByStateAndAndEndTime?:  {
    __typename: "ModelSessionConnection",
    items:  Array< {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRatingQueryVariables = {
  id?: string,
};

export type GetRatingQuery = {
  getRating?:  {
    __typename: "Rating",
    id: string,
    stars?: number | null,
    text?: string | null,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListRatingsQueryVariables = {
  filter?: ModelRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRatingsQuery = {
  listRatings?:  {
    __typename: "ModelRatingConnection",
    items:  Array< {
      __typename: "Rating",
      id: string,
      stars?: number | null,
      text?: string | null,
      createdAt: string,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      module?:  {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAuthEventQueryVariables = {
  id?: string,
};

export type GetAuthEventQuery = {
  getAuthEvent?:  {
    __typename: "AuthEvent",
    id: string,
    type?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListAuthEventsQueryVariables = {
  filter?: ModelAuthEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAuthEventsQuery = {
  listAuthEvents?:  {
    __typename: "ModelAuthEventConnection",
    items:  Array< {
      __typename: "AuthEvent",
      id: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AuthEventsByTypeAndDateQueryVariables = {
  type?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAuthEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AuthEventsByTypeAndDateQuery = {
  authEventsByTypeAndDate?:  {
    __typename: "ModelAuthEventConnection",
    items:  Array< {
      __typename: "AuthEvent",
      id: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt: string,
      user?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id?: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    id: string,
    body?: string | null,
    createdAt?: string | null,
    sessionId?: string | null,
    anonymousUserName?: string | null,
    replies?:  Array< {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    isFlagged?: boolean | null,
    flaggedBy?: string | null,
    isRepliedTo?: boolean | null,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesQuery = {
  listMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetModuleQueryVariables = {
  id?: string,
};

export type GetModuleQuery = {
  getModule?:  {
    __typename: "Module",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpage?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpageEN?: string | null,
    startpageDE?: string | null,
    color?: string | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    sort?: number | null,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    rating?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListModulesQueryVariables = {
  filter?: ModelModuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListModulesQuery = {
  listModules?:  {
    __typename: "ModelModuleConnection",
    items:  Array< {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSectionQueryVariables = {
  id?: string,
};

export type GetSectionQuery = {
  getSection?:  {
    __typename: "Section",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpage?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    sort?: number | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    pages?:  {
      __typename: "ModelPageConnection",
      items:  Array< {
        __typename: "Page",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListSectionsQueryVariables = {
  filter?: ModelSectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSectionsQuery = {
  listSections?:  {
    __typename: "ModelSectionConnection",
    items:  Array< {
      __typename: "Section",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      module?:  {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null,
      pages?:  {
        __typename: "ModelPageConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPageQueryVariables = {
  id?: string,
};

export type GetPageQuery = {
  getPage?:  {
    __typename: "Page",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorState?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    sort?: number | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    count?: number | null,
    section?:  {
      __typename: "Section",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      module?:  {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null,
      pages?:  {
        __typename: "ModelPageConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListPagesQueryVariables = {
  filter?: ModelPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPagesQuery = {
  listPages?:  {
    __typename: "ModelPageConnection",
    items:  Array< {
      __typename: "Page",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorState?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      count?: number | null,
      section?:  {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByEmailQuery = {
  usersByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByStatusAndLastLoginTimeQueryVariables = {
  status?: Status | null,
  lastLoginTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByStatusAndLastLoginTimeQuery = {
  usersByStatusAndLastLoginTime?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id?: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    status?: Status | null,
    firstName?: string | null,
    lastName?: string | null,
    image?: string | null,
    userType?: UserType | null,
    position?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    isTemporary?: boolean | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    events?:  {
      __typename: "ModelEventEnrollmentsConnection",
      items:  Array< {
        __typename: "EventEnrollments",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ratings?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    authEvents?:  {
      __typename: "ModelAuthEventConnection",
      items:  Array< {
        __typename: "AuthEvent",
        id: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLoginTime?: string | null,
    email?: string | null,
    ytTokens?:  {
      __typename: "Token",
      accessToken?: string | null,
      refreshToken?: string | null,
      expires_in?: string | null,
    } | null,
    teamsTokens?:  {
      __typename: "Token",
      accessToken?: string | null,
      refreshToken?: string | null,
      expires_in?: string | null,
    } | null,
    redirect?: string | null,
    modules?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    departments?:  {
      __typename: "ModelDepartmentStaffConnection",
      items:  Array< {
        __typename: "DepartmentStaff",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        id: string,
        type?: string | null,
        sessionEnrollmentSessionId?: string | null,
        sessionEnrollmentUserId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetModuleUsersQueryVariables = {
  id?: string,
};

export type GetModuleUsersQuery = {
  getModuleUsers?:  {
    __typename: "ModuleUsers",
    id: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListModuleUserssQueryVariables = {
  filter?: ModelModuleUsersFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListModuleUserssQuery = {
  listModuleUserss?:  {
    __typename: "ModelModuleUsersConnection",
    items:  Array< {
      __typename: "ModuleUsers",
      id: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      module?:  {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDepartmentStaffQueryVariables = {
  id?: string,
};

export type GetDepartmentStaffQuery = {
  getDepartmentStaff?:  {
    __typename: "DepartmentStaff",
    id: string,
    createdAt: string,
    updatedAt: string,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListDepartmentStaffsQueryVariables = {
  filter?: ModelDepartmentStaffFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDepartmentStaffsQuery = {
  listDepartmentStaffs?:  {
    __typename: "ModelDepartmentStaffConnection",
    items:  Array< {
      __typename: "DepartmentStaff",
      id: string,
      createdAt: string,
      updatedAt: string,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventModulesQueryVariables = {
  id?: string,
};

export type GetEventModulesQuery = {
  getEventModules?:  {
    __typename: "EventModules",
    id: string,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListEventModulessQueryVariables = {
  filter?: ModelEventModulesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventModulessQuery = {
  listEventModuless?:  {
    __typename: "ModelEventModulesConnection",
    items:  Array< {
      __typename: "EventModules",
      id: string,
      createdAt: string,
      updatedAt: string,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      module?:  {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSessionModulesQueryVariables = {
  id?: string,
};

export type GetSessionModulesQuery = {
  getSessionModules?:  {
    __typename: "SessionModules",
    id: string,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSessionModulessQueryVariables = {
  filter?: ModelSessionModulesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSessionModulessQuery = {
  listSessionModuless?:  {
    __typename: "ModelSessionModulesConnection",
    items:  Array< {
      __typename: "SessionModules",
      id: string,
      createdAt: string,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      module?:  {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSessionEnrollmentQueryVariables = {
  id?: string,
};

export type GetSessionEnrollmentQuery = {
  getSessionEnrollment?:  {
    __typename: "SessionEnrollment",
    id: string,
    type?: string | null,
    sessionEnrollmentSessionId?: string | null,
    sessionEnrollmentUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListSessionEnrollmentsQueryVariables = {
  filter?: ModelSessionEnrollmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSessionEnrollmentsQuery = {
  listSessionEnrollments?:  {
    __typename: "ModelSessionEnrollmentConnection",
    items:  Array< {
      __typename: "SessionEnrollment",
      id: string,
      type?: string | null,
      sessionEnrollmentSessionId?: string | null,
      sessionEnrollmentUserId?: string | null,
      createdAt: string,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLikeQueryVariables = {
  id?: string,
};

export type GetLikeQuery = {
  getLike?:  {
    __typename: "Like",
    id: string,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    message?:  {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    page?:  {
      __typename: "Page",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorState?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      count?: number | null,
      section?:  {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListLikesQueryVariables = {
  filter?: ModelLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLikesQuery = {
  listLikes?:  {
    __typename: "ModelLikeConnection",
    items:  Array< {
      __typename: "Like",
      id: string,
      createdAt: string,
      updatedAt: string,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      message?:  {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null,
      module?:  {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null,
      page?:  {
        __typename: "Page",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    platformTitle?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    website?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    contact?: string | null,
    logo?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    primaryColor?: string | null,
    secondaryColor?: string | null,
    font?: string | null,
    backgroundImage?: string | null,
    standardCover?: string | null,
    welcomeText?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    features?: Array< string | null > | null,
    bbbServerUrl?: string | null,
    bbbServerSecret?: string | null,
    currentSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    upcomingSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    archivedSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    copyright?: string | null,
    maxEvents?: number | null,
    transactionalEmail?: string | null,
    cssOverride?: string | null,
    showAudienceFiltering?: boolean | null,
    loginRequired?: boolean | null,
    landingPageDisplay?: LandingPageDisplay | null,
    videoUrl?: string | null,
    autoDeleteUsers?: boolean | null,
    dataPrivacy?: string | null,
    imprint?: string | null,
    showSessionGroupFiltering?: boolean | null,
    hideGlobalSearch?: boolean | null,
    hideArchivedSessions?: boolean | null,
    createdAt: string,
    updatedAt: string,
    departments?:  {
      __typename: "ModelDepartmentConnection",
      items:  Array< {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    platformTitle?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    website?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    contact?: string | null,
    logo?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    primaryColor?: string | null,
    secondaryColor?: string | null,
    font?: string | null,
    backgroundImage?: string | null,
    standardCover?: string | null,
    welcomeText?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    features?: Array< string | null > | null,
    bbbServerUrl?: string | null,
    bbbServerSecret?: string | null,
    currentSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    upcomingSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    archivedSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    copyright?: string | null,
    maxEvents?: number | null,
    transactionalEmail?: string | null,
    cssOverride?: string | null,
    showAudienceFiltering?: boolean | null,
    loginRequired?: boolean | null,
    landingPageDisplay?: LandingPageDisplay | null,
    videoUrl?: string | null,
    autoDeleteUsers?: boolean | null,
    dataPrivacy?: string | null,
    imprint?: string | null,
    showSessionGroupFiltering?: boolean | null,
    hideGlobalSearch?: boolean | null,
    hideArchivedSessions?: boolean | null,
    createdAt: string,
    updatedAt: string,
    departments?:  {
      __typename: "ModelDepartmentConnection",
      items:  Array< {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteOrganizationSubscription = {
  onDeleteOrganization?:  {
    __typename: "Organization",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    platformTitle?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    website?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    contact?: string | null,
    logo?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    primaryColor?: string | null,
    secondaryColor?: string | null,
    font?: string | null,
    backgroundImage?: string | null,
    standardCover?: string | null,
    welcomeText?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    features?: Array< string | null > | null,
    bbbServerUrl?: string | null,
    bbbServerSecret?: string | null,
    currentSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    upcomingSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    archivedSessionDesc?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    copyright?: string | null,
    maxEvents?: number | null,
    transactionalEmail?: string | null,
    cssOverride?: string | null,
    showAudienceFiltering?: boolean | null,
    loginRequired?: boolean | null,
    landingPageDisplay?: LandingPageDisplay | null,
    videoUrl?: string | null,
    autoDeleteUsers?: boolean | null,
    dataPrivacy?: string | null,
    imprint?: string | null,
    showSessionGroupFiltering?: boolean | null,
    hideGlobalSearch?: boolean | null,
    hideArchivedSessions?: boolean | null,
    createdAt: string,
    updatedAt: string,
    departments?:  {
      __typename: "ModelDepartmentConnection",
      items:  Array< {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateDepartmentSubscription = {
  onCreateDepartment?:  {
    __typename: "Department",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    primaryColor?: string | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    logo?: string | null,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff?:  {
      __typename: "ModelDepartmentStaffConnection",
      items:  Array< {
        __typename: "DepartmentStaff",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateDepartmentSubscription = {
  onUpdateDepartment?:  {
    __typename: "Department",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    primaryColor?: string | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    logo?: string | null,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff?:  {
      __typename: "ModelDepartmentStaffConnection",
      items:  Array< {
        __typename: "DepartmentStaff",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteDepartmentSubscription = {
  onDeleteDepartment?:  {
    __typename: "Department",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    primaryColor?: string | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    logo?: string | null,
    website?: string | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    programs?:  {
      __typename: "ModelProgramConnection",
      items:  Array< {
        __typename: "Program",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelModuleConnection",
      items:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    staff?:  {
      __typename: "ModelDepartmentStaffConnection",
      items:  Array< {
        __typename: "DepartmentStaff",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateProgramSubscription = {
  onCreateProgram?:  {
    __typename: "Program",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    createdAt: string,
    updatedAt: string,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    sessions?:  {
      __typename: "ModelProgramSessionConnection",
      items:  Array< {
        __typename: "ProgramSession",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateProgramSubscription = {
  onUpdateProgram?:  {
    __typename: "Program",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    createdAt: string,
    updatedAt: string,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    sessions?:  {
      __typename: "ModelProgramSessionConnection",
      items:  Array< {
        __typename: "ProgramSession",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteProgramSubscription = {
  onDeleteProgram?:  {
    __typename: "Program",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    createdAt: string,
    updatedAt: string,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    sessions?:  {
      __typename: "ModelProgramSessionConnection",
      items:  Array< {
        __typename: "ProgramSession",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    id: string,
    state?: EventState | null,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoUrl?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoCover?: string | null,
    image?: string | null,
    imageAlt?: string | null,
    start?: string | null,
    end?: string | null,
    template?: EventTemplate | null,
    count?: number | null,
    isRestricted?: boolean | null,
    group?: string | null,
    audience?: Array< UserType | null > | null,
    showInlineModules?: boolean | null,
    altHeaderStyle?: boolean | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    enrollments?:  {
      __typename: "ModelEventEnrollmentsConnection",
      items:  Array< {
        __typename: "EventEnrollments",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    id: string,
    state?: EventState | null,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoUrl?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoCover?: string | null,
    image?: string | null,
    imageAlt?: string | null,
    start?: string | null,
    end?: string | null,
    template?: EventTemplate | null,
    count?: number | null,
    isRestricted?: boolean | null,
    group?: string | null,
    audience?: Array< UserType | null > | null,
    showInlineModules?: boolean | null,
    altHeaderStyle?: boolean | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    enrollments?:  {
      __typename: "ModelEventEnrollmentsConnection",
      items:  Array< {
        __typename: "EventEnrollments",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    id: string,
    state?: EventState | null,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoUrl?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoCover?: string | null,
    image?: string | null,
    imageAlt?: string | null,
    start?: string | null,
    end?: string | null,
    template?: EventTemplate | null,
    count?: number | null,
    isRestricted?: boolean | null,
    group?: string | null,
    audience?: Array< UserType | null > | null,
    showInlineModules?: boolean | null,
    altHeaderStyle?: boolean | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    enrollments?:  {
      __typename: "ModelEventEnrollmentsConnection",
      items:  Array< {
        __typename: "EventEnrollments",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateEventEnrollmentsSubscriptionVariables = {
  eventEnrollmentsUserId?: string | null,
};

export type OnCreateEventEnrollmentsSubscription = {
  onCreateEventEnrollments?:  {
    __typename: "EventEnrollments",
    id: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateEventEnrollmentsSubscriptionVariables = {
  eventEnrollmentsUserId?: string | null,
};

export type OnUpdateEventEnrollmentsSubscription = {
  onUpdateEventEnrollments?:  {
    __typename: "EventEnrollments",
    id: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteEventEnrollmentsSubscriptionVariables = {
  eventEnrollmentsUserId?: string | null,
};

export type OnDeleteEventEnrollmentsSubscription = {
  onDeleteEventEnrollments?:  {
    __typename: "EventEnrollments",
    id: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateProgramSessionSubscription = {
  onCreateProgramSession?:  {
    __typename: "ProgramSession",
    id: string,
    createdAt: string,
    updatedAt: string,
    program?:  {
      __typename: "Program",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      createdAt: string,
      updatedAt: string,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      sessions?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateProgramSessionSubscription = {
  onUpdateProgramSession?:  {
    __typename: "ProgramSession",
    id: string,
    createdAt: string,
    updatedAt: string,
    program?:  {
      __typename: "Program",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      createdAt: string,
      updatedAt: string,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      sessions?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteProgramSessionSubscription = {
  onDeleteProgramSession?:  {
    __typename: "ProgramSession",
    id: string,
    createdAt: string,
    updatedAt: string,
    program?:  {
      __typename: "Program",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      createdAt: string,
      updatedAt: string,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      sessions?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSessionSubscription = {
  onCreateSession?:  {
    __typename: "Session",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    start?: string | null,
    end?: string | null,
    language?: Language | null,
    type?: SessionType | null,
    sessionState?: SessionState | null,
    stream?: string | null,
    chatEnabled?: boolean | null,
    conference?: string | null,
    module?:  Array< {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    isFree?: boolean | null,
    audience?: Array< UserType | null > | null,
    color?: string | null,
    resources?:  Array< {
      __typename: "Resource",
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      link?: string | null,
    } | null > | null,
    image?: string | null,
    anonymous?: boolean | null,
    isRated?: boolean | null,
    isProtected?: boolean | null,
    password?: string | null,
    automaticActivationEnabled?: boolean | null,
    automaticArchiveEnabled?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    zoom?:  {
      __typename: "Zoom",
      start_url?: string | null,
      join_url?: string | null,
      id?: string | null,
    } | null,
    teams?:  {
      __typename: "Teams",
      joinUrl?: string | null,
      id?: string | null,
    } | null,
    count?: number | null,
    isRestricted?: boolean | null,
    group?: string | null,
    link?: string | null,
    sessionDepartmentId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    program?:  {
      __typename: "ModelProgramSessionConnection",
      items:  Array< {
        __typename: "ProgramSession",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ratings?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    enrollments?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        id: string,
        type?: string | null,
        sessionEnrollmentSessionId?: string | null,
        sessionEnrollmentUserId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateSessionSubscription = {
  onUpdateSession?:  {
    __typename: "Session",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    start?: string | null,
    end?: string | null,
    language?: Language | null,
    type?: SessionType | null,
    sessionState?: SessionState | null,
    stream?: string | null,
    chatEnabled?: boolean | null,
    conference?: string | null,
    module?:  Array< {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    isFree?: boolean | null,
    audience?: Array< UserType | null > | null,
    color?: string | null,
    resources?:  Array< {
      __typename: "Resource",
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      link?: string | null,
    } | null > | null,
    image?: string | null,
    anonymous?: boolean | null,
    isRated?: boolean | null,
    isProtected?: boolean | null,
    password?: string | null,
    automaticActivationEnabled?: boolean | null,
    automaticArchiveEnabled?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    zoom?:  {
      __typename: "Zoom",
      start_url?: string | null,
      join_url?: string | null,
      id?: string | null,
    } | null,
    teams?:  {
      __typename: "Teams",
      joinUrl?: string | null,
      id?: string | null,
    } | null,
    count?: number | null,
    isRestricted?: boolean | null,
    group?: string | null,
    link?: string | null,
    sessionDepartmentId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    program?:  {
      __typename: "ModelProgramSessionConnection",
      items:  Array< {
        __typename: "ProgramSession",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ratings?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    enrollments?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        id: string,
        type?: string | null,
        sessionEnrollmentSessionId?: string | null,
        sessionEnrollmentUserId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteSessionSubscription = {
  onDeleteSession?:  {
    __typename: "Session",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    start?: string | null,
    end?: string | null,
    language?: Language | null,
    type?: SessionType | null,
    sessionState?: SessionState | null,
    stream?: string | null,
    chatEnabled?: boolean | null,
    conference?: string | null,
    module?:  Array< {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    isFree?: boolean | null,
    audience?: Array< UserType | null > | null,
    color?: string | null,
    resources?:  Array< {
      __typename: "Resource",
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      link?: string | null,
    } | null > | null,
    image?: string | null,
    anonymous?: boolean | null,
    isRated?: boolean | null,
    isProtected?: boolean | null,
    password?: string | null,
    automaticActivationEnabled?: boolean | null,
    automaticArchiveEnabled?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    zoom?:  {
      __typename: "Zoom",
      start_url?: string | null,
      join_url?: string | null,
      id?: string | null,
    } | null,
    teams?:  {
      __typename: "Teams",
      joinUrl?: string | null,
      id?: string | null,
    } | null,
    count?: number | null,
    isRestricted?: boolean | null,
    group?: string | null,
    link?: string | null,
    sessionDepartmentId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      platformTitle?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      website?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      contact?: string | null,
      logo?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      secondaryColor?: string | null,
      font?: string | null,
      backgroundImage?: string | null,
      standardCover?: string | null,
      welcomeText?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      features?: Array< string | null > | null,
      bbbServerUrl?: string | null,
      bbbServerSecret?: string | null,
      currentSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      upcomingSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      archivedSessionDesc?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      copyright?: string | null,
      maxEvents?: number | null,
      transactionalEmail?: string | null,
      cssOverride?: string | null,
      showAudienceFiltering?: boolean | null,
      loginRequired?: boolean | null,
      landingPageDisplay?: LandingPageDisplay | null,
      videoUrl?: string | null,
      autoDeleteUsers?: boolean | null,
      dataPrivacy?: string | null,
      imprint?: string | null,
      showSessionGroupFiltering?: boolean | null,
      hideGlobalSearch?: boolean | null,
      hideArchivedSessions?: boolean | null,
      createdAt: string,
      updatedAt: string,
      departments?:  {
        __typename: "ModelDepartmentConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    program?:  {
      __typename: "ModelProgramSessionConnection",
      items:  Array< {
        __typename: "ProgramSession",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ratings?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    modules?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    enrollments?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        id: string,
        type?: string | null,
        sessionEnrollmentSessionId?: string | null,
        sessionEnrollmentUserId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateRatingSubscription = {
  onCreateRating?:  {
    __typename: "Rating",
    id: string,
    stars?: number | null,
    text?: string | null,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateRatingSubscription = {
  onUpdateRating?:  {
    __typename: "Rating",
    id: string,
    stars?: number | null,
    text?: string | null,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteRatingSubscription = {
  onDeleteRating?:  {
    __typename: "Rating",
    id: string,
    stars?: number | null,
    text?: string | null,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateAuthEventSubscriptionVariables = {
  authEventUserId?: string | null,
};

export type OnCreateAuthEventSubscription = {
  onCreateAuthEvent?:  {
    __typename: "AuthEvent",
    id: string,
    type?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateAuthEventSubscriptionVariables = {
  authEventUserId?: string | null,
};

export type OnUpdateAuthEventSubscription = {
  onUpdateAuthEvent?:  {
    __typename: "AuthEvent",
    id: string,
    type?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteAuthEventSubscriptionVariables = {
  authEventUserId?: string | null,
};

export type OnDeleteAuthEventSubscription = {
  onDeleteAuthEvent?:  {
    __typename: "AuthEvent",
    id: string,
    type?: string | null,
    createdAt?: string | null,
    updatedAt: string,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    body?: string | null,
    createdAt?: string | null,
    sessionId?: string | null,
    anonymousUserName?: string | null,
    replies?:  Array< {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    isFlagged?: boolean | null,
    flaggedBy?: string | null,
    isRepliedTo?: boolean | null,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    id: string,
    body?: string | null,
    createdAt?: string | null,
    sessionId?: string | null,
    anonymousUserName?: string | null,
    replies?:  Array< {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    isFlagged?: boolean | null,
    flaggedBy?: string | null,
    isRepliedTo?: boolean | null,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?:  {
    __typename: "Message",
    id: string,
    body?: string | null,
    createdAt?: string | null,
    sessionId?: string | null,
    anonymousUserName?: string | null,
    replies?:  Array< {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null > | null,
    isFlagged?: boolean | null,
    flaggedBy?: string | null,
    isRepliedTo?: boolean | null,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateModuleSubscription = {
  onCreateModule?:  {
    __typename: "Module",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpage?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpageEN?: string | null,
    startpageDE?: string | null,
    color?: string | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    sort?: number | null,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    rating?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateModuleSubscription = {
  onUpdateModule?:  {
    __typename: "Module",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpage?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpageEN?: string | null,
    startpageDE?: string | null,
    color?: string | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    sort?: number | null,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    rating?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteModuleSubscription = {
  onDeleteModule?:  {
    __typename: "Module",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpage?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpageEN?: string | null,
    startpageDE?: string | null,
    color?: string | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    sort?: number | null,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    rating?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sections?:  {
      __typename: "ModelSectionConnection",
      items:  Array< {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateSectionSubscription = {
  onCreateSection?:  {
    __typename: "Section",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpage?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    sort?: number | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    pages?:  {
      __typename: "ModelPageConnection",
      items:  Array< {
        __typename: "Page",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateSectionSubscription = {
  onUpdateSection?:  {
    __typename: "Section",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpage?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    sort?: number | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    pages?:  {
      __typename: "ModelPageConnection",
      items:  Array< {
        __typename: "Page",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteSectionSubscription = {
  onDeleteSection?:  {
    __typename: "Section",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    startpage?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    sort?: number | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    pages?:  {
      __typename: "ModelPageConnection",
      items:  Array< {
        __typename: "Page",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreatePageSubscription = {
  onCreatePage?:  {
    __typename: "Page",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorState?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    sort?: number | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    count?: number | null,
    section?:  {
      __typename: "Section",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      module?:  {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null,
      pages?:  {
        __typename: "ModelPageConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdatePageSubscription = {
  onUpdatePage?:  {
    __typename: "Page",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorState?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    sort?: number | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    count?: number | null,
    section?:  {
      __typename: "Section",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      module?:  {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null,
      pages?:  {
        __typename: "ModelPageConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeletePageSubscription = {
  onDeletePage?:  {
    __typename: "Page",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorState?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
    sort?: number | null,
    isPublished?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    count?: number | null,
    section?:  {
      __typename: "Section",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      module?:  {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null,
      pages?:  {
        __typename: "ModelPageConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    status?: Status | null,
    firstName?: string | null,
    lastName?: string | null,
    image?: string | null,
    userType?: UserType | null,
    position?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    isTemporary?: boolean | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    events?:  {
      __typename: "ModelEventEnrollmentsConnection",
      items:  Array< {
        __typename: "EventEnrollments",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ratings?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    authEvents?:  {
      __typename: "ModelAuthEventConnection",
      items:  Array< {
        __typename: "AuthEvent",
        id: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLoginTime?: string | null,
    email?: string | null,
    ytTokens?:  {
      __typename: "Token",
      accessToken?: string | null,
      refreshToken?: string | null,
      expires_in?: string | null,
    } | null,
    teamsTokens?:  {
      __typename: "Token",
      accessToken?: string | null,
      refreshToken?: string | null,
      expires_in?: string | null,
    } | null,
    redirect?: string | null,
    modules?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    departments?:  {
      __typename: "ModelDepartmentStaffConnection",
      items:  Array< {
        __typename: "DepartmentStaff",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        id: string,
        type?: string | null,
        sessionEnrollmentSessionId?: string | null,
        sessionEnrollmentUserId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    status?: Status | null,
    firstName?: string | null,
    lastName?: string | null,
    image?: string | null,
    userType?: UserType | null,
    position?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    isTemporary?: boolean | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    events?:  {
      __typename: "ModelEventEnrollmentsConnection",
      items:  Array< {
        __typename: "EventEnrollments",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ratings?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    authEvents?:  {
      __typename: "ModelAuthEventConnection",
      items:  Array< {
        __typename: "AuthEvent",
        id: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLoginTime?: string | null,
    email?: string | null,
    ytTokens?:  {
      __typename: "Token",
      accessToken?: string | null,
      refreshToken?: string | null,
      expires_in?: string | null,
    } | null,
    teamsTokens?:  {
      __typename: "Token",
      accessToken?: string | null,
      refreshToken?: string | null,
      expires_in?: string | null,
    } | null,
    redirect?: string | null,
    modules?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    departments?:  {
      __typename: "ModelDepartmentStaffConnection",
      items:  Array< {
        __typename: "DepartmentStaff",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        id: string,
        type?: string | null,
        sessionEnrollmentSessionId?: string | null,
        sessionEnrollmentUserId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    status?: Status | null,
    firstName?: string | null,
    lastName?: string | null,
    image?: string | null,
    userType?: UserType | null,
    position?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    isTemporary?: boolean | null,
    onboarded?: boolean | null,
    createdAt: string,
    updatedAt: string,
    events?:  {
      __typename: "ModelEventEnrollmentsConnection",
      items:  Array< {
        __typename: "EventEnrollments",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ratings?:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        stars?: number | null,
        text?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    authEvents?:  {
      __typename: "ModelAuthEventConnection",
      items:  Array< {
        __typename: "AuthEvent",
        id: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    lastLoginTime?: string | null,
    email?: string | null,
    ytTokens?:  {
      __typename: "Token",
      accessToken?: string | null,
      refreshToken?: string | null,
      expires_in?: string | null,
    } | null,
    teamsTokens?:  {
      __typename: "Token",
      accessToken?: string | null,
      refreshToken?: string | null,
      expires_in?: string | null,
    } | null,
    redirect?: string | null,
    modules?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        id: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    departments?:  {
      __typename: "ModelDepartmentStaffConnection",
      items:  Array< {
        __typename: "DepartmentStaff",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        id: string,
        type?: string | null,
        sessionEnrollmentSessionId?: string | null,
        sessionEnrollmentUserId?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    likes?:  {
      __typename: "ModelLikeConnection",
      items:  Array< {
        __typename: "Like",
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateModuleUsersSubscriptionVariables = {
  moduleUsersUserId?: string | null,
};

export type OnCreateModuleUsersSubscription = {
  onCreateModuleUsers?:  {
    __typename: "ModuleUsers",
    id: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateModuleUsersSubscriptionVariables = {
  moduleUsersUserId?: string | null,
};

export type OnUpdateModuleUsersSubscription = {
  onUpdateModuleUsers?:  {
    __typename: "ModuleUsers",
    id: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteModuleUsersSubscriptionVariables = {
  moduleUsersUserId?: string | null,
};

export type OnDeleteModuleUsersSubscription = {
  onDeleteModuleUsers?:  {
    __typename: "ModuleUsers",
    id: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateDepartmentStaffSubscriptionVariables = {
  user?: string | null,
};

export type OnCreateDepartmentStaffSubscription = {
  onCreateDepartmentStaff?:  {
    __typename: "DepartmentStaff",
    id: string,
    createdAt: string,
    updatedAt: string,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateDepartmentStaffSubscriptionVariables = {
  user?: string | null,
};

export type OnUpdateDepartmentStaffSubscription = {
  onUpdateDepartmentStaff?:  {
    __typename: "DepartmentStaff",
    id: string,
    createdAt: string,
    updatedAt: string,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteDepartmentStaffSubscriptionVariables = {
  user?: string | null,
};

export type OnDeleteDepartmentStaffSubscription = {
  onDeleteDepartmentStaff?:  {
    __typename: "DepartmentStaff",
    id: string,
    createdAt: string,
    updatedAt: string,
    department?:  {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      logo?: string | null,
      website?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelModuleConnection",
        nextToken?: string | null,
      } | null,
      staff?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateEventModulesSubscription = {
  onCreateEventModules?:  {
    __typename: "EventModules",
    id: string,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateEventModulesSubscription = {
  onUpdateEventModules?:  {
    __typename: "EventModules",
    id: string,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteEventModulesSubscription = {
  onDeleteEventModules?:  {
    __typename: "EventModules",
    id: string,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSessionModulesSubscription = {
  onCreateSessionModules?:  {
    __typename: "SessionModules",
    id: string,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSessionModulesSubscription = {
  onUpdateSessionModules?:  {
    __typename: "SessionModules",
    id: string,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSessionModulesSubscription = {
  onDeleteSessionModules?:  {
    __typename: "SessionModules",
    id: string,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateSessionEnrollmentSubscription = {
  onCreateSessionEnrollment?:  {
    __typename: "SessionEnrollment",
    id: string,
    type?: string | null,
    sessionEnrollmentSessionId?: string | null,
    sessionEnrollmentUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSessionEnrollmentSubscription = {
  onUpdateSessionEnrollment?:  {
    __typename: "SessionEnrollment",
    id: string,
    type?: string | null,
    sessionEnrollmentSessionId?: string | null,
    sessionEnrollmentUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteSessionEnrollmentSubscription = {
  onDeleteSessionEnrollment?:  {
    __typename: "SessionEnrollment",
    id: string,
    type?: string | null,
    sessionEnrollmentSessionId?: string | null,
    sessionEnrollmentUserId?: string | null,
    createdAt: string,
    updatedAt: string,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateLikeSubscriptionVariables = {
  likeUserId?: string | null,
};

export type OnCreateLikeSubscription = {
  onCreateLike?:  {
    __typename: "Like",
    id: string,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    message?:  {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    page?:  {
      __typename: "Page",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorState?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      count?: number | null,
      section?:  {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateLikeSubscriptionVariables = {
  likeUserId?: string | null,
};

export type OnUpdateLikeSubscription = {
  onUpdateLike?:  {
    __typename: "Like",
    id: string,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    message?:  {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    page?:  {
      __typename: "Page",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorState?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      count?: number | null,
      section?:  {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteLikeSubscriptionVariables = {
  likeUserId?: string | null,
};

export type OnDeleteLikeSubscription = {
  onDeleteLike?:  {
    __typename: "Like",
    id: string,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      state?: EventState | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoUrl?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      videoCover?: string | null,
      image?: string | null,
      imageAlt?: string | null,
      start?: string | null,
      end?: string | null,
      template?: EventTemplate | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      audience?: Array< UserType | null > | null,
      showInlineModules?: boolean | null,
      altHeaderStyle?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      enrollments?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    session?:  {
      __typename: "Session",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      language?: Language | null,
      type?: SessionType | null,
      sessionState?: SessionState | null,
      stream?: string | null,
      chatEnabled?: boolean | null,
      conference?: string | null,
      module?:  Array< {
        __typename: "Module",
        id: string,
        startpageEN?: string | null,
        startpageDE?: string | null,
        color?: string | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        sort?: number | null,
      } | null > | null,
      isFree?: boolean | null,
      audience?: Array< UserType | null > | null,
      color?: string | null,
      resources?:  Array< {
        __typename: "Resource",
        link?: string | null,
      } | null > | null,
      image?: string | null,
      anonymous?: boolean | null,
      isRated?: boolean | null,
      isProtected?: boolean | null,
      password?: string | null,
      automaticActivationEnabled?: boolean | null,
      automaticArchiveEnabled?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      count?: number | null,
      isRestricted?: boolean | null,
      group?: string | null,
      link?: string | null,
      sessionDepartmentId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        contact?: string | null,
        primaryColor?: string | null,
        secondaryColor?: string | null,
        font?: string | null,
        backgroundImage?: string | null,
        standardCover?: string | null,
        features?: Array< string | null > | null,
        bbbServerUrl?: string | null,
        bbbServerSecret?: string | null,
        copyright?: string | null,
        maxEvents?: number | null,
        transactionalEmail?: string | null,
        cssOverride?: string | null,
        showAudienceFiltering?: boolean | null,
        loginRequired?: boolean | null,
        landingPageDisplay?: LandingPageDisplay | null,
        videoUrl?: string | null,
        autoDeleteUsers?: boolean | null,
        dataPrivacy?: string | null,
        imprint?: string | null,
        showSessionGroupFiltering?: boolean | null,
        hideGlobalSearch?: boolean | null,
        hideArchivedSessions?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        state?: EventState | null,
        videoCover?: string | null,
        image?: string | null,
        imageAlt?: string | null,
        start?: string | null,
        end?: string | null,
        template?: EventTemplate | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        audience?: Array< UserType | null > | null,
        showInlineModules?: boolean | null,
        altHeaderStyle?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      program?:  {
        __typename: "ModelProgramSessionConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      modules?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      enrollments?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    message?:  {
      __typename: "Message",
      id: string,
      body?: string | null,
      createdAt?: string | null,
      sessionId?: string | null,
      anonymousUserName?: string | null,
      replies?:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        sessionId?: string | null,
        anonymousUserName?: string | null,
        isFlagged?: boolean | null,
        flaggedBy?: string | null,
        isRepliedTo?: boolean | null,
        updatedAt: string,
      } | null > | null,
      isFlagged?: boolean | null,
      flaggedBy?: string | null,
      isRepliedTo?: boolean | null,
      updatedAt: string,
      session?:  {
        __typename: "Session",
        id: string,
        start?: string | null,
        end?: string | null,
        language?: Language | null,
        type?: SessionType | null,
        sessionState?: SessionState | null,
        stream?: string | null,
        chatEnabled?: boolean | null,
        conference?: string | null,
        isFree?: boolean | null,
        audience?: Array< UserType | null > | null,
        color?: string | null,
        image?: string | null,
        anonymous?: boolean | null,
        isRated?: boolean | null,
        isProtected?: boolean | null,
        password?: string | null,
        automaticActivationEnabled?: boolean | null,
        automaticArchiveEnabled?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        count?: number | null,
        isRestricted?: boolean | null,
        group?: string | null,
        link?: string | null,
        sessionDepartmentId?: string | null,
      } | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    module?:  {
      __typename: "Module",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpage?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      startpageEN?: string | null,
      startpageDE?: string | null,
      color?: string | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      sort?: number | null,
      department?:  {
        __typename: "Department",
        id: string,
        primaryColor?: string | null,
        editorStateDE?: string | null,
        editorStateEN?: string | null,
        logo?: string | null,
        website?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      rating?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      sections?:  {
        __typename: "ModelSectionConnection",
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventModulesConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionModulesConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    page?:  {
      __typename: "Page",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorState?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      editorStateDE?: string | null,
      editorStateEN?: string | null,
      sort?: number | null,
      isPublished?: boolean | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      author?:  {
        __typename: "User",
        id: string,
        status?: Status | null,
        firstName?: string | null,
        lastName?: string | null,
        image?: string | null,
        userType?: UserType | null,
        isTemporary?: boolean | null,
        onboarded?: boolean | null,
        createdAt: string,
        updatedAt: string,
        lastLoginTime?: string | null,
        email?: string | null,
        redirect?: string | null,
      } | null,
      count?: number | null,
      section?:  {
        __typename: "Section",
        id: string,
        sort?: number | null,
        isPublished?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      status?: Status | null,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      userType?: UserType | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      isTemporary?: boolean | null,
      onboarded?: boolean | null,
      createdAt: string,
      updatedAt: string,
      events?:  {
        __typename: "ModelEventEnrollmentsConnection",
        nextToken?: string | null,
      } | null,
      ratings?:  {
        __typename: "ModelRatingConnection",
        nextToken?: string | null,
      } | null,
      authEvents?:  {
        __typename: "ModelAuthEventConnection",
        nextToken?: string | null,
      } | null,
      messages?:  {
        __typename: "ModelMessageConnection",
        nextToken?: string | null,
      } | null,
      lastLoginTime?: string | null,
      email?: string | null,
      ytTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      teamsTokens?:  {
        __typename: "Token",
        accessToken?: string | null,
        refreshToken?: string | null,
        expires_in?: string | null,
      } | null,
      redirect?: string | null,
      modules?:  {
        __typename: "ModelModuleUsersConnection",
        nextToken?: string | null,
      } | null,
      departments?:  {
        __typename: "ModelDepartmentStaffConnection",
        nextToken?: string | null,
      } | null,
      sessions?:  {
        __typename: "ModelSessionEnrollmentConnection",
        nextToken?: string | null,
      } | null,
      likes?:  {
        __typename: "ModelLikeConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateAuthEventCustomMutationVariables = {
  input?: CreateAuthEventInput,
  condition?: ModelAuthEventConditionInput | null,
};

export type CreateAuthEventCustomMutation = {
  createAuthEvent?:  {
    __typename: "AuthEvent",
    id: string,
  } | null,
};

export type CreateLikeCustomMutationVariables = {
  input?: CreateLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type CreateLikeCustomMutation = {
  createLike?:  {
    __typename: "Like",
    id: string,
  } | null,
};

export type CreateSessionEnrollmentCustomMutationVariables = {
  input?: CreateSessionEnrollmentInput,
  condition?: ModelSessionEnrollmentConditionInput | null,
};

export type CreateSessionEnrollmentCustomMutation = {
  createSessionEnrollment?:  {
    __typename: "SessionEnrollment",
    id: string,
  } | null,
};

export type UpdateUserCustomMutationVariables = {
  input?: UpdateUserInput,
};

export type UpdateUserCustomMutation = {
  updateUser?:  {
    __typename: "User",
    onboarded?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    image?: string | null,
    position?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        type?: string | null,
        session?:  {
          __typename: "Session",
          id: string,
          start?: string | null,
          type?: SessionType | null,
          title?:  {
            __typename: "LanguageInput",
            en?: string | null,
            de?: string | null,
          } | null,
        } | null,
      } | null >,
    } | null,
    modules?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        module?:  {
          __typename: "Module",
          id: string,
          title?:  {
            __typename: "LanguageInput",
            en?: string | null,
            de?: string | null,
          } | null,
        } | null,
      } | null >,
    } | null,
  } | null,
};

export type DeleteLikeCustomMutationVariables = {
  input?: DeleteLikeInput,
  condition?: ModelLikeConditionInput | null,
};

export type DeleteLikeCustomMutation = {
  deleteLike?:  {
    __typename: "Like",
    id: string,
  } | null,
};

export type getSessionCustomQueryVariables = {
  id?: string,
};

export type getSessionCustomQuery = {
  getSession?:  {
    __typename: "Session",
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    id: string,
    type?: SessionType | null,
    resources?:  Array< {
      __typename: "Resource",
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      link?: string | null,
    } | null > | null,
    automaticActivationEnabled?: boolean | null,
    stream?: string | null,
    start?: string | null,
    sessionState?: SessionState | null,
    isRated?: boolean | null,
    enrollments?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        type?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          image?: string | null,
          position?:  {
            __typename: "LanguageInput",
            en?: string | null,
            de?: string | null,
          } | null,
        } | null,
      } | null >,
    } | null,
    chatEnabled?: boolean | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        isRepliedTo?: boolean | null,
        flaggedBy?: string | null,
        anonymousUserName?: string | null,
        author?:  {
          __typename: "User",
          firstName?: string | null,
          lastName?: string | null,
          id: string,
          image?: string | null,
          position?:  {
            __typename: "LanguageInput",
            en?: string | null,
            de?: string | null,
          } | null,
        } | null,
      } | null >,
    } | null,
    modules?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        module?:  {
          __typename: "Module",
          id: string,
          title?:  {
            __typename: "LanguageInput",
            en?: string | null,
            de?: string | null,
          } | null,
          isPublished?: boolean | null,
        } | null,
      } | null >,
    } | null,
  } | null,
};

export type ListDepartmentsCustomQuery = {
  listDepartments?:  {
    __typename: "ModelDepartmentConnection",
    items:  Array< {
      __typename: "Department",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      primaryColor?: string | null,
      programs?:  {
        __typename: "ModelProgramConnection",
        items:  Array< {
          __typename: "Program",
          title?:  {
            __typename: "LanguageInput",
            en?: string | null,
            de?: string | null,
          } | null,
        } | null >,
      } | null,
    } | null >,
  } | null,
};

export type listSessionsWithDepartmentsQuery = {
  listSessions?:  {
    __typename: "ModelSessionConnection",
    items:  Array< {
      __typename: "Session",
      link?: string | null,
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      start?: string | null,
      end?: string | null,
      type?: SessionType | null,
      event?:  {
        __typename: "Event",
        title?:  {
          __typename: "LanguageInput",
          en?: string | null,
          de?: string | null,
        } | null,
        id: string,
      } | null,
      sessionState?: SessionState | null,
      conference?: string | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      department?:  {
        __typename: "Department",
        id: string,
        title?:  {
          __typename: "LanguageInput",
          en?: string | null,
          de?: string | null,
        } | null,
        primaryColor?: string | null,
      } | null,
    } | null >,
  } | null,
};

export type GetUserSessionsCustomQueryVariables = {
  id?: string,
};

export type GetUserSessionsCustomQuery = {
  getUser?:  {
    __typename: "User",
    sessions?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        type?: string | null,
        session?:  {
          __typename: "Session",
          link?: string | null,
          conference?: string | null,
          zoom?:  {
            __typename: "Zoom",
            join_url?: string | null,
            start_url?: string | null,
            id?: string | null,
          } | null,
          teams?:  {
            __typename: "Teams",
            joinUrl?: string | null,
            id?: string | null,
          } | null,
          id: string,
          sessionState?: SessionState | null,
          type?: SessionType | null,
          title?:  {
            __typename: "LanguageInput",
            en?: string | null,
            de?: string | null,
          } | null,
          department?:  {
            __typename: "Department",
            id: string,
            primaryColor?: string | null,
            title?:  {
              __typename: "LanguageInput",
              en?: string | null,
              de?: string | null,
            } | null,
          } | null,
        } | null,
      } | null >,
    } | null,
  } | null,
};

export type GetEventCustomQueryVariables = {
  id?: string,
};

export type GetEventCustomQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    showInlineModules?: boolean | null,
    altHeaderStyle?: boolean | null,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoUrl?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    videoCover?: string | null,
    image?: string | null,
    start?: string | null,
    end?: string | null,
    template?: EventTemplate | null,
    audience?: Array< UserType | null > | null,
    isRestricted?: boolean | null,
    group?: string | null,
    modules?:  {
      __typename: "ModelEventModulesConnection",
      items:  Array< {
        __typename: "EventModules",
        module?:  {
          __typename: "Module",
          sort?: number | null,
          id: string,
          title?:  {
            __typename: "LanguageInput",
            en?: string | null,
            de?: string | null,
          } | null,
          isPublished?: boolean | null,
          department?:  {
            __typename: "Department",
            id: string,
            title?:  {
              __typename: "LanguageInput",
              en?: string | null,
              de?: string | null,
            } | null,
            primaryColor?: string | null,
          } | null,
        } | null,
      } | null >,
    } | null,
    sessions?:  {
      __typename: "ModelSessionConnection",
      items:  Array< {
        __typename: "Session",
        link?: string | null,
        id: string,
        count?: number | null,
        isRated?: boolean | null,
        title?:  {
          __typename: "LanguageInput",
          en?: string | null,
          de?: string | null,
        } | null,
        conference?: string | null,
        zoom?:  {
          __typename: "Zoom",
          start_url?: string | null,
          join_url?: string | null,
          id?: string | null,
        } | null,
        teams?:  {
          __typename: "Teams",
          joinUrl?: string | null,
          id?: string | null,
        } | null,
        description?:  {
          __typename: "LanguageInput",
          en?: string | null,
          de?: string | null,
        } | null,
        sessionState?: SessionState | null,
        start?: string | null,
        end?: string | null,
        type?: SessionType | null,
        anonymous?: boolean | null,
        sessionDepartmentId?: string | null,
      } | null >,
    } | null,
  } | null,
};

export type listAllSessionsCustomQueryVariables = {
  filter?: ModelSessionFilterInput | null,
};

export type listAllSessionsCustomQuery = {
  listSessions?:  {
    __typename: "ModelSessionConnection",
    items:  Array< {
      __typename: "Session",
      id: string,
      count?: number | null,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      type?: SessionType | null,
      event?:  {
        __typename: "Event",
        title?:  {
          __typename: "LanguageInput",
          en?: string | null,
          de?: string | null,
        } | null,
      } | null,
      link?: string | null,
      sessionState?: SessionState | null,
      conference?: string | null,
      zoom?:  {
        __typename: "Zoom",
        start_url?: string | null,
        join_url?: string | null,
        id?: string | null,
      } | null,
      teams?:  {
        __typename: "Teams",
        joinUrl?: string | null,
        id?: string | null,
      } | null,
      anonymous?: boolean | null,
      start?: string | null,
      end?: string | null,
      sessionDepartmentId?: string | null,
    } | null >,
  } | null,
};

export type ListEventsCustomQueryVariables = {
  filter?: ModelEventFilterInput | null,
};

export type ListEventsCustomQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      title?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      image?: string | null,
      start?: string | null,
      end?: string | null,
      state?: EventState | null,
      group?: string | null,
      isRestricted?: boolean | null,
      audience?: Array< UserType | null > | null,
    } | null >,
  } | null,
};

export type GetProgramCustomQueryVariables = {
  id?: string,
};

export type GetProgramCustomQuery = {
  getProgram?:  {
    __typename: "Program",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
  } | null,
};

export type GetDepartmentCustomQueryVariables = {
  id?: string,
};

export type GetDepartmentCustomQuery = {
  getDepartment?:  {
    __typename: "Department",
    id: string,
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    editorStateDE?: string | null,
    editorStateEN?: string | null,
  } | null,
};

export type GetDashboardUserCustomQueryVariables = {
  id?: string,
};

export type GetDashboardUserCustomQuery = {
  getUser?:  {
    __typename: "User",
    onboarded?: boolean | null,
    firstName?: string | null,
    lastName?: string | null,
    image?: string | null,
    position?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    sessions?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        type?: string | null,
        session?:  {
          __typename: "Session",
          link?: string | null,
          conference?: string | null,
          teams?:  {
            __typename: "Teams",
            joinUrl?: string | null,
            id?: string | null,
          } | null,
          zoom?:  {
            __typename: "Zoom",
            join_url?: string | null,
            start_url?: string | null,
            id?: string | null,
          } | null,
          id: string,
          sessionState?: SessionState | null,
          start?: string | null,
          end?: string | null,
          type?: SessionType | null,
          title?:  {
            __typename: "LanguageInput",
            en?: string | null,
            de?: string | null,
          } | null,
        } | null,
      } | null >,
    } | null,
    modules?:  {
      __typename: "ModelModuleUsersConnection",
      items:  Array< {
        __typename: "ModuleUsers",
        type?: string | null,
        module?:  {
          __typename: "Module",
          id: string,
          title?:  {
            __typename: "LanguageInput",
            en?: string | null,
            de?: string | null,
          } | null,
        } | null,
      } | null >,
    } | null,
  } | null,
};

export type onCreateMessageCustomSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    createdAt?: string | null,
    isRepliedTo?: boolean | null,
    flaggedBy?: string | null,
    body?: string | null,
    session?:  {
      __typename: "Session",
      id: string,
    } | null,
    anonymousUserName?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
    } | null,
  } | null,
};

export type onUpdateMessageCustomSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    id: string,
    createdAt?: string | null,
    isRepliedTo?: boolean | null,
    flaggedBy?: string | null,
    body?: string | null,
    session?:  {
      __typename: "Session",
      id: string,
    } | null,
    anonymousUserName?: string | null,
    author?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      image?: string | null,
      position?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateSessionCustomSubscription = {
  onUpdateSession?:  {
    __typename: "Session",
    title?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    description?:  {
      __typename: "LanguageInput",
      en?: string | null,
      de?: string | null,
    } | null,
    id: string,
    type?: SessionType | null,
    isRated?: boolean | null,
    resources?:  Array< {
      __typename: "Resource",
      description?:  {
        __typename: "LanguageInput",
        en?: string | null,
        de?: string | null,
      } | null,
      link?: string | null,
    } | null > | null,
    stream?: string | null,
    sessionState?: SessionState | null,
    enrollments?:  {
      __typename: "ModelSessionEnrollmentConnection",
      items:  Array< {
        __typename: "SessionEnrollment",
        type?: string | null,
        user?:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          image?: string | null,
          position?:  {
            __typename: "LanguageInput",
            en?: string | null,
            de?: string | null,
          } | null,
        } | null,
      } | null >,
    } | null,
    chatEnabled?: boolean | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        body?: string | null,
        createdAt?: string | null,
        isRepliedTo?: boolean | null,
        author?:  {
          __typename: "User",
          firstName?: string | null,
          lastName?: string | null,
          id: string,
          image?: string | null,
          position?:  {
            __typename: "LanguageInput",
            en?: string | null,
            de?: string | null,
          } | null,
        } | null,
      } | null >,
    } | null,
    modules?:  {
      __typename: "ModelSessionModulesConnection",
      items:  Array< {
        __typename: "SessionModules",
        module?:  {
          __typename: "Module",
          id: string,
          title?:  {
            __typename: "LanguageInput",
            en?: string | null,
            de?: string | null,
          } | null,
          isPublished?: boolean | null,
        } | null,
      } | null >,
    } | null,
  } | null,
};

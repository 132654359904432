import React, { useContext } from "react";
import styled from "styled-components";
import { Button, Typography } from "antd";
import { StateContext } from "../../state";
import { useHistory } from "react-router-dom";

const data = {
  codeSent: {
    en: "You are registered. Please Log in.",
    de: "Sie sind registriert. Bitte loggen Sie sich ein.",
  },
  confirmSignupTitle: {
    en: "You are registered!",
    de: "Sie sind registriert!",
  },
  signUp: {
    en: "Login",
    de: "Login",
  },
  requiredMessage: {
    en: "Required field",
    de: "Pflichtfeld",
  },
};

const { Title, Paragraph } = Typography;

export default function ConfirmSignUpForm(props) {
  const { language } = useContext(StateContext);
  const history = useHistory();

  const { codeSent, confirmSignupTitle, signUp } = data;

  // const confirmRegistration = async (values) => {
  //   try {
  //     await Auth.confirmSignUp(props.authData, values.code);
  //     notification.success({
  //       message: I18n.get("general success"),
  //       description: I18n.get("auth register success"),
  //     });
  //     props.onStateChange("signIn");
  //   } catch (err) {
  //     console.log(err);
  //     notification.error({
  //       message: I18n.get("general error"),
  //       description: err.message,
  //     });
  //   }
  // };

  // const resendConfirmationCode = async () => {
  //   try {
  //     await Auth.resendSignUp(props.authData);
  //     notification.success({
  //       message: I18n.get("general success"),
  //       description: I18n.get("auth resend success"),
  //     });
  //   } catch (err) {
  //     notification.error({
  //       message: I18n.get("general error"),
  //       description: err.message,
  //     });
  //   }
  // };

  return (
    <>
      {props.authState === "confirmSignUp" && (
        <Wrapper>
          <Title level={3}>{confirmSignupTitle[language]}</Title>
          <Paragraph>{codeSent[language]} </Paragraph>
          {/* <Form
            name="confirmSignup"
            layout="vertical"
            onFinish={confirmRegistration}
          >
            <Form.Item
              label={"Code"}
              name="code"
              rules={[{ required: true, message: requiredMessage[language] }]}
            >
              <Input type="number" />
            </Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginBottom: "1rem" }}
            >
              {signUp[language]}
            </Button>
            <br />
            <a onClick={resendConfirmationCode}>
              {I18n.get("auth resend code")}
            </a>
          </Form> */}
          <Button
            onClick={() => history.push("/signin")}
            type="primary"
            style={{ marginBottom: "1rem" }}
          >
            {signUp[language]}
          </Button>
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 1rem;
  // @media (max-width: 900px) {
  //   width: 150%;
  // }
`;

import React from "react";
import { Spin } from "antd";

export default function Loader() {
  return (
    <div style={{ display: "flex", height: "50vh" }}>
      <Spin style={{ margin: "auto" }} size="large" />
    </div>
  );
}

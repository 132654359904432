/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      platformTitle {
        en
        de
      }
      editorStateDE
      editorStateEN
      website {
        en
        de
      }
      contact
      logo {
        en
        de
      }
      primaryColor
      secondaryColor
      font
      backgroundImage
      standardCover
      welcomeText {
        en
        de
      }
      features
      bbbServerUrl
      bbbServerSecret
      currentSessionDesc {
        en
        de
      }
      upcomingSessionDesc {
        en
        de
      }
      archivedSessionDesc {
        en
        de
      }
      copyright
      maxEvents
      transactionalEmail
      cssOverride
      showAudienceFiltering
      loginRequired
      landingPageDisplay
      videoUrl
      autoDeleteUsers
      dataPrivacy
      imprint
      showSessionGroupFiltering
      hideGlobalSearch
      hideArchivedSessions
      createdAt
      updatedAt
      departments {
        items {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        cssOverride
        showAudienceFiltering
        loginRequired
        landingPageDisplay
        videoUrl
        autoDeleteUsers
        dataPrivacy
        imprint
        showSessionGroupFiltering
        hideGlobalSearch
        hideArchivedSessions
        createdAt
        updatedAt
        departments {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      primaryColor
      editorStateDE
      editorStateEN
      logo
      website
      createdAt
      updatedAt
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        cssOverride
        showAudienceFiltering
        loginRequired
        landingPageDisplay
        videoUrl
        autoDeleteUsers
        dataPrivacy
        imprint
        showSessionGroupFiltering
        hideGlobalSearch
        hideArchivedSessions
        createdAt
        updatedAt
        departments {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
      }
      programs {
        items {
          id
          editorStateDE
          editorStateEN
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
      modules {
        items {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        nextToken
      }
      staff {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getProgram = /* GraphQL */ `
  query GetProgram($id: ID!) {
    getProgram(id: $id) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      editorStateDE
      editorStateEN
      createdAt
      updatedAt
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      sessions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPrograms = /* GraphQL */ `
  query ListPrograms(
    $filter: ModelProgramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrograms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        editorStateDE
        editorStateEN
        createdAt
        updatedAt
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      state
      title {
        en
        de
      }
      description {
        en
        de
      }
      videoUrl {
        en
        de
      }
      videoCover
      image
      imageAlt
      start
      end
      template
      count
      isRestricted
      group
      audience
      showInlineModules
      altHeaderStyle
      createdAt
      updatedAt
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        cssOverride
        showAudienceFiltering
        loginRequired
        landingPageDisplay
        videoUrl
        autoDeleteUsers
        dataPrivacy
        imprint
        showSessionGroupFiltering
        hideGlobalSearch
        hideArchivedSessions
        createdAt
        updatedAt
        departments {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
      }
      enrollments {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
      modules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEventEnrollments = /* GraphQL */ `
  query GetEventEnrollments($id: ID!) {
    getEventEnrollments(id: $id) {
      id
      type
      createdAt
      updatedAt
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const listEventEnrollmentss = /* GraphQL */ `
  query ListEventEnrollmentss(
    $filter: ModelEventEnrollmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventEnrollmentss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        updatedAt
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        user {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
      }
      nextToken
    }
  }
`;
export const getProgramSession = /* GraphQL */ `
  query GetProgramSession($id: ID!) {
    getProgramSession(id: $id) {
      id
      createdAt
      updatedAt
      program {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        editorStateDE
        editorStateEN
        createdAt
        updatedAt
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
      }
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const listProgramSessions = /* GraphQL */ `
  query ListProgramSessions(
    $filter: ModelProgramSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgramSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        program {
          id
          editorStateDE
          editorStateEN
          createdAt
          updatedAt
        }
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
      }
      nextToken
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      start
      end
      language
      type
      sessionState
      stream
      chatEnabled
      conference
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      isFree
      audience
      color
      resources {
        description {
          en
          de
        }
        link
      }
      image
      anonymous
      isRated
      isProtected
      password
      automaticActivationEnabled
      automaticArchiveEnabled
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      zoom {
        start_url
        join_url
        id
      }
      teams {
        joinUrl
        id
      }
      count
      isRestricted
      group
      link
      sessionDepartmentId
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        cssOverride
        showAudienceFiltering
        loginRequired
        landingPageDisplay
        videoUrl
        autoDeleteUsers
        dataPrivacy
        imprint
        showSessionGroupFiltering
        hideGlobalSearch
        hideArchivedSessions
        createdAt
        updatedAt
        departments {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
      }
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      program {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      ratings {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        nextToken
      }
      modules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      enrollments {
        items {
          id
          type
          sessionEnrollmentSessionId
          sessionEnrollmentUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const sessionsByStateAndAndStartTime = /* GraphQL */ `
  query SessionsByStateAndAndStartTime(
    $sessionState: SessionState
    $start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionsByStateAndAndStartTime(
      sessionState: $sessionState
      start: $start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const sessionsByStateAndAndEndTime = /* GraphQL */ `
  query SessionsByStateAndAndEndTime(
    $sessionState: SessionState
    $end: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionsByStateAndAndEndTime(
      sessionState: $sessionState
      end: $end
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRating = /* GraphQL */ `
  query GetRating($id: ID!) {
    getRating(id: $id) {
      id
      stars
      text
      createdAt
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const listRatings = /* GraphQL */ `
  query ListRatings(
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stars
        text
        createdAt
        updatedAt
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        user {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
      }
      nextToken
    }
  }
`;
export const getAuthEvent = /* GraphQL */ `
  query GetAuthEvent($id: ID!) {
    getAuthEvent(id: $id) {
      id
      type
      createdAt
      updatedAt
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const listAuthEvents = /* GraphQL */ `
  query ListAuthEvents(
    $filter: ModelAuthEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        updatedAt
        user {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
      }
      nextToken
    }
  }
`;
export const authEventsByTypeAndDate = /* GraphQL */ `
  query AuthEventsByTypeAndDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuthEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    authEventsByTypeAndDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        updatedAt
        user {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      body
      createdAt
      sessionId
      anonymousUserName
      replies {
        id
        body
        createdAt
        sessionId
        anonymousUserName
        replies {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        isFlagged
        flaggedBy
        isRepliedTo
        updatedAt
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        likes {
          nextToken
        }
      }
      isFlagged
      flaggedBy
      isRepliedTo
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        body
        createdAt
        sessionId
        anonymousUserName
        replies {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        isFlagged
        flaggedBy
        isRepliedTo
        updatedAt
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        likes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getModule = /* GraphQL */ `
  query GetModule($id: ID!) {
    getModule(id: $id) {
      id
      title {
        en
        de
      }
      startpage {
        en
        de
      }
      startpageEN
      startpageDE
      color
      isPublished
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      sort
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      rating {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      sections {
        items {
          id
          sort
          isPublished
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listModules = /* GraphQL */ `
  query ListModules(
    $filter: ModelModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSection = /* GraphQL */ `
  query GetSection($id: ID!) {
    getSection(id: $id) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      startpage {
        en
        de
      }
      sort
      isPublished
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      pages {
        items {
          id
          editorStateDE
          editorStateEN
          sort
          isPublished
          createdAt
          updatedAt
          count
        }
        nextToken
      }
    }
  }
`;
export const listSections = /* GraphQL */ `
  query ListSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        startpage {
          en
          de
        }
        sort
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        pages {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      title {
        en
        de
      }
      editorState {
        en
        de
      }
      editorStateDE
      editorStateEN
      sort
      isPublished
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      count
      section {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        startpage {
          en
          de
        }
        sort
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        pages {
          nextToken
        }
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title {
          en
          de
        }
        editorState {
          en
          de
        }
        editorStateDE
        editorStateEN
        sort
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        count
        section {
          id
          sort
          isPublished
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const usersByStatusAndLastLoginTime = /* GraphQL */ `
  query UsersByStatusAndLastLoginTime(
    $status: Status
    $lastLoginTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByStatusAndLastLoginTime(
      status: $status
      lastLoginTime: $lastLoginTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      status
      firstName
      lastName
      image
      userType
      position {
        en
        de
      }
      isTemporary
      onboarded
      createdAt
      updatedAt
      events {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      ratings {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      authEvents {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        nextToken
      }
      lastLoginTime
      email
      ytTokens {
        accessToken
        refreshToken
        expires_in
      }
      teamsTokens {
        accessToken
        refreshToken
        expires_in
      }
      redirect
      modules {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      departments {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          type
          sessionEnrollmentSessionId
          sessionEnrollmentUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getModuleUsers = /* GraphQL */ `
  query GetModuleUsers($id: ID!) {
    getModuleUsers(id: $id) {
      id
      type
      createdAt
      updatedAt
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const listModuleUserss = /* GraphQL */ `
  query ListModuleUserss(
    $filter: ModelModuleUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModuleUserss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        updatedAt
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        user {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
      }
      nextToken
    }
  }
`;
export const getDepartmentStaff = /* GraphQL */ `
  query GetDepartmentStaff($id: ID!) {
    getDepartmentStaff(id: $id) {
      id
      createdAt
      updatedAt
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const listDepartmentStaffs = /* GraphQL */ `
  query ListDepartmentStaffs(
    $filter: ModelDepartmentStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartmentStaffs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        user {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
      }
      nextToken
    }
  }
`;
export const getEventModules = /* GraphQL */ `
  query GetEventModules($id: ID!) {
    getEventModules(id: $id) {
      id
      createdAt
      updatedAt
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const listEventModuless = /* GraphQL */ `
  query ListEventModuless(
    $filter: ModelEventModulesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventModuless(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
      }
      nextToken
    }
  }
`;
export const getSessionModules = /* GraphQL */ `
  query GetSessionModules($id: ID!) {
    getSessionModules(id: $id) {
      id
      createdAt
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const listSessionModuless = /* GraphQL */ `
  query ListSessionModuless(
    $filter: ModelSessionModulesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionModuless(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
      }
      nextToken
    }
  }
`;
export const getSessionEnrollment = /* GraphQL */ `
  query GetSessionEnrollment($id: ID!) {
    getSessionEnrollment(id: $id) {
      id
      type
      sessionEnrollmentSessionId
      sessionEnrollmentUserId
      createdAt
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const listSessionEnrollments = /* GraphQL */ `
  query ListSessionEnrollments(
    $filter: ModelSessionEnrollmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionEnrollments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        sessionEnrollmentSessionId
        sessionEnrollmentUserId
        createdAt
        updatedAt
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        user {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
      }
      nextToken
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($id: ID!) {
    getLike(id: $id) {
      id
      createdAt
      updatedAt
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      message {
        id
        body
        createdAt
        sessionId
        anonymousUserName
        replies {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        isFlagged
        flaggedBy
        isRepliedTo
        updatedAt
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      page {
        id
        title {
          en
          de
        }
        editorState {
          en
          de
        }
        editorStateDE
        editorStateEN
        sort
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        count
        section {
          id
          sort
          isPublished
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        message {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        page {
          id
          editorStateDE
          editorStateEN
          sort
          isPublished
          createdAt
          updatedAt
          count
        }
        user {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
      }
      nextToken
    }
  }
`;

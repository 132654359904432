import React, { useContext } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { StateContext } from "../state";

const data = {
  message: {
    en: "We're using Cookies to improve your experience.",
    de: "Wir verwenden Cookies, um unsere Inhalte für Sie zu optimieren.",
  },
  refuse: { en: "Refuse", de: "Ablehnen" },
};

export default function CookiePopup(props) {
  const { cookies } = props;
  const { language } = useContext(StateContext);

  return (
    <CookiePopupWrapper>
      <CookiePopupText>
        <p>
          <span role="img" aria-label="Cookie">
            🍪
          </span>{" "}
          {data.message[language]}
        </p>
      </CookiePopupText>
      <CookiePopupButtons>
        <Button
          style={{ marginLeft: "1rem" }}
          onClick={() => {
            cookies.set("consentCookie", "noConsent", { path: "/" });
            props.setConsentCookie(true);
          }}
        >
          {data.refuse[language]}
        </Button>
        <Button
          type="primary"
          style={{ marginLeft: "1rem" }}
          onClick={() => {
            cookies.set("consentCookie", "consentGiven", { path: "/" });
            props.setConsentCookie(true);
          }}
        >
          Okay
        </Button>
      </CookiePopupButtons>
    </CookiePopupWrapper>
  );
}

const CookiePopupWrapper = styled.div`
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8%;
  z-index: 20;
  background-image: linear-gradient(
    to left,
    rgba(250, 250, 250, 1),
    rgba(250, 250, 250, 1)
  );
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 900px) {
    height: 15%;
    flex-direction: column;
    padding: 2rem;
  }

  @media (max-width: 600px) {
    height: 20%;
    padding: 1rem;
    text-align: center;
  }
`;

const CookiePopupText = styled.div``;

const CookiePopupButtons = styled.div``;

import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import GroupSelfRegistrationWrapper from "./frontend/auth/GroupSelfRegistrationWrapper";

const Backend = lazy(() => import("./backend/Backend"));
const SessionsChat = lazy(() => import("./backend/sessions/Sessions_Chat"));
const Unauthorized = lazy(() => import("./Unauthorized"));
const NotFound = lazy(() => import("./NotFound"));
const Frontend = lazy(() => import("./frontend/Frontend"));
const Imprint = lazy(() => import("./frontend/legal/Imprint"));
const DataPrivacy = lazy(() => import("./frontend/legal/DataPrivacy"));
const EventSingleFetch = lazy(() =>
  import("./frontend/events/Event_Single_Fetch")
);
const ModuleSingleFetch = lazy(() =>
  import("./frontend/modules/Module_Single_Fetch")
);
const DepartmentsSingleFetch = lazy(() =>
  import("./frontend/departments/Departments_Single_Fetch")
);
const ProgramsSingleFetch = lazy(() =>
  import("./frontend/programs/Programs_Single_Fetch")
);
const OrganizationsSingleFetch = lazy(() =>
  import("./frontend/organization/Organizations_Single_Fetch")
);
const Profile_Fetch = lazy(() => import("./frontend/profile/Profile_Fetch"));

const Token = lazy(() => import("./frontend/auth/Token"));
const TeamsTokens = lazy(() => import("./frontend/auth/TeamsTokens"));

const FullScreenSignIn = lazy(() => import("./frontend/auth/FullScreenSignIn"));

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Frontend} />
      <Route path="/sessions/:id" exact render={() => <SessionsChat />} />
      <Route path={`/events/:id`} exact component={EventSingleFetch} />
      <Route path={`/modules/:id`} exact component={ModuleSingleFetch} />
      <Route
        path={`/departments/:id`}
        exact
        component={DepartmentsSingleFetch}
      />
      <Route path={`/programs/:id`} exact component={ProgramsSingleFetch} />
      <Route
        path={`/organization`}
        exact
        component={OrganizationsSingleFetch}
      />
      <Route path="/profile" component={Profile_Fetch} />
      <Route path="/backend" component={Backend} />
      <Route path={`/imprint`} exact component={Imprint} />
      <Route path={`/privacy`} exact component={DataPrivacy} />
      <Route path={`/token`} exact component={Token} />
      <Route path={`/teamsTokens`} exact component={TeamsTokens} />
      <Route path={`/signin`} exact component={FullScreenSignIn} />
      <Route
        path={`/groupSelfRegistration/:groupname+`}
        exact
        component={GroupSelfRegistrationWrapper}
      />

      <Route path="/unauthorized" component={Unauthorized} />
      <Route component={NotFound} />
    </Switch>
  );
}

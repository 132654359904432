import { message } from "antd";
import { API, Auth, I18n } from "aws-amplify";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { StateContext } from "../../state";

function GroupSelfRegistration(props) {
  let { groupname } = useParams();
  let { userId } = useContext(StateContext);

  useEffect(() => {
    const addUserToGroup = async () => {
      if (
        groupname === "admins" ||
        groupname === "staff" ||
        groupname === "departmentEditors"
      ) {
        message.error("Not authorized to add to this group");
        return;
      }
      let apiName = "AdminQueries";
      let path = "/groupSelfRegistration";
      let myInit = {
        body: {
          username: userId,
          groupname,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
      };
      try {
        await API.post(apiName, path, myInit);
        await message.success(
          `You have been added to the group ${groupname}. Please wait for this process to finish and log in again.`
        );
        await Auth.signOut();
      } catch (e) {
        message.error(I18n.get("general error message"));
        console.log(e);
      }
    };

    if (props.authState === "signedIn") {
      addUserToGroup();
    }
  }, [groupname, props.authState, userId]);

  return <div></div>;
}

export default GroupSelfRegistration;

const translations = {
  de: {
    "dashboard good day": "Schön, dass du wieder hier bist.",
    "dashboard onboarding again": "Onboarding anzeigen",
    "dashboard upcoming sessions": "Deine Sessions",
    "dashboard module enrollments": "Deine Module",
    "dashboard conference error":
      "Beim Öffnen der Konferenz ist ein Fehler aufgetreten.",
  },
  en: {
    "dashboard good day": "Nice to see you again.",
    "dashboard onboarding again": "Show Onboarding again",
    "dashboard upcoming sessions": "Your Sessions",
    "dashboard module enrollments": "Your Modules",
    "dashboard conference error": "There was an error opening your conference.",
  },
};

export default translations;

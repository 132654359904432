import React, { useContext } from "react";
import styled from "styled-components";
import {
  Authenticator,
  SignIn,
  SignUp,
  ForgotPassword,
  ConfirmSignUp,
  RequireNewPassword,
  VerifyContact,
} from "aws-amplify-react";
import SignUpForm from "../auth/SignUpForm";
import SignInForm from "../auth/SignInForm";
import ForgotPasswordForm from "../auth/ForgotPasswordForm";
import ConfirmSignUpForm from "../auth/ConfirmSignUpForm";
import NavigationFrontend from "../Navigation_Frontend";
import { StateContext } from "../../state";

export default function CustomAuth(props) {
  const { globalOrganization, isAuthenticated } = useContext(StateContext);

  return (
    <>
      {!isAuthenticated ? (
        <Wrapper
          primaryColor={globalOrganization.primaryColor}
          secondaryColor={globalOrganization.secondaryColor}
          bg={globalOrganization.backgroundImage}
        >
          <NavigationFrontend />

          <InnerWrapper>
            <Authenticator
              authState="signIn"
              style={{
                boxShadow: "1rem 1rem 1rem rgba(0,0,0,0.8)",
              }}
              usernameAttributes="email"
              hideDefault
            >
              <SignInForm override={<SignIn />} />
              <SignUpForm override={<SignUp />} />
              <RequireNewPassword />
              <VerifyContact />
              <ForgotPasswordForm override={<ForgotPassword />} />
              <ConfirmSignUpForm override={<ConfirmSignUp />} />
            </Authenticator>
          </InnerWrapper>
        </Wrapper>
      ) : (
        props.children
      )}
    </>
  );
}

const Wrapper = styled.div`
  z-index: 100;
  background-position: center;
  background-size: cover;
  background-image: ${(props) => `linear-gradient(
      to right bottom,
      ${props.primaryColor},
      ${props.secondaryColor}
    ),
    url("${props.bg}")`};
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  background-repeat: no-repeat;

  position: relative;
`;

const InnerWrapper = styled.div`
  width: 50%;
  margin: auto;
  @media (max-width: 900px) {
    width: 95%;
  }
`;

import React, { useContext, useState } from "react";
import { Auth, I18n } from "aws-amplify";
import styled from "styled-components";
import { Form, Typography, Input, Button, notification } from "antd";
import { StateContext } from "../../state";
import { DynamicField } from "./DynamicField";
const { Title, Paragraph } = Typography;

const data = {
  login: {
    en: "Already have an account?",
    de: "Schon registriert?",
  },
  formTitle: {
    en: "Sign Up",
    de: "Registrierung",
  },
  firstName: {
    en: "First Name",
    de: "Vorname",
  },
  lastName: {
    en: "Last Name",
    de: "Nachname",
  },
  password: {
    en: "Password",
    de: "Passwort",
  },
  role: {
    en: "Role",
    de: "Rolle",
  },
  signUp: {
    en: "Sign Up For Free",
    de: "Kostenlos registrieren",
  },
  requiredMessage: {
    en: "Required field",
    de: "Pflichtfeld",
  },
  roleTypes: [
    {
      en: "Prospective Student",
      de: "Studieninteressierte/r",
      value: "PROSPECT",
    },
    {
      en: "Student",
      de: "Studierende/r",
      value: "CURRENT",
    },
    {
      en: "Staff",
      de: "Mitarbeitende/r",
      value: "STAFF",
    },
    {
      en: "Other",
      de: "Sonstige/r",
      value: "EXTERNAL",
    },
  ],
  codeSent: {
    en: "We have sent you a confirmation code. Please check your emails.",
    de: "Wir haben Ihnen einen Code zugeschickt. Bitte checken Sie Ihre Emails.",
  },
  confirmSignupTitle: {
    en: "Confirm your email address",
    de: "Bestätigung Ihrer E-Mail-Adresse",
  },
};

export default function DynamicSignupForm(props) {
  const [user, setUser] = useState(false);

  const { language } = useContext(StateContext);
  const {
    firstName,
    lastName,
    password,
    role,
    formTitle,
    signUp,
    requiredMessage,
    roleTypes,
    confirmSignupTitle,
    codeSent,
  } = data;

  const customFields = JSON.parse(process.env.REACT_APP_SIGN_UP_PARAMS);

  if (!customFields) {
    return null;
  }

  const onFinish = async (values) => {
    const attributes = {
      given_name: values.firstName,
      family_name: values.lastName,
    };

    customFields.forEach((customField) => {
      attributes[customField.name] = `${values[customField.name] || "NONE"}`;
    });

    let input = {
      username: values.email,
      email: values.email,
      password: values.password,
      attributes: attributes,
    };

    try {
      await Auth.signUp(input);

      props.onStateChange("confirmSignUp", values.email);
    } catch (e) {
      notification.error({ message: "Error", description: e.message });
    }
  };

  const confirmRegistration = async (values) => {
    try {
      await Auth.confirmSignUp(user, values.code);
      setUser(false);
      notification.success({
        message: I18n.get("general success"),
        description: I18n.get("auth register success"),
      });
      props.onStateChange("signIn");
    } catch (err) {
      console.log(err);
      notification.error({
        message: I18n.get("general error"),
        description: err.message,
      });
    }
  };

  return (
    <>
      {props.authState === "signUp" ? (
        <Wrapper>
          {user ? (
            <>
              <Title level={3}>{confirmSignupTitle[language]}</Title>
              <Paragraph>{codeSent[language]} </Paragraph>
              <Form
                name="confirmSignup"
                layout="vertical"
                onFinish={confirmRegistration}
              >
                <Form.Item
                  label={"Code"}
                  name="code"
                  rules={[
                    { required: true, message: requiredMessage[language] },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Button htmlType="submit" type="primary">
                  {signUp[language]}
                </Button>
              </Form>
            </>
          ) : (
            <>
              <Title level={3}>{formTitle[language]}</Title>
              <Paragraph>
                {data.login[language]}{" "}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={() => props.onStateChange("signIn")}> Login.</a>
              </Paragraph>
              <Form name="signUpForm1" layout="vertical" onFinish={onFinish}>
                <Form.Item
                  label={firstName[language]}
                  name="firstName"
                  rules={[
                    { required: true, message: requiredMessage[language] },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={lastName[language]}
                  name="lastName"
                  rules={[
                    { required: true, message: requiredMessage[language] },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: requiredMessage[language] },
                  ]}
                >
                  <Input type="email" />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "24px" }}
                  label={password[language]}
                  name="password"
                  hasFeedback
                  help={I18n.get("general password policy")}
                  rules={[
                    { required: true, message: requiredMessage[language] },
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
                {customFields?.map((props) => (
                  <DynamicField {...props} />
                ))}

                <Button htmlType="submit" type="primary">
                  {signUp[language]}
                </Button>
              </Form>
            </>
          )}
        </Wrapper>
      ) : (
        <></>
      )}
    </>
  );
}

const Wrapper = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 1rem;
  // @media (max-width: 900px) {
  //   width: 150%;
  // }
`;

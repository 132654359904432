import React, { useContext, useState } from "react";
import { Auth, API } from "aws-amplify";
import styled from "styled-components";
import { Form, Input, Button, Typography, notification } from "antd";
import { StateContext } from "../../state";
import { createAuthEvent } from "../../graphql_custom/mutations_custom";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

const { Title, Paragraph } = Typography;

const data = {
  login: {
    en: "Not registered yet?",
    de: "Noch nicht registriert?",
  },
  formTitle: {
    en: "Login",
    de: "Login",
  },
  firstName: {
    en: "First Name",
    de: "Vorname",
  },
  lastName: {
    en: "Last Name",
    de: "Nachname",
  },
  password: {
    en: "Password",
    de: "Passwort",
  },
  requiredMessage: {
    en: "Required field",
    de: "Pflichtfeld",
  },
  signUp: {
    en: "Sign up",
    de: "Registrieren",
  },
  forgot: {
    en: "Forgot password?",
    de: "Passwort vergessen?",
  },
  newPasswordTitle: {
    en: "Choose new password",
    de: "Neues Passwort festlegen",
  },
  newPasswordButton: {
    en: "Confirm new password",
    de: "Neues Passwort bestätigen",
  },
  newPasswordLabel: {
    en: "New Password",
    de: "Neues Passwort",
  },
};

export default function SignInForm(props) {
  const [newPassword, setNewPassword] = useState(false);
  const [user, setUser] = useState(false);

  const { language } = useContext(StateContext);
  const {
    formTitle,
    password,
    requiredMessage,
    newPasswordTitle,
    newPasswordButton,
    newPasswordLabel,
  } = data;

  const onFinish = async (values) => {
    try {
      let response = await Auth.signIn(values.email, values.password);
      if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUser(response);
        setNewPassword(true);
      } else {
        try {
          let input = {
            type: "SIGNIN",
            authEventUserId: response?.attributes?.sub,
          };
          // TODO: Remove this and record auth events server side with triggers
          await API.graphql({
            query: createAuthEvent,
            variables: { input },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
          });
        } catch (err) {
          console.log(err);
        }
      }
    } catch (e) {
      notification.error({ message: "Error", description: e.message });
      console.log(e);
      if (e.code === "UserNotConfirmedException") {
        props.onStateChange("confirmSignUp", values.email);
      } else if (e.code === "PasswordResetRequiredException") {
        props.onStateChange("forgotPassword", values.email);
      }
    }
  };

  const onConfirmNewPassword = async (values) => {
    try {
      await Auth.completeNewPassword(user, values.password);

      setUser(false);
    } catch (err) {
      notification.error({ message: "Error", description: err.message });
    }
  };

  return (
    <>
      {props.authState === "signIn" ? (
        <Wrapper>
          {newPassword === true ? (
            <>
              <Title level={3}>{newPasswordTitle[language]}</Title>
              <Form
                name="confirmPasswordForm"
                layout="vertical"
                onFinish={onConfirmNewPassword}
              >
                <Form.Item
                  label={newPasswordLabel[language]}
                  name="password"
                  rules={[
                    { required: true, message: requiredMessage[language] },
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
                <Button htmlType="submit" type="primary">
                  {newPasswordButton[language]}
                </Button>
              </Form>
            </>
          ) : (
            <>
              <Title level={3}>{formTitle[language]}</Title>
              <Paragraph>
                {data.login[language]}{" "}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={() => props.onStateChange("signUp")}>
                  {" "}
                  {data.signUp[language]}.
                </a>
              </Paragraph>
              <Form name="signInForm" layout="vertical" onFinish={onFinish}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: requiredMessage[language] },
                  ]}
                >
                  <Input type="email" />
                </Form.Item>
                <Form.Item
                  label={password[language]}
                  name="password"
                  rules={[
                    { required: true, message: requiredMessage[language] },
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
                <Paragraph>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={() => props.onStateChange("forgotPassword")}>
                    {data.forgot[language]}
                  </a>
                </Paragraph>
                <Button htmlType="submit" type="primary">
                  Login
                </Button>
              </Form>
            </>
          )}
        </Wrapper>
      ) : null}
    </>
  );
}

const Wrapper = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 1rem;
`;

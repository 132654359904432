const translations = {
  de: {
    "general dashboard": "Mein Dashboard",
    "general organization": "Organisation",
    "general departments": "Abteilungen",
    "general department": "Abteilung",
    "general programs": "Programme",
    "general events": "Veranstaltungen",
    "general event": "Veranstaltung",
    "general sessions": "Sessions",
    "general session": "Session",
    "general modules": "Module",
    "general module": "Modul",
    "general users": "Benutzer",
    "general user": "Benutzer",
    "general profile": "Mein Profil",
    "general logout": "Abmelden",
    "general welcome": "Willkommen",
    "general signup": "Registrieren",
    "general edit": "Bearbeiten",
    "general cancel": "Abbrechen",
    "general german": "Deutsch",
    "general english": "Englisch",
    "general description": "Beschreibung",
    "general website": "Webseite",
    "general color": "Farbe",
    "general primary color": "Primärfarbe",
    "general secondary color": "Sekundärfarbe",
    "general required message": "Pflichtfeld",
    "general create": "Erstellen",
    "general deletion prompt":
      "Eine Löschung können Sie nicht rückgängig machen. Möchten Sie dennoch fortfahren?",
    "general yes": "Ja",
    "general no": "Nein",
    "general success message": "Aktion erfolgreich ausgeführt",
    "general error message": "Ein Fehler ist aufgetreten",
    "general save": "Speichern",
    "general height": "Höhe",
    "general width": "Breite",
    "general file": "Datei",
    "general image": "Bild",
    "general upload": "Hochladen",
    "general add": "Hinzufügen",
    "general actions": "Aktionen",
    "general delete": "Löschen",
    "general time": "Zeit",
    "general start": "Beginn",
    "general end": "Ende",
    "general type": "Art",
    "general people": "Personen",
    "general resource": "Ressource",
    "general resources": "Ressourcen",
    "general language": "Sprache",
    "general both": "Beide",
    "general without": "Ohne",
    "general select": "Auswählen",
    "general published": "Veröffentlicht",
    "general editors": "Editoren",
    "general section": "Abschnitt",
    "general sections": "Abschnitte",
    "general firstname": "Vorname",
    "general lastname": "Nachname",
    "general page": "Seite",
    "general pages": "Seiten",
    "general search": "Suchen",
    "general groups": "Gruppen",
    "general group": "Gruppe",
    "general invite": "Einladen",
    "general reset": "Zurücksetzen",
    "general general": "Allgemein",
    "general password": "Passwort",
    "general change": "Ändern",
    "general created": "Erstellt",
    "general duplicate": "Duplikat",
    "general confirm": "Bestätigen",
    "general staff": "Mitarbeitende/r",
    "general load more": "Mehr laden",
    "general exists": "Benutzer/in existiert bereits",
    "general informations": "Informationen",
    "general success": "Aktion erfolgreich",
    "general error": "Fehler",
    "general reply": "Antworten",
    "general send": "Senden",
    "general not found": "Diese Seite gibt es leider nicht.",
    "general preview": "Vorschau",
    "general see live": "Live ansehen",
    "general like message": "Als Like gespeichert",
    "general messages": "Nachrichten",
    "general settings": "Einstellungen",
    "general mark": "Markieren",
    "general new message": "Neue Nachricht",
    "general redirect": "Weiterleitung",
    "general conference nonopen":
      "Sollten Sie nicht weitergeleitet werden, klicken Sie auf den untenstehenden Button.",
    "general to session": "Zur Session",
    "general register need":
      "Registrieren Sie sich bitte auf der Startseite, um an diesen Sessions teilzunehmen.",
    "general privacy": "Datenschutz",
    "general contact": "Kontakt",
    "general like register":
      "Um Likes zu speichern, müssen Sie sich registrieren.",
    "general password policy":
      "Passwort muss mindestens 8 Zeichen beinhalten, darunter mindestens ein Kleinbuchstaben, Großbuchstaben und eine Zahl.",
    "general participants": "Teilnehmende",
    "general overview": "Übersicht",
    "general visits": "Besuche",
    "general now live": "Jetzt live",
    "general now live desc": "Die Session ist jetzt live.",
    "general to module": "Zum Modul",
    "general all": "Alle",
    "general admins only": "Sichtbar für Admins",
    "general restriction": "Beschränkung",
    "general template": "Vorlage",
    "general timeline": "Zeitplan",
    "general template help":
      "Zeitplan ist ein Event, welches die Sessions nach Wochentagen sortiert.",
    "general editor hint":
      "Markieren Sie Text um weitere Formatierungsoptionen anzuzeigen oder tippen Sie : für Emoji-Vorschläge.",
    "general input full email": "Bitte vollständige E-Mail-Adresse eingeben.",
    "general send on enter": "Mit Enter senden",
    "general audience": "Zielgruppe",
    "general prospect": "Studieninteressierte",
    "general current": "Studierende",
    "general external": "Externe",
    "general show content for": "Inhalte anzeigen für:",
    "general bookmark": "Vormerken",
    "general upcoming events": "Kommende Veranstaltungen",
    "general current events": "Laufende Veranstaltungen",
    "general archived events": "Archivierte Veranstaltungen",
    "general staff multiple": "Mitarbeitende",
  },
  en: {
    "general dashboard": "My Dashboard",
    "general organization": "Organization",
    "general departments": "Departments",
    "general department": "Department",
    "general programs": "Programs",
    "general events": "Events",
    "general event": "Event",
    "general sessions": "Sessions",
    "general session": "Session",
    "general modules": "Modules",
    "general module": "Module",
    "general users": "User",
    "general user": "User",
    "general profile": "My Profile",
    "general logout": "Logout",
    "general welcome": "Welcome",
    "general signup": "Sign up",
    "general edit": "Edit",
    "general cancel": "Cancel",
    "general german": "German",
    "general english": "English",
    "general description": "Description",
    "general website": "Website",
    "general color": "Color",
    "general primary color": "Primary color",
    "general secondary color": "Secondary color",
    "general required message": "Required",
    "general create": "Create",
    "general deletion prompt":
      "Deletions cannot be undone. Do you want to continue?",
    "general yes": "Yes",
    "general no": "No",
    "general success message": "Action successfully completed",
    "general error message": "An error ocurred",
    "general save": "Save",
    "general height": "Height",
    "general width": "Width",
    "general file": "File",
    "general image": "Image",
    "general upload": "Upload",
    "general add": "Add",
    "general actions": "Actions",
    "general delete": "Delete",
    "general time": "Time",
    "general start": "Start",
    "general end": "End",
    "general type": "Type",
    "general people": "People",
    "general resource": "Resource",
    "general resources": "Ressources",
    "general language": "Language",
    "general both": "Both",
    "general without": "Without",
    "general select": "Select",
    "general published": "Published",
    "general editors": "Editors",
    "general section": "Section",
    "general sections": "Sections",
    "general firstname": "First Name",
    "general lastname": "Last Name",
    "general page": "Page",
    "general pages": "Pages",
    "general search": "Search",
    "general groups": "Groups",
    "general group": "Group",
    "general invite": "Invite",
    "general reset": "Reset",
    "general general": "General",
    "general password": "Password",
    "general change": "Change",
    "general created": "Created",
    "general duplicate": "Duplicate",
    "general confirm": "Confirm",
    "general staff": "Staff",
    "general load more": "Load more",
    "general exists": "User already exists",
    "general informations": "Information",
    "general success": "Success",
    "general error": "Error",
    "general reply": "Reply",
    "general send": "Send",
    "general not found": "Sorry, the page you visited does not exist.",
    "general preview": "Preview",
    "general see live": "See live",
    "general like message": "Saved as like",
    "general messages": "Messages",
    "general settings": "Settings",
    "general mark": "Mark",
    "general new message": "New Message",
    "general redirect": "Redirect",
    "general conference nonopen":
      "If no window opens automatically, click the button below to proceed.",
    "general to session": "Go to Session",
    "general register need":
      "Please register on the homepage to join these sessions.",
    "general imprint": "Imprint",
    "general privacy": "Data Privacy",
    "general like register":
      "In order to save likes, you must be a registered user.",
    "general password policy":
      "Password must be minimum 8 characters and contain numbers, uppercase and lowercase letters.",
    "general participants": "Participants",
    "general overview": "Overview",
    "general visits": "Visits",
    "general now live": "Now live",
    "general now live desc": "The session is now open for your participation.",
    "general to module": "Got to Module",
    "general all": "All",
    "general admins only": "Admins only",
    "general restriction": "Restriction",
    "general template": "Template",
    "general timeline": "Timeline",
    "general template help":
      "Timeline is an event that sorts the sessions according to weekdays.",
    "general editor hint":
      "Select text to show formatting options or type : to show emoji suggestions.",
    "general input full email": "Please input full e-mail address.",
    "general send on enter": "Send on enter",
    "general audience": "Audience",
    "general prospect": "Prospective Students",
    "general current": "Students",
    "general external": "External",
    "general show content for": "Show content for:",
    "general bookmark": "Bookmark",
    "general upcoming events": "Upcoming Events",
    "general current events": "Current Events",
    "general archived events": "Archived Events",
    "general staff multiple": "Staff",
  },
};

export default translations;

import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import {
  ForgotPassword,
  SignIn,
  SignUp,
  RequireNewPassword,
  VerifyContact,
  Authenticator,
  ConfirmSignUp,
  Greetings,
} from "aws-amplify-react";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ConfirmSignUpForm from "./ConfirmSignUpForm";
import GroupSelfRegistration from "./GroupSelfRegistration";

export default function GroupSelfRegistrationWrapper() {
  const { colors, assets } = useContext(ThemeContext);

  const Wrapper = styled.div`
    // max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
    box-sizing: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(
        to left top,
        ${colors.primaryColorDark},
        ${colors.primaryColorLight}
      ),
      url(${assets.backgroundImage});
  `;
  return (
    <Wrapper>
      <Authenticator
        authState="signIn"
        style={{
          boxShadow: "1rem 1rem 1rem rgba(0,0,0,0.8)",
        }}
        usernameAttributes="email"
        hideDefault
      >
        <SignInForm override={<SignIn />} />
        <SignUpForm override={<SignUp />} />
        <RequireNewPassword />
        <VerifyContact />
        <ForgotPasswordForm override={<ForgotPassword />} />
        <ConfirmSignUpForm override={<ConfirmSignUp />} />
        <GroupSelfRegistration override={<Greetings />} />
      </Authenticator>
    </Wrapper>
  );
}

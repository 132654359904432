export default {
  de: {
    "onboarding register desc": "Lege einen Account an.",
    "onboarding update title": "Profil vervollständigen",
    "onboarding update desc":
      "Lade ein Bild hoch und nenne deine Berufsbezeichnung.",
    "onboarding enroll title": "Session beitreten",
    "onboarding enroll desc": "Dein Administrator kann dich hinzufügen.",
    "onboarding finished title": "Fertig!",
    "onboarding finished desc": "Viel Spaß bei deinem Online-Event.",
    "onboarding done": "Verstanden",
  },
  en: {
    "onboarding register desc": "Sign up for an account.",
    "onboarding update title": "Update your profile",
    "onboarding update desc": "Upload an image and provide a job title.",
    "onboarding enroll title": "Enroll in a session",
    "onboarding enroll desc": "Ask your administrator for enrollment.",
    "onboarding finished title": "Finished!",
    "onboarding finished desc": "Enjoy your online event.",
    "onboarding done": "Got it",
  },
};

export default {
  de: {
    "organization my organization": "Meine Organisation",
    "organization name": "Name der Organisation",
  },
  en: {
    "organization my organization": "My Organization",
    "organization name": "Name of Organization",
  },
};

export const createAuthEvent = /* GraphQL */ `
  mutation CreateAuthEventCustom(
    $input: CreateAuthEventInput!
    $condition: ModelAuthEventConditionInput
  ) {
    createAuthEvent(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createLike = /* GraphQL */ `
  mutation CreateLikeCustom(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createSessionEnrollment = /* GraphQL */ `
  mutation CreateSessionEnrollmentCustom(
    $input: CreateSessionEnrollmentInput!
    $condition: ModelSessionEnrollmentConditionInput
  ) {
    createSessionEnrollment(input: $input, condition: $condition) {
      id
    }
  }
`;

export const dashboard_updateUserOnboarding = `
  mutation UpdateUserCustom($input: UpdateUserInput!) {
    updateUser(input: $input) {
      onboarded
      firstName
      lastName
      image
      position {
        en
        de
      }
      sessions(limit:1000) {
        items {
          type
          session {
            id
            start
            type
            title {
              en
              de
            }
          }
        }
      }
      modules(limit: 10000) {
        items {
          module {
            id
            title {
              en
              de
            }
          }
        }
      }
    }
  }
`;

export const deleteLike = /* GraphQL */ `
  mutation DeleteLikeCustom(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
    }
  }
`;

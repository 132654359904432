/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      platformTitle {
        en
        de
      }
      editorStateDE
      editorStateEN
      website {
        en
        de
      }
      contact
      logo {
        en
        de
      }
      primaryColor
      secondaryColor
      font
      backgroundImage
      standardCover
      welcomeText {
        en
        de
      }
      features
      bbbServerUrl
      bbbServerSecret
      currentSessionDesc {
        en
        de
      }
      upcomingSessionDesc {
        en
        de
      }
      archivedSessionDesc {
        en
        de
      }
      copyright
      maxEvents
      transactionalEmail
      cssOverride
      showAudienceFiltering
      loginRequired
      landingPageDisplay
      videoUrl
      autoDeleteUsers
      dataPrivacy
      imprint
      showSessionGroupFiltering
      hideGlobalSearch
      hideArchivedSessions
      createdAt
      updatedAt
      departments {
        items {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      platformTitle {
        en
        de
      }
      editorStateDE
      editorStateEN
      website {
        en
        de
      }
      contact
      logo {
        en
        de
      }
      primaryColor
      secondaryColor
      font
      backgroundImage
      standardCover
      welcomeText {
        en
        de
      }
      features
      bbbServerUrl
      bbbServerSecret
      currentSessionDesc {
        en
        de
      }
      upcomingSessionDesc {
        en
        de
      }
      archivedSessionDesc {
        en
        de
      }
      copyright
      maxEvents
      transactionalEmail
      cssOverride
      showAudienceFiltering
      loginRequired
      landingPageDisplay
      videoUrl
      autoDeleteUsers
      dataPrivacy
      imprint
      showSessionGroupFiltering
      hideGlobalSearch
      hideArchivedSessions
      createdAt
      updatedAt
      departments {
        items {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      platformTitle {
        en
        de
      }
      editorStateDE
      editorStateEN
      website {
        en
        de
      }
      contact
      logo {
        en
        de
      }
      primaryColor
      secondaryColor
      font
      backgroundImage
      standardCover
      welcomeText {
        en
        de
      }
      features
      bbbServerUrl
      bbbServerSecret
      currentSessionDesc {
        en
        de
      }
      upcomingSessionDesc {
        en
        de
      }
      archivedSessionDesc {
        en
        de
      }
      copyright
      maxEvents
      transactionalEmail
      cssOverride
      showAudienceFiltering
      loginRequired
      landingPageDisplay
      videoUrl
      autoDeleteUsers
      dataPrivacy
      imprint
      showSessionGroupFiltering
      hideGlobalSearch
      hideArchivedSessions
      createdAt
      updatedAt
      departments {
        items {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
    }
  }
`;
export const createDepartment = /* GraphQL */ `
  mutation CreateDepartment(
    $input: CreateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    createDepartment(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      primaryColor
      editorStateDE
      editorStateEN
      logo
      website
      createdAt
      updatedAt
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        cssOverride
        showAudienceFiltering
        loginRequired
        landingPageDisplay
        videoUrl
        autoDeleteUsers
        dataPrivacy
        imprint
        showSessionGroupFiltering
        hideGlobalSearch
        hideArchivedSessions
        createdAt
        updatedAt
        departments {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
      }
      programs {
        items {
          id
          editorStateDE
          editorStateEN
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
      modules {
        items {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        nextToken
      }
      staff {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateDepartment = /* GraphQL */ `
  mutation UpdateDepartment(
    $input: UpdateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    updateDepartment(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      primaryColor
      editorStateDE
      editorStateEN
      logo
      website
      createdAt
      updatedAt
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        cssOverride
        showAudienceFiltering
        loginRequired
        landingPageDisplay
        videoUrl
        autoDeleteUsers
        dataPrivacy
        imprint
        showSessionGroupFiltering
        hideGlobalSearch
        hideArchivedSessions
        createdAt
        updatedAt
        departments {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
      }
      programs {
        items {
          id
          editorStateDE
          editorStateEN
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
      modules {
        items {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        nextToken
      }
      staff {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteDepartment = /* GraphQL */ `
  mutation DeleteDepartment(
    $input: DeleteDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    deleteDepartment(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      primaryColor
      editorStateDE
      editorStateEN
      logo
      website
      createdAt
      updatedAt
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        cssOverride
        showAudienceFiltering
        loginRequired
        landingPageDisplay
        videoUrl
        autoDeleteUsers
        dataPrivacy
        imprint
        showSessionGroupFiltering
        hideGlobalSearch
        hideArchivedSessions
        createdAt
        updatedAt
        departments {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
      }
      programs {
        items {
          id
          editorStateDE
          editorStateEN
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
      modules {
        items {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        nextToken
      }
      staff {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createProgram = /* GraphQL */ `
  mutation CreateProgram(
    $input: CreateProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    createProgram(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      editorStateDE
      editorStateEN
      createdAt
      updatedAt
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      sessions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateProgram = /* GraphQL */ `
  mutation UpdateProgram(
    $input: UpdateProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    updateProgram(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      editorStateDE
      editorStateEN
      createdAt
      updatedAt
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      sessions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteProgram = /* GraphQL */ `
  mutation DeleteProgram(
    $input: DeleteProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    deleteProgram(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      editorStateDE
      editorStateEN
      createdAt
      updatedAt
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      sessions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      state
      title {
        en
        de
      }
      description {
        en
        de
      }
      videoUrl {
        en
        de
      }
      videoCover
      image
      imageAlt
      start
      end
      template
      count
      isRestricted
      group
      audience
      showInlineModules
      altHeaderStyle
      createdAt
      updatedAt
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        cssOverride
        showAudienceFiltering
        loginRequired
        landingPageDisplay
        videoUrl
        autoDeleteUsers
        dataPrivacy
        imprint
        showSessionGroupFiltering
        hideGlobalSearch
        hideArchivedSessions
        createdAt
        updatedAt
        departments {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
      }
      enrollments {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
      modules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      state
      title {
        en
        de
      }
      description {
        en
        de
      }
      videoUrl {
        en
        de
      }
      videoCover
      image
      imageAlt
      start
      end
      template
      count
      isRestricted
      group
      audience
      showInlineModules
      altHeaderStyle
      createdAt
      updatedAt
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        cssOverride
        showAudienceFiltering
        loginRequired
        landingPageDisplay
        videoUrl
        autoDeleteUsers
        dataPrivacy
        imprint
        showSessionGroupFiltering
        hideGlobalSearch
        hideArchivedSessions
        createdAt
        updatedAt
        departments {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
      }
      enrollments {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
      modules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      state
      title {
        en
        de
      }
      description {
        en
        de
      }
      videoUrl {
        en
        de
      }
      videoCover
      image
      imageAlt
      start
      end
      template
      count
      isRestricted
      group
      audience
      showInlineModules
      altHeaderStyle
      createdAt
      updatedAt
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        cssOverride
        showAudienceFiltering
        loginRequired
        landingPageDisplay
        videoUrl
        autoDeleteUsers
        dataPrivacy
        imprint
        showSessionGroupFiltering
        hideGlobalSearch
        hideArchivedSessions
        createdAt
        updatedAt
        departments {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
      }
      enrollments {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        nextToken
      }
      modules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createEventEnrollments = /* GraphQL */ `
  mutation CreateEventEnrollments(
    $input: CreateEventEnrollmentsInput!
    $condition: ModelEventEnrollmentsConditionInput
  ) {
    createEventEnrollments(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const updateEventEnrollments = /* GraphQL */ `
  mutation UpdateEventEnrollments(
    $input: UpdateEventEnrollmentsInput!
    $condition: ModelEventEnrollmentsConditionInput
  ) {
    updateEventEnrollments(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const deleteEventEnrollments = /* GraphQL */ `
  mutation DeleteEventEnrollments(
    $input: DeleteEventEnrollmentsInput!
    $condition: ModelEventEnrollmentsConditionInput
  ) {
    deleteEventEnrollments(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const createProgramSession = /* GraphQL */ `
  mutation CreateProgramSession(
    $input: CreateProgramSessionInput!
    $condition: ModelProgramSessionConditionInput
  ) {
    createProgramSession(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      program {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        editorStateDE
        editorStateEN
        createdAt
        updatedAt
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
      }
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const updateProgramSession = /* GraphQL */ `
  mutation UpdateProgramSession(
    $input: UpdateProgramSessionInput!
    $condition: ModelProgramSessionConditionInput
  ) {
    updateProgramSession(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      program {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        editorStateDE
        editorStateEN
        createdAt
        updatedAt
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
      }
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const deleteProgramSession = /* GraphQL */ `
  mutation DeleteProgramSession(
    $input: DeleteProgramSessionInput!
    $condition: ModelProgramSessionConditionInput
  ) {
    deleteProgramSession(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      program {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        editorStateDE
        editorStateEN
        createdAt
        updatedAt
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        sessions {
          nextToken
        }
      }
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      start
      end
      language
      type
      sessionState
      stream
      chatEnabled
      conference
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      isFree
      audience
      color
      resources {
        description {
          en
          de
        }
        link
      }
      image
      anonymous
      isRated
      isProtected
      password
      automaticActivationEnabled
      automaticArchiveEnabled
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      zoom {
        start_url
        join_url
        id
      }
      teams {
        joinUrl
        id
      }
      count
      isRestricted
      group
      link
      sessionDepartmentId
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        cssOverride
        showAudienceFiltering
        loginRequired
        landingPageDisplay
        videoUrl
        autoDeleteUsers
        dataPrivacy
        imprint
        showSessionGroupFiltering
        hideGlobalSearch
        hideArchivedSessions
        createdAt
        updatedAt
        departments {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
      }
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      program {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      ratings {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        nextToken
      }
      modules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      enrollments {
        items {
          id
          type
          sessionEnrollmentSessionId
          sessionEnrollmentUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      start
      end
      language
      type
      sessionState
      stream
      chatEnabled
      conference
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      isFree
      audience
      color
      resources {
        description {
          en
          de
        }
        link
      }
      image
      anonymous
      isRated
      isProtected
      password
      automaticActivationEnabled
      automaticArchiveEnabled
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      zoom {
        start_url
        join_url
        id
      }
      teams {
        joinUrl
        id
      }
      count
      isRestricted
      group
      link
      sessionDepartmentId
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        cssOverride
        showAudienceFiltering
        loginRequired
        landingPageDisplay
        videoUrl
        autoDeleteUsers
        dataPrivacy
        imprint
        showSessionGroupFiltering
        hideGlobalSearch
        hideArchivedSessions
        createdAt
        updatedAt
        departments {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
      }
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      program {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      ratings {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        nextToken
      }
      modules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      enrollments {
        items {
          id
          type
          sessionEnrollmentSessionId
          sessionEnrollmentUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      start
      end
      language
      type
      sessionState
      stream
      chatEnabled
      conference
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      isFree
      audience
      color
      resources {
        description {
          en
          de
        }
        link
      }
      image
      anonymous
      isRated
      isProtected
      password
      automaticActivationEnabled
      automaticArchiveEnabled
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      zoom {
        start_url
        join_url
        id
      }
      teams {
        joinUrl
        id
      }
      count
      isRestricted
      group
      link
      sessionDepartmentId
      organization {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        platformTitle {
          en
          de
        }
        editorStateDE
        editorStateEN
        website {
          en
          de
        }
        contact
        logo {
          en
          de
        }
        primaryColor
        secondaryColor
        font
        backgroundImage
        standardCover
        welcomeText {
          en
          de
        }
        features
        bbbServerUrl
        bbbServerSecret
        currentSessionDesc {
          en
          de
        }
        upcomingSessionDesc {
          en
          de
        }
        archivedSessionDesc {
          en
          de
        }
        copyright
        maxEvents
        transactionalEmail
        cssOverride
        showAudienceFiltering
        loginRequired
        landingPageDisplay
        videoUrl
        autoDeleteUsers
        dataPrivacy
        imprint
        showSessionGroupFiltering
        hideGlobalSearch
        hideArchivedSessions
        createdAt
        updatedAt
        departments {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
      }
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      program {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      ratings {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        nextToken
      }
      modules {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      enrollments {
        items {
          id
          type
          sessionEnrollmentSessionId
          sessionEnrollmentUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createRating = /* GraphQL */ `
  mutation CreateRating(
    $input: CreateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    createRating(input: $input, condition: $condition) {
      id
      stars
      text
      createdAt
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const updateRating = /* GraphQL */ `
  mutation UpdateRating(
    $input: UpdateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    updateRating(input: $input, condition: $condition) {
      id
      stars
      text
      createdAt
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const deleteRating = /* GraphQL */ `
  mutation DeleteRating(
    $input: DeleteRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    deleteRating(input: $input, condition: $condition) {
      id
      stars
      text
      createdAt
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const createAuthEvent = /* GraphQL */ `
  mutation CreateAuthEvent(
    $input: CreateAuthEventInput!
    $condition: ModelAuthEventConditionInput
  ) {
    createAuthEvent(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const updateAuthEvent = /* GraphQL */ `
  mutation UpdateAuthEvent(
    $input: UpdateAuthEventInput!
    $condition: ModelAuthEventConditionInput
  ) {
    updateAuthEvent(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const deleteAuthEvent = /* GraphQL */ `
  mutation DeleteAuthEvent(
    $input: DeleteAuthEventInput!
    $condition: ModelAuthEventConditionInput
  ) {
    deleteAuthEvent(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      body
      createdAt
      sessionId
      anonymousUserName
      replies {
        id
        body
        createdAt
        sessionId
        anonymousUserName
        replies {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        isFlagged
        flaggedBy
        isRepliedTo
        updatedAt
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        likes {
          nextToken
        }
      }
      isFlagged
      flaggedBy
      isRepliedTo
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      body
      createdAt
      sessionId
      anonymousUserName
      replies {
        id
        body
        createdAt
        sessionId
        anonymousUserName
        replies {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        isFlagged
        flaggedBy
        isRepliedTo
        updatedAt
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        likes {
          nextToken
        }
      }
      isFlagged
      flaggedBy
      isRepliedTo
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      body
      createdAt
      sessionId
      anonymousUserName
      replies {
        id
        body
        createdAt
        sessionId
        anonymousUserName
        replies {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        isFlagged
        flaggedBy
        isRepliedTo
        updatedAt
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        likes {
          nextToken
        }
      }
      isFlagged
      flaggedBy
      isRepliedTo
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createModule = /* GraphQL */ `
  mutation CreateModule(
    $input: CreateModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    createModule(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      startpage {
        en
        de
      }
      startpageEN
      startpageDE
      color
      isPublished
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      sort
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      rating {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      sections {
        items {
          id
          sort
          isPublished
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateModule = /* GraphQL */ `
  mutation UpdateModule(
    $input: UpdateModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    updateModule(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      startpage {
        en
        de
      }
      startpageEN
      startpageDE
      color
      isPublished
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      sort
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      rating {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      sections {
        items {
          id
          sort
          isPublished
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteModule = /* GraphQL */ `
  mutation DeleteModule(
    $input: DeleteModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    deleteModule(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      startpage {
        en
        de
      }
      startpageEN
      startpageDE
      color
      isPublished
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      sort
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      rating {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      sections {
        items {
          id
          sort
          isPublished
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createSection = /* GraphQL */ `
  mutation CreateSection(
    $input: CreateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    createSection(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      startpage {
        en
        de
      }
      sort
      isPublished
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      pages {
        items {
          id
          editorStateDE
          editorStateEN
          sort
          isPublished
          createdAt
          updatedAt
          count
        }
        nextToken
      }
    }
  }
`;
export const updateSection = /* GraphQL */ `
  mutation UpdateSection(
    $input: UpdateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    updateSection(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      startpage {
        en
        de
      }
      sort
      isPublished
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      pages {
        items {
          id
          editorStateDE
          editorStateEN
          sort
          isPublished
          createdAt
          updatedAt
          count
        }
        nextToken
      }
    }
  }
`;
export const deleteSection = /* GraphQL */ `
  mutation DeleteSection(
    $input: DeleteSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    deleteSection(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      description {
        en
        de
      }
      startpage {
        en
        de
      }
      sort
      isPublished
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      pages {
        items {
          id
          editorStateDE
          editorStateEN
          sort
          isPublished
          createdAt
          updatedAt
          count
        }
        nextToken
      }
    }
  }
`;
export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      editorState {
        en
        de
      }
      editorStateDE
      editorStateEN
      sort
      isPublished
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      count
      section {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        startpage {
          en
          de
        }
        sort
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        pages {
          nextToken
        }
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePage = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      editorState {
        en
        de
      }
      editorStateDE
      editorStateEN
      sort
      isPublished
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      count
      section {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        startpage {
          en
          de
        }
        sort
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        pages {
          nextToken
        }
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePage = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      id
      title {
        en
        de
      }
      editorState {
        en
        de
      }
      editorStateDE
      editorStateEN
      sort
      isPublished
      createdAt
      updatedAt
      author {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      count
      section {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        startpage {
          en
          de
        }
        sort
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        pages {
          nextToken
        }
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      status
      firstName
      lastName
      image
      userType
      position {
        en
        de
      }
      isTemporary
      onboarded
      createdAt
      updatedAt
      events {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      ratings {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      authEvents {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        nextToken
      }
      lastLoginTime
      email
      ytTokens {
        accessToken
        refreshToken
        expires_in
      }
      teamsTokens {
        accessToken
        refreshToken
        expires_in
      }
      redirect
      modules {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      departments {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          type
          sessionEnrollmentSessionId
          sessionEnrollmentUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      status
      firstName
      lastName
      image
      userType
      position {
        en
        de
      }
      isTemporary
      onboarded
      createdAt
      updatedAt
      events {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      ratings {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      authEvents {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        nextToken
      }
      lastLoginTime
      email
      ytTokens {
        accessToken
        refreshToken
        expires_in
      }
      teamsTokens {
        accessToken
        refreshToken
        expires_in
      }
      redirect
      modules {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      departments {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          type
          sessionEnrollmentSessionId
          sessionEnrollmentUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      status
      firstName
      lastName
      image
      userType
      position {
        en
        de
      }
      isTemporary
      onboarded
      createdAt
      updatedAt
      events {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      ratings {
        items {
          id
          stars
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      authEvents {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        nextToken
      }
      lastLoginTime
      email
      ytTokens {
        accessToken
        refreshToken
        expires_in
      }
      teamsTokens {
        accessToken
        refreshToken
        expires_in
      }
      redirect
      modules {
        items {
          id
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      departments {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sessions {
        items {
          id
          type
          sessionEnrollmentSessionId
          sessionEnrollmentUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createModuleUsers = /* GraphQL */ `
  mutation CreateModuleUsers(
    $input: CreateModuleUsersInput!
    $condition: ModelModuleUsersConditionInput
  ) {
    createModuleUsers(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const updateModuleUsers = /* GraphQL */ `
  mutation UpdateModuleUsers(
    $input: UpdateModuleUsersInput!
    $condition: ModelModuleUsersConditionInput
  ) {
    updateModuleUsers(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const deleteModuleUsers = /* GraphQL */ `
  mutation DeleteModuleUsers(
    $input: DeleteModuleUsersInput!
    $condition: ModelModuleUsersConditionInput
  ) {
    deleteModuleUsers(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const createDepartmentStaff = /* GraphQL */ `
  mutation CreateDepartmentStaff(
    $input: CreateDepartmentStaffInput!
    $condition: ModelDepartmentStaffConditionInput
  ) {
    createDepartmentStaff(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const updateDepartmentStaff = /* GraphQL */ `
  mutation UpdateDepartmentStaff(
    $input: UpdateDepartmentStaffInput!
    $condition: ModelDepartmentStaffConditionInput
  ) {
    updateDepartmentStaff(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const deleteDepartmentStaff = /* GraphQL */ `
  mutation DeleteDepartmentStaff(
    $input: DeleteDepartmentStaffInput!
    $condition: ModelDepartmentStaffConditionInput
  ) {
    deleteDepartmentStaff(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      department {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        primaryColor
        editorStateDE
        editorStateEN
        logo
        website
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        programs {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        staff {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const createEventModules = /* GraphQL */ `
  mutation CreateEventModules(
    $input: CreateEventModulesInput!
    $condition: ModelEventModulesConditionInput
  ) {
    createEventModules(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const updateEventModules = /* GraphQL */ `
  mutation UpdateEventModules(
    $input: UpdateEventModulesInput!
    $condition: ModelEventModulesConditionInput
  ) {
    updateEventModules(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const deleteEventModules = /* GraphQL */ `
  mutation DeleteEventModules(
    $input: DeleteEventModulesInput!
    $condition: ModelEventModulesConditionInput
  ) {
    deleteEventModules(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const createSessionModules = /* GraphQL */ `
  mutation CreateSessionModules(
    $input: CreateSessionModulesInput!
    $condition: ModelSessionModulesConditionInput
  ) {
    createSessionModules(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const updateSessionModules = /* GraphQL */ `
  mutation UpdateSessionModules(
    $input: UpdateSessionModulesInput!
    $condition: ModelSessionModulesConditionInput
  ) {
    updateSessionModules(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const deleteSessionModules = /* GraphQL */ `
  mutation DeleteSessionModules(
    $input: DeleteSessionModulesInput!
    $condition: ModelSessionModulesConditionInput
  ) {
    deleteSessionModules(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const createSessionEnrollment = /* GraphQL */ `
  mutation CreateSessionEnrollment(
    $input: CreateSessionEnrollmentInput!
    $condition: ModelSessionEnrollmentConditionInput
  ) {
    createSessionEnrollment(input: $input, condition: $condition) {
      id
      type
      sessionEnrollmentSessionId
      sessionEnrollmentUserId
      createdAt
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const updateSessionEnrollment = /* GraphQL */ `
  mutation UpdateSessionEnrollment(
    $input: UpdateSessionEnrollmentInput!
    $condition: ModelSessionEnrollmentConditionInput
  ) {
    updateSessionEnrollment(input: $input, condition: $condition) {
      id
      type
      sessionEnrollmentSessionId
      sessionEnrollmentUserId
      createdAt
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const deleteSessionEnrollment = /* GraphQL */ `
  mutation DeleteSessionEnrollment(
    $input: DeleteSessionEnrollmentInput!
    $condition: ModelSessionEnrollmentConditionInput
  ) {
    deleteSessionEnrollment(input: $input, condition: $condition) {
      id
      type
      sessionEnrollmentSessionId
      sessionEnrollmentUserId
      createdAt
      updatedAt
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      message {
        id
        body
        createdAt
        sessionId
        anonymousUserName
        replies {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        isFlagged
        flaggedBy
        isRepliedTo
        updatedAt
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      page {
        id
        title {
          en
          de
        }
        editorState {
          en
          de
        }
        editorStateDE
        editorStateEN
        sort
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        count
        section {
          id
          sort
          isPublished
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      message {
        id
        body
        createdAt
        sessionId
        anonymousUserName
        replies {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        isFlagged
        flaggedBy
        isRepliedTo
        updatedAt
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      page {
        id
        title {
          en
          de
        }
        editorState {
          en
          de
        }
        editorStateDE
        editorStateEN
        sort
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        count
        section {
          id
          sort
          isPublished
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      event {
        id
        state
        title {
          en
          de
        }
        description {
          en
          de
        }
        videoUrl {
          en
          de
        }
        videoCover
        image
        imageAlt
        start
        end
        template
        count
        isRestricted
        group
        audience
        showInlineModules
        altHeaderStyle
        createdAt
        updatedAt
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        enrollments {
          nextToken
        }
        sessions {
          nextToken
        }
        modules {
          nextToken
        }
        likes {
          nextToken
        }
      }
      session {
        id
        title {
          en
          de
        }
        description {
          en
          de
        }
        start
        end
        language
        type
        sessionState
        stream
        chatEnabled
        conference
        module {
          id
          startpageEN
          startpageDE
          color
          isPublished
          createdAt
          updatedAt
          sort
        }
        isFree
        audience
        color
        resources {
          link
        }
        image
        anonymous
        isRated
        isProtected
        password
        automaticActivationEnabled
        automaticArchiveEnabled
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        zoom {
          start_url
          join_url
          id
        }
        teams {
          joinUrl
          id
        }
        count
        isRestricted
        group
        link
        sessionDepartmentId
        organization {
          id
          editorStateDE
          editorStateEN
          contact
          primaryColor
          secondaryColor
          font
          backgroundImage
          standardCover
          features
          bbbServerUrl
          bbbServerSecret
          copyright
          maxEvents
          transactionalEmail
          cssOverride
          showAudienceFiltering
          loginRequired
          landingPageDisplay
          videoUrl
          autoDeleteUsers
          dataPrivacy
          imprint
          showSessionGroupFiltering
          hideGlobalSearch
          hideArchivedSessions
          createdAt
          updatedAt
        }
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        event {
          id
          state
          videoCover
          image
          imageAlt
          start
          end
          template
          count
          isRestricted
          group
          audience
          showInlineModules
          altHeaderStyle
          createdAt
          updatedAt
        }
        program {
          nextToken
        }
        ratings {
          nextToken
        }
        messages {
          nextToken
        }
        modules {
          nextToken
        }
        enrollments {
          nextToken
        }
        likes {
          nextToken
        }
      }
      message {
        id
        body
        createdAt
        sessionId
        anonymousUserName
        replies {
          id
          body
          createdAt
          sessionId
          anonymousUserName
          isFlagged
          flaggedBy
          isRepliedTo
          updatedAt
        }
        isFlagged
        flaggedBy
        isRepliedTo
        updatedAt
        session {
          id
          start
          end
          language
          type
          sessionState
          stream
          chatEnabled
          conference
          isFree
          audience
          color
          image
          anonymous
          isRated
          isProtected
          password
          automaticActivationEnabled
          automaticArchiveEnabled
          createdAt
          updatedAt
          count
          isRestricted
          group
          link
          sessionDepartmentId
        }
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        likes {
          nextToken
        }
      }
      module {
        id
        title {
          en
          de
        }
        startpage {
          en
          de
        }
        startpageEN
        startpageDE
        color
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        sort
        department {
          id
          primaryColor
          editorStateDE
          editorStateEN
          logo
          website
          createdAt
          updatedAt
        }
        rating {
          nextToken
        }
        sections {
          nextToken
        }
        users {
          nextToken
        }
        events {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
      page {
        id
        title {
          en
          de
        }
        editorState {
          en
          de
        }
        editorStateDE
        editorStateEN
        sort
        isPublished
        createdAt
        updatedAt
        author {
          id
          status
          firstName
          lastName
          image
          userType
          isTemporary
          onboarded
          createdAt
          updatedAt
          lastLoginTime
          email
          redirect
        }
        count
        section {
          id
          sort
          isPublished
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
      }
      user {
        id
        status
        firstName
        lastName
        image
        userType
        position {
          en
          de
        }
        isTemporary
        onboarded
        createdAt
        updatedAt
        events {
          nextToken
        }
        ratings {
          nextToken
        }
        authEvents {
          nextToken
        }
        messages {
          nextToken
        }
        lastLoginTime
        email
        ytTokens {
          accessToken
          refreshToken
          expires_in
        }
        teamsTokens {
          accessToken
          refreshToken
          expires_in
        }
        redirect
        modules {
          nextToken
        }
        departments {
          nextToken
        }
        sessions {
          nextToken
        }
        likes {
          nextToken
        }
      }
    }
  }
`;

import React, { Component } from "react";
import styled from "styled-components";
import { Result, Button } from "antd";
import logger from "./Logger";

export default class GlobalErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logger.push({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Wrapper>
          <Result
            status="500"
            title="Error"
            subTitle="Sorry, something went wrong."
            extra={<Button type="primary">Back Home</Button>}
            style={{ margin: "auto" }}
          />
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

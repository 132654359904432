import React, { useContext } from "react";
import { Checkbox, Form, Input, Select } from "antd";
import { StateContext } from "../../state";

const requiredMessage = {
  en: "Required field",
  de: "Pflichtfeld",
} as any;

export const DynamicField = ({
  type,
  name,
  en,
  de,
  options,
  required,
}: {
  type: string;
  name: string;
  en: string;
  de: string;
  options: {
    key: string;
    en: string;
    de: string;
  }[];
  required: boolean;
}) => {
  const { language } = useContext(StateContext);
  const label = language === "de" ? de : en;

  if (type === "checkbox") {
    return (
      <Form.Item
        name={name}
        getValueFromEvent={(e) => {
          if (e.target.checked) return true;
          if (required) return null;
          return false;
        }}
        rules={[
          {
            required: required,
            message: requiredMessage[language],
          },
        ]}
      >
        <Checkbox>{label}</Checkbox>
      </Form.Item>
    );
  }

  if (type === "select") {
    return (
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required,
            message: requiredMessage[language],
          },
        ]}
      >
        <Select>
          {options.map((option) => (
            <Select.Option key={option.key} value={option.key}>
              {language === "de" ? option.de : option.en}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: required,
          message: requiredMessage[language],
        },
      ]}
    >
      <Input type={type} />
    </Form.Item>
  );
};

import { API, graphqlOperation } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";

const initalDepartments = [];

const DepartmentContext = React.createContext(initalDepartments);

const listDepartments = `
  query ListDepartments {
    listDepartments (limit: 1000) {
      items {
        id
        title {
          en
          de
        }
        primaryColor
      }
    }
  }
`;

export default function DepartmentProvider({ children }) {
  const [departments, setDepartments] = useState({});

  useEffect(() => {
    const fetch = async () => {
      let {
        data: {
          listDepartments: { items },
        },
      } = await API.graphql(graphqlOperation(listDepartments));
      let departmentObj = {};
      items.map((dep) => (departmentObj[dep.id] = dep));
      setDepartments(departmentObj);
    };
    fetch();
  }, []);

  return (
    <DepartmentContext.Provider value={{ departments }}>
      {children}
    </DepartmentContext.Provider>
  );
}

export function useDepartments() {
  const context = useContext(DepartmentContext);
  return context;
}

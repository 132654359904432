export class Group {
  constructor(group) {
    this.groupName = group;
  }
}

export class Department {
  constructor(title) {
    this.title = title;
  }
}

export class Program {
  constructor(department) {
    this.department = department;
  }
}

export class Session {
  constructor(department) {
    this.department = department;
  }
}

export class Module {
  constructor(department) {
    this.department = department;
  }
}

import React, { useContext, useState, useEffect } from "react";
import { API, Auth, I18n } from "aws-amplify";
import styled from "styled-components";
import { Form, Typography, Input, Select, Button, notification } from "antd";
import { StateContext } from "../../state";

import Loader from "../../Loader";
import DynamicSignupForm from "./DynamicSignupForm";

const listDepartmentNames = `
  query listDepartments {
    listDepartments {
      items {
        title {
          en
          de
        }
      }
    }
  }
`;

const listProgramNames = `
  query listPrograms {
    listPrograms {
      items {
        title {
          en
          de
        }
      }
    }
  }
`;

const { Title, Paragraph } = Typography;

const data = {
  login: {
    en: "Already have an account?",
    de: "Schon registriert?",
  },
  formTitle: {
    en: "Sign Up",
    de: "Registrierung",
  },
  firstName: {
    en: "First Name",
    de: "Vorname",
  },
  lastName: {
    en: "Last Name",
    de: "Nachname",
  },
  password: {
    en: "Password",
    de: "Passwort",
  },
  role: {
    en: "Role",
    de: "Rolle",
  },
  program: {
    de: "Studienfach",
    en: "Program",
  },
  department: {
    en: "Department",
    de: "Institution",
  },
  interests: {
    de: "Interessen",
    en: "Interests",
  },
  signUp: {
    en: "Sign Up For Free",
    de: "Kostenlos registrieren",
  },
  requiredMessage: {
    en: "Required field",
    de: "Pflichtfeld",
  },
  roleTypes: [
    {
      en: "Prospective Student",
      de: "Studieninteressierte/r",
      value: "PROSPECT",
    },
    {
      en: "Student",
      de: "Studierende/r",
      value: "CURRENT",
    },
    {
      en: "Staff",
      de: "Mitarbeitende/r",
      value: "STAFF",
    },
    {
      en: "Other",
      de: "Sonstige/r",
      value: "EXTERNAL",
    },
  ],
  codeSent: {
    en: "We have sent you a confirmation code. Please check your emails.",
    de: "Wir haben Ihnen einen Code zugeschickt. Bitte checken Sie Ihre Emails.",
  },
  confirmSignupTitle: {
    en: "Confirm your email address",
    de: "Bestätigung Ihrer E-Mail-Adresse",
  },
};

const interests = [
  {
    en: "Business",
    de: "Betriebswirtschaftslehre",
  },
  {
    en: "History",
    de: "Geschichte",
  },
  {
    en: "Mathematics and Informatics",
    de: "Mathematik und Informatik",
  },
  {
    en: "Media and Communication",
    de: "Medien und Kommunikation",
  },
  {
    en: "Law",
    de: "Jura",
  },
  {
    en: "Educaction",
    de: "Pädagogik",
  },
  {
    en: "Philosophy",
    de: "Philosophie",
  },
  {
    en: "Politics",
    de: "Politik",
  },
  {
    en: "Psychology",
    de: "Psychologie",
  },
  {
    en: "Sociology",
    de: "Soziologie",
  },
  {
    en: "Language and Literature",
    de: "Sprache und Literatur",
  },
  {
    en: "Economics",
    de: "Volkswirtschaftslehre",
  },
  {
    en: "All",
    de: "Alle",
  },
];

export default function SignUpForm(props) {
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [user, setUser] = useState(false);

  const { language } = useContext(StateContext);
  const {
    firstName,
    lastName,
    password,
    role,
    formTitle,
    signUp,
    requiredMessage,
    roleTypes,
    confirmSignupTitle,
    codeSent,
  } = data;

  const onFinish = async (values) => {
    values["custom:interests"] =
      values["custom:interests"] === undefined ||
      values["custom:interests"].length === 0
        ? "None"
        : values["custom:interests"].join(", ");

    let input = {
      username: values.email,
      email: values.email,
      password: values.password,
      attributes: {
        given_name: values.firstName,
        family_name: values.lastName,
        "custom:userType": values.userType,
        "custom:interests": values["custom:interests"] || "None",
        "custom:department": values["custom:department"] || "None",
        "custom:program": values["custom:program"] || "None",
      },
    };
    try {
      await Auth.signUp(input);
      // let dbInput = {
      //   id: response.userSub,
      //   firstName: values.firstName,
      //   lastName: values.lastName,
      //   email: values.email,
      // };
      // // TODO: Move this to the backend
      // await API.graphql({
      //   query: mutations.createUser,
      //   variables: { input: dbInput },
      //   authMode: "API_KEY",
      // });
      // try {
      //   let authInput = {
      //     type: "SIGNUP",
      //     authEventUserId: response?.userSub,
      //   };
      //   // TODO: Move this to the backend
      //   await API.graphql({
      //     query: mutations.createAuthEvent,
      //     variables: { input: authInput },
      //     authMode: "API_KEY",
      //   });
      // } catch (err) {
      //   console.log(err);
      // }

      props.onStateChange("confirmSignUp", values.email);
    } catch (e) {
      notification.error({ message: "Error", description: e.message });
    }
  };

  const confirmRegistration = async (values) => {
    try {
      await Auth.confirmSignUp(user, values.code);
      setUser(false);
      notification.success({
        message: I18n.get("general success"),
        description: I18n.get("auth register success"),
      });
      props.onStateChange("signIn");
    } catch (err) {
      console.log(err);
      notification.error({
        message: I18n.get("general error"),
        description: err.message,
      });
    }
  };

  useEffect(() => {
    const fetch = async () => {
      let {
        data: { listDepartments },
      } = await API.graphql({
        query: listDepartmentNames,
        authMode: "API_KEY",
      });
      let {
        data: { listPrograms },
      } = await API.graphql({
        query: listProgramNames,
        authMode: "API_KEY",
      });
      setDepartments(listDepartments.items);
      setPrograms(listPrograms.items);
      setLoading(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (process.env.REACT_APP_SIGN_UP_PARAMS) {
    return <DynamicSignupForm {...props} />;
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {props.authState === "signUp" ? (
            <Wrapper>
              {user ? (
                <>
                  <Title level={3}>{confirmSignupTitle[language]}</Title>
                  <Paragraph>{codeSent[language]} </Paragraph>
                  <Form
                    name="confirmSignup"
                    layout="vertical"
                    onFinish={confirmRegistration}
                  >
                    <Form.Item
                      label={"Code"}
                      name="code"
                      rules={[
                        { required: true, message: requiredMessage[language] },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>
                    <Button htmlType="submit" type="primary">
                      {signUp[language]}
                    </Button>
                  </Form>
                </>
              ) : (
                <>
                  <Title level={3}>{formTitle[language]}</Title>
                  <Paragraph>
                    {data.login[language]}{" "}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={() => props.onStateChange("signIn")}> Login.</a>
                  </Paragraph>
                  <Form
                    name="signUpForm1"
                    layout="vertical"
                    onFinish={onFinish}
                  >
                    <Form.Item
                      label={firstName[language]}
                      name="firstName"
                      rules={[
                        { required: true, message: requiredMessage[language] },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label={lastName[language]}
                      name="lastName"
                      rules={[
                        { required: true, message: requiredMessage[language] },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        { required: true, message: requiredMessage[language] },
                      ]}
                    >
                      <Input type="email" />
                    </Form.Item>
                    <Form.Item
                      label={password[language]}
                      name="password"
                      hasFeedback
                      help={I18n.get("general password policy")}
                      rules={[
                        { required: true, message: requiredMessage[language] },
                      ]}
                    >
                      <Input type="password" />
                    </Form.Item>
                    <Form.Item
                      label={role[language]}
                      name="userType"
                      rules={[
                        { required: true, message: requiredMessage[language] },
                      ]}
                    >
                      <Select>
                        {roleTypes.map((roleType, index) => (
                          <Select.Option key={index} value={roleType.value}>
                            {roleType[language]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.userType !== currentValues.userType
                      }
                    >
                      {({ getFieldValue }) => {
                        return getFieldValue("userType") === "PROSPECT" ? (
                          <>
                            <Form.Item
                              name="custom:interests"
                              label={data.interests[language]}
                            >
                              <Select mode="multiple">
                                {interests.map((interest, index) => (
                                  <Select.Option
                                    key={index}
                                    value={interest.en}
                                  >
                                    {interest[language]}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </>
                        ) : null;
                      }}
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.userType !== currentValues.userType
                      }
                    >
                      {({ getFieldValue }) => {
                        return getFieldValue("userType") === "CURRENT" ? (
                          <>
                            <Form.Item
                              name="custom:program"
                              label={data.program[language]}
                            >
                              <Select>
                                {programs.map((program, index) => (
                                  <Select.Option
                                    key={index}
                                    value={program.title.en}
                                  >
                                    {program.title[language]}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </>
                        ) : null;
                      }}
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.userType !== currentValues.userType
                      }
                    >
                      {({ getFieldValue }) => {
                        return getFieldValue("userType") === "STAFF" ? (
                          <>
                            <Form.Item
                              name="custom:department"
                              label={data.department[language]}
                            >
                              <Select>
                                {departments.map((department, index) => (
                                  <Select.Option
                                    key={index}
                                    value={department.title.en}
                                  >
                                    {department.title[language]}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </>
                        ) : null;
                      }}
                    </Form.Item>

                    <Button htmlType="submit" type="primary">
                      {signUp[language]}
                    </Button>
                  </Form>
                </>
              )}
            </Wrapper>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

const Wrapper = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 1rem;
  // @media (max-width: 900px) {
  //   width: 150%;
  // }
`;
